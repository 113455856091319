/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      role
      email
      token
      lastActive
      accepted
      extra
      isManager
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      matches {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        role
        email
        token
        lastActive
        accepted
        extra
        isManager
        company {
          id
          name
          description
          active
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMatch = /* GraphQL */ `
  query GetMatch($id: ID!) {
    getMatch(id: $id) {
      id
      details
      date
      place
      minutes
      bookedMinutes
      message
      competition
      feeds
      logo
      regions
      hidden
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          feeds
          createdAt
          updatedAt
        }
        nextToken
      }
      images {
        items {
          id
          feedNumber
          bucket
          region
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          bucket
          key
          link
          extra
          misc
          feedNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listMatchs = /* GraphQL */ `
  query ListMatchs(
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMatchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyMatchBooking = /* GraphQL */ `
  query GetCompanyMatchBooking($id: ID!) {
    getCompanyMatchBooking(id: $id) {
      id
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      feeds
      createdAt
      updatedAt
    }
  }
`;
export const listCompanyMatchBookings = /* GraphQL */ `
  query ListCompanyMatchBookings(
    $filter: ModelCompanyMatchBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyMatchBookings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        company {
          id
          name
          description
          active
          createdAt
          updatedAt
        }
        match {
          id
          details
          date
          place
          minutes
          bookedMinutes
          message
          competition
          feeds
          logo
          regions
          hidden
          createdAt
          updatedAt
        }
        feeds
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      description
      active
      users {
        items {
          id
          role
          email
          token
          lastActive
          accepted
          extra
          isManager
          createdAt
          updatedAt
        }
        nextToken
      }
      matches {
        items {
          id
          feeds
          createdAt
          updatedAt
        }
        nextToken
      }
      images {
        items {
          id
          feedNumber
          bucket
          region
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          bucket
          key
          link
          extra
          misc
          feedNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFeed = /* GraphQL */ `
  query GetFeed($id: ID!) {
    getFeed(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listFeeds = /* GraphQL */ `
  query ListFeeds(
    $filter: ModelFeedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeeds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getImage = /* GraphQL */ `
  query GetImage($id: ID!) {
    getImage(id: $id) {
      id
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      feedNumber
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      bucket
      region
      fullsize {
        key
        width
        height
        size
        type
      }
      thumbnail {
        key
        width
        height
        size
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const listImages = /* GraphQL */ `
  query ListImages(
    $filter: ModelImageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        match {
          id
          details
          date
          place
          minutes
          bookedMinutes
          message
          competition
          feeds
          logo
          regions
          hidden
          createdAt
          updatedAt
        }
        feedNumber
        company {
          id
          name
          description
          active
          createdAt
          updatedAt
        }
        bucket
        region
        fullsize {
          key
          width
          height
          size
          type
        }
        thumbnail {
          key
          width
          height
          size
          type
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVideo = /* GraphQL */ `
  query GetVideo($id: ID!) {
    getVideo(id: $id) {
      id
      bucket
      key
      link
      extra
      misc
      feedNumber
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listVideos = /* GraphQL */ `
  query ListVideos(
    $filter: ModelVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        bucket
        key
        link
        extra
        misc
        feedNumber
        match {
          id
          details
          date
          place
          minutes
          bookedMinutes
          message
          competition
          feeds
          logo
          regions
          hidden
          createdAt
          updatedAt
        }
        company {
          id
          name
          description
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
