import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { withAuthenticator, AmplifyTheme } from 'aws-amplify-react';
import { Auth, Hub, I18n } from 'aws-amplify';
import { ConfirmSignIn, ConfirmSignUp, ForgotPassword, RequireNewPassword, SignIn, SignUp, VerifyContact} from 'aws-amplify-react';
import NewMenu from './components/newmenu';
import LoginPage_2 from './components/loginPage_2';
import ReportingPage from './pages/reportingPage';
import AdminPage from './pages/adminPage';
import ImagesPage from './pages/imagesPage';
import VideosPage from './pages/videosPage';
/* !!! faq begins */
import FaqPage from './pages/faqPage';
import InfoIcon from '@material-ui/icons/Info';
import { listFeeds } from './graphql/queries';
import Tooltip from '@material-ui/core/Tooltip';
/* !!! faq ends */
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import "./App.css";
import { CHECK_SINGLE_SESSION, CHECK_HUB_DB_ON_STARTUP, DEFAULT_COMP, DEFAULT_LOGO,
         HUB_DB_DUMP_FILE, APP_VERSION, THEME, MEDIA_BUCKET, RENAME_MATCHES } from './utils/constants';
import { getCompanies } from './utils/functions';
import { API, graphqlOperation } from "aws-amplify";
import { createMatch, updateUser, registerUser } from './graphql/mutations';
import { getUser } from './graphql/queries';
import { listMatchesWithImages } from './utils/customQueries';
import Toast from 'light-toast';
import moment from 'moment';
import aws_exports from './aws-exports';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { List, Divider as SemanticDivider, Message, Popup, Icon } from 'semantic-ui-react';
import { createTheme, MuiThemeProvider } from "@material-ui/core";
const noRippleTheme = createTheme({
    props: {
      MuiButtonBase: {
        disableRipple: true // no more ripple, on the whole application!
      }
    }
  });
// this forces browser to always render auth form elements in English, 
// not in default browser locale language
I18n.setLanguage('en');

const AWS = require('aws-sdk');
AWS.config.update ({
    region: aws_exports.aws_project_region
});

const CURRENT_YEAR = moment().format('YYYY');

export const UserContext = React.createContext();

const logOut = async (user, globalLogOut=false, nullifySessionInDb=true) => {

  if (CHECK_SINGLE_SESSION && nullifySessionInDb && !user.isManager) {
      let input = {
          id: user.id,
          token: null,
          // extra: null,
      }
      await API.graphql(
          graphqlOperation(updateUser, { input })
      );
  }
  Auth.signOut({ global: globalLogOut })
      .then(data => console.log(data))
      .catch(err => console.log(err));
}

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
class App extends React.Component {
  constructor(props) {
    super(props);
    
    // https://aws-amplify.github.io/docs/js/authentication#using-withauthenticator-hoc
    Hub.listen('auth', async (data) => {
      switch (data.payload.event) {
          case 'signIn':
              let user = data.payload.data;
              break;
          case 'signOut': 
            // console.log('now the user is signed off');
            break;
          case 'signIn_failure':
              console.log('the user failed to sign in');
              console.log('the error is', data.payload.data);
              break;
          default:
              break;
      }
    });
  }
  /* !!! faq begins */
  state = {
    user: null,
    matches: null,
    newMatches: null,
    diffMatches: null,
    open: false,
    openEmail: false,
    openInfo: false,
    companyList: null
  };
  openModal = () => { this.setState({ open : true }); }
  closeModal = () => { this.setState({ open : false }); }
  openModalEmail = () => { this.setState({ openEmail : true }); }
  closeModalEmail = () => { this.setState({ openEmail : false }); }
  openModalInfo = () => { console.log("hello"); this.setState({ openInfo : true }); }
  closeModalInfo = () => { this.setState({ openInfo : false }); }
  /* !!! faq ends */

  async componentDidMount() {
    const favicon = document.querySelector('[rel=icon]');
    favicon.href = process.env.PUBLIC_URL + '/' + 'infront' + '/favicon.ico';
    await this.getUserData();

    let companyList = [];
    let matches = [];
    [companyList, matches] = await Promise.all([
      getCompanies().then(
        console.log('companies fetched')),
      this.getMatchesWithImages(200, CHECK_HUB_DB_ON_STARTUP).then(
        console.log('matches fetched'))
    ]);
    {/* !!! faq begins */}
    let questions = [];
    if (!this.state.user.isManager) {
      const questionsUnparsed = await API.graphql(graphqlOperation(listFeeds));
      questions = questionsUnparsed.data.listFeeds.items.map((question) =>
        {
          const separator = '& & &';
          const stringParts = question.name ? question.name.split(separator) : [];
          let result = {question: '', answer: '', lastChanged: ''};
          
          if (stringParts.length == 3) {          
            result = {question: stringParts[0], answer: stringParts[1], lastChanged: stringParts[2]};
          }

          return result;
        }
      );
    }
    
    this.setState ({ companyList, matches, questions });
    {/* !!! faq ends */}
  };

  async getObject () {
    try {
        const options = {
            Bucket: aws_exports.aws_user_files_s3_bucket,
            Key: HUB_DB_DUMP_FILE,
            ResponseContentType: 'application/json',
            ResponseCacheControl: 'No-cache'            // ensure json file is not cached
        };      
        const credentials = await Auth.currentCredentials();
        const S3 = new AWS.S3({ 
            signatureVersion: 'v4', 
            httpOptions: { timeout: 1000 * 86400 },
            credentials: Auth.essentialCredentials(credentials)
        });

        const data = await S3.getObject(options).promise();
        return data.Body;
    } catch (e) {
        throw new Error(`Could not retrieve file from S3: ${e.message}`)
    }
  }
  componentWillUnmount() {
    // try {
    //   this.deleteImageListener.unsubscribe();
    // } catch (e) {
    //   console.log("unsubscribe error supressed. TODO! fix this")
    // }
    // console.log("app.js - componentWillUnmount")
  }
  getUserData = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (user) {
        let input = { id: user.username };
        const userDbRecord = await API.graphql(
            graphqlOperation(getUser, input)
        );

        let signedInSomewhereElse = false;        
        const isUserManager = userDbRecord.data.getUser.isManager ? true : false;
        
        // ensure user isn't signed in somewhere else
        if (CHECK_SINGLE_SESSION && !isUserManager) {
              // if token field wasn't nullified (after proper signout normally)
              // we assume that user is still signed in. However,
              // if access token is the same - we allow user in, since it's 
              // the same session in the same browser.

              if (userDbRecord.data.getUser.token !== null &&
                userDbRecord.data.getUser.token !== user.signInUserSession.refreshToken.token) {
                signedInSomewhereElse = true;
              }
              else {
                    let input = {
                      id: user.username,
                      token: user.signInUserSession.refreshToken.token
                      // extra: user.signInUserSession.accessToken.jwtToken,
                    }

                    await API.graphql(
                        graphqlOperation(updateUser, { input })
                    );
              }
              console.log("signedInSomewhereElse", signedInSomewhereElse);
        } // CHECK_SINGLE_SESSION block ends

        this.setState({ user: {
            isManager: isUserManager,
            userCompany: userDbRecord.data.getUser.company,

            acceptedConditions: userDbRecord.data.getUser.accepted,

            signedInSomewhereElse,
            email: user.attributes.email,
            id: user.username,    // for logOut purposes
            refreshToken: user.signInUserSession.refreshToken.token,
            token: user.signInUserSession.accessToken.jwtToken // used for password reset
        }});
      }
      
      
      // const session = await Auth.currentSession();


      // const refreshToken = session.getRefreshToken();
      
      // var params = {
      //   AuthFlow: 'REFRESH_TOKEN_AUTH',
      //   AuthParameters: { 'REFRESH_TOKEN': refreshToken.token },
      //   ClientId: aws_exports.aws_user_pools_web_client_id, /* required */
      //   UserPoolId: aws_exports.aws_user_pools_id, /* required */

      // };
      // const cognito = new AWS.CognitoIdentityServiceProvider();
      // cognito.adminInitiateAuth(params, function(err, data) {
      //   if (err) console.log(err, err.stack); // an error occurred
      //   else     console.log(data);           // successful response
      // });

     

      // console.log(refreshToken.token)
      // console.log(session)
      // user.refreshSession(refreshToken, (error, session) => {
      //   if (error) {
      //     console.warn(error);
      //     if (error.code === "NotAuthorizedException") {
      //       console.log("logging out")
      //       Auth.logOut();
      //     }
      //   } else {
      //     console.log("Tokens refreshed!", session);
      //   }
      // });
    } catch (err) {
      Toast.fail('Could not fetch user data from the database', 1700, () => {
        console.log(err.message);
      });
    }
  };
  signInAndGetNewTokens = async (user) => {
    let input = {
      id: user.id,
      token: user.refreshToken,
      // extra: user.signInUserSession.accessToken.jwtToken,
    }

    await API.graphql(
        graphqlOperation(updateUser, { input })
    );
    
    this.setState(prevState => ({
      user: {                   
          ...prevState.user,    
          signedInSomewhereElse: false
      }
    }));
  }
  // getMatches = async () => {
  //   const result = await API.graphql(graphqlOperation(listMatchs));
  //   this.setState({ matches: result.data.listMatchs.items });
  // };

  getMatchesWithImages = async (limit, checkHubDB) => {
    let result, matches;
    try {
        result = await API.graphql(graphqlOperation(listMatchesWithImages, {limit: limit}));
        matches = result.data.listMatchs.items;
    } catch (err) {
        Toast.fail('Could not fetch matches from DB', 1700, () => {console.log(err.message);});
    }
    // once there was an error like "cannot read isManager of null"
    // thus let's check that this.state.user is defined

    if (!this.state.user) {
      console.log("WARNING: Can't read user info (this.state.user)")
      return matches;
    }

    if (!checkHubDB || !this.state.user.isManager) {
      return matches;
    }

    /////////////////////////////////////////////// ----
    const options2 = {
      Bucket: 'test-hub2-media',
      Key: 'public/infront/data/events.json',
      ResponseContentType: 'application/json',
      ResponseCacheControl: 'No-cache'            // ensure json file is not cached
    };      
    
    const credentials = await Auth.currentCredentials();
    const S3 = new AWS.S3({ 
        signatureVersion: 'v4', 
        httpOptions: { timeout: 1000 * 86400 },
        credentials: Auth.essentialCredentials(credentials)
    });

    const data2 = await S3.getObject(options2).promise();

    if (!data2) {
      console.log("COULD NOT FETCH JSON from S3");
    }
    const arr2 = data2.Body;
    let str2 = "";
    for (let i = 0; i < arr2.byteLength; i++) {
      str2 += String.fromCharCode(arr2[i]);
    }

    const final2 = JSON.parse(str2);

    const nowDate = moment(); // today date
    const db2 = final2.filter(match => 
      moment(match.datetime).diff(nowDate, 'days') > -5 &&
      moment(match.datetime).diff(nowDate, 'days') < 60
    );
    const hubMatchesNHL = db2.map(match => {
      return {
          id: match.id, 
          details: match.name,
          date: match.datetime,
          feeds: match.feeds,
          logo: DEFAULT_LOGO,
          competition: DEFAULT_COMP
      }
    });
    console.log(hubMatchesNHL);


    //////////////////////////////////////// ----




    // console.log("from hub", hubMatches);
    // console.log("existing", matches);
    // console.log(db.map(m=>m.fields.venue));
    // TODO: REFACTOR!!!!!!!!!!!!!!
    // find matches that are not currently in app's DB
    const diffMatches = hubMatchesNHL.filter(hubMatch => {
        let exists = false;
        for (let match of matches) {
            // console.log(match.id)
            if (match.id == hubMatch.id)
                exists = true;
        }
        // console.log(hubMatch.id)
        if (!exists)
            return hubMatch;
    });
    // console.log("diff", diffMatches);


    if (diffMatches.length === 0) {
      console.log("Database is up to date");
      return matches;
    }
    else {
      try {
        for (let match of diffMatches) {
            
            if (RENAME_MATCHES) {
              // automate match renaming (cut off everything after the 3rd space)
              // e.g. "BVB v BMG KO-2030 CET" => "BVB v BMG"
              const n = 3; // third space

              const trimmedMatchName = match.details.trim(); 
              
              const matchNameChunks = trimmedMatchName.split(' ');
              
              const newMatchName = matchNameChunks.slice(0, n).join(' ');
              console.log(trimmedMatchName, matchNameChunks, newMatchName);
              match.details = newMatchName;
              console.log(match.details);
            }
            let result = await API.graphql(
                graphqlOperation(createMatch, { input: match })
            );
            console.log(result);
        }
        Toast.success(`${diffMatches.length} match(es) added. Reloading...`, 1700, () => {
            console.log(diffMatches);
            window.location.reload();
        });
        this.setState({ matches: [...matches, ...diffMatches] });
        return [...matches, ...diffMatches];
      } catch (err) {
        //Toast.fail('Could not add matches to DB', 1700, () => {console.log(err.message);});
        console.log(err.message);
    }
  }
  };
  // -----------------------------------------------
  LogoArea = () => {
    const tmpTHEME = 'infront';
    return (
      <div className={'logoArea ' + tmpTHEME}>
        <img alt="" className={'logoMain ' + tmpTHEME}
          src={process.env.PUBLIC_URL + '/' + tmpTHEME + '/logo.png'} 
        />    
        <img alt="" className={'logoVirtualAdvertising ' + tmpTHEME} 
          src={process.env.PUBLIC_URL + '/' + tmpTHEME + '/logotext.png'
        } 
        />
      </div>
    )
  }
  UserArea = (props) => {
    // const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };
    // const handleClickAway = () => {
    //   setAnchorEl(false);
    // };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    return (
      <div className="userArea">

          {/* !!! faq begins */}
          {props.user.isManager ? ""
          :  
          <Tooltip arrow={true} title="Frequently asked questions" placement="bottom">
            <span className={"headerIcon " + THEME}>
            <IconButton onClick={props.openModalInfo}><InfoIcon fontSize='large'/></IconButton>
          </span>
          </Tooltip>
          }
        {/* !!! faq ends */}

        {/* <span className="userRole">
          {props.user.isManager ?  
            "manager"
          :
            ""}
        </span> */}

      {/* <Popper id={id} open={open} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={450}>
        <Paper>
          <Typography className={classes.typography}>
        <ClickAwayListener onClickAway={handleClickAway}>
        <div className="profileMain">        
            <div className="profileBlock">
              <div className="profileEmail">{props.user.email}</div>
            </div>
            <br/>
            <div className="profileButtonsBlock">
            <Button className="profileButton" startIcon={<VpnKeyIcon />} onClick={props.openPasswordChangeModal} color="primary" >
              Change password</Button><br/>
            <Button className="profileButton" startIcon={<AlternateEmailIcon />} onClick={props.openEmailChangeModal}  color="primary">
              Change email</Button>
            </div>
            
            </div>
        </ClickAwayListener>

          </Typography>
        </Paper>
        </Fade>
        )}
      </Popper> */}
      
      <Popup open={this.state.modalOpen} position='bottom right' 
        trigger={ <span className={"headerIcon " + THEME}>
                          <IconButton aria-describedby={id} type="button" onClick={handleClick}>
                            <AccountCircleIcon fontSize='large'/>
                          </IconButton>
                      </span>} flowing hoverable>
          
          <div className="profileBlock">
          {props.user.isManager ?  
            
            <SemanticDivider horizontal>
                <span className="dividerText">
                    <Icon name='id badge outline' className='lowOpacityIcon' />
                    Manager
                </span>
            </SemanticDivider>
:
<SemanticDivider horizontal>
<span className="dividerText">
  <Icon name='users' className='lowOpacityIcon' />
  {props.user.userCompany ? props.user.userCompany.name : 'No company'}
</span>
</SemanticDivider>}
          <List divided relaxed>
          
    <List.Item className="profileMenuListItem">
      <List.Icon name='user outline' size='small' className='iconGreenProfile' verticalAlign='middle' />
      <List.Content>
        <List.Description >{props.user.email} </List.Description>
      </List.Content>
    </List.Item>
    <List.Item className="profileMenuListItem">
      <List.Icon name='keyboard outline' size='small' className='iconGreenProfile' verticalAlign='middle' />
      <List.Content>
        <List.Description as='a' onClick={props.openPasswordChangeModal}>
              Change password
        </List.Description>
        
      </List.Content>
    </List.Item>
    <List.Item className="profileMenuListItem">
      <List.Icon name='mail outline' size='small' className='iconGreenProfile' verticalAlign='middle' />
      <List.Content>
        <List.Description as='a' onClick={props.openEmailChangeModal}>Change email</List.Description>
        
      </List.Content>
    </List.Item>
    <List.Item className="profileMenuListItem">
      <List.Icon name='sign out alternate' size='small' className='iconGreenProfile' verticalAlign='middle' />
      <List.Content>
        <List.Description as='a' onClick={() => logOut(props.user)}>Sign Out</List.Description>
        
      </List.Content>
    </List.Item>
  </List>
              
          </div>
      </Popup>
 
        {/* <Tooltip arrow={true} title={props.user.email} placement="bottom"> */}
          {/* <span className="headerIcon">
            <IconButton aria-describedby={id} type="button" onClick={handleClick}>
              <AccountCircleIcon fontSize='large'/>
            </IconButton>
          </span> */}
        {/* </Tooltip> */}
        <span className={"headerIcon " + THEME}>
          <IconButton onClick={() => logOut(props.user)}><ExitToAppIcon fontSize='large'/></IconButton>
        </span>
      </div>)
  }
  handleResetPassword = async (oldPwd, newPwd, confirmNewPwd) => {
    console.log(oldPwd, newPwd, confirmNewPwd);
    // validation
    if (!newPwd || !oldPwd || !confirmNewPwd)
      Toast.fail('All field marked with an * are required', 2500, () => {console.log(oldPwd, newPwd, confirmNewPwd);});
    else if (newPwd !== confirmNewPwd)
      Toast.fail('Passwords don\'t match. Please, double-check', 2500, () => {console.log(newPwd, confirmNewPwd);});
    else if (newPwd.length < 6)
      Toast.fail('Password should be at least 6 characters long', 2500, () => {console.log(newPwd);});
    else
    {
      const credentials = await Auth.currentCredentials();
      const cognito = new AWS.CognitoIdentityServiceProvider({
          credentials: Auth.essentialCredentials(credentials)
      });

      try 
      {   
          const res = await cognito.changePassword ({
            AccessToken: this.state.user.token,
            PreviousPassword: oldPwd,
            ProposedPassword: newPwd
          }).promise();
          this.setState({ open: false });
          Toast.success(`Password changed`, 1000, () => {console.log(res)});
      } catch (err) {
          Toast.fail('Error. ' + err.message, 4000, () => {console.log(err);});
      }
    }
  }
  handleChangeEmail = async (newEmail, confirmPwd) => {
    // validation
    const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!newEmail || !confirmPwd)
      Toast.fail('All field marked with an * are required', 2000, () => {});
      
    else if(!newEmail.match(mailformat))
      Toast.fail('Please, enter valid email address', 2000, () => {console.log(newEmail);});
    else if (confirmPwd.length < 6)
      Toast.fail('Password not correct', 2000, () => {console.log(confirmPwd);});
    // else if (newPwd !== confirmNewPwd)
    //   Toast.fail('Passwords don\'t match. Please, double-check', 2500, () => {console.log(newPwd, confirmNewPwd);});
    else
    {
      const credentials = await Auth.currentCredentials();
      const cognito = new AWS.CognitoIdentityServiceProvider({
          credentials: Auth.essentialCredentials(credentials)
      });

      try 
      {   
          const res = await cognito.adminUpdateUserAttributes ({
            UserAttributes: [
              {
                Name: 'email',
                Value: newEmail
              }
            ],
            UserPoolId: aws_exports.aws_user_pools_id,
            Username: this.state.user.email,
    //         AccessToken: this.state.user.token,
    //         PreviousPassword: oldPwd,
    //         ProposedPassword: newPwd
          }).promise();
          this.setState({ openEmail: false  });
          this.setState(prevState => {
            let user = Object.assign({}, prevState.user);  // creating copy of state variable 
            user.email = newEmail;                         // update the email property, assign a new value                 
            return { user };                               // return new object jasper object
          });
          Toast.success(`Email successfully changed to ` + newEmail, 2000, () => {console.log(res)});
      } catch (err) {
          Toast.fail('Error. ' + err.message, 4000, () => {console.log(err);});
      }
    }
  }

  update = (image, matchId, action) => {
    const { matches } = this.state;
    const matchIndex = matches.findIndex(match => match.id ===  matchId);
    let matchToUpdate = matches[matchIndex];
    
    if (action === 'add') {
      matchToUpdate.images.items.push(image);
    }
    else if (action === 'add array') {
      matchToUpdate.images.items.push(...image);
    }
    else {
      const imageIndex = matchToUpdate.images.items
          .findIndex(im => im.id === image.id);
      matchToUpdate.images.items.splice(imageIndex, 1);
    }
    const updatedMatches = [
      ...matches.slice(0, matchIndex),
      matchToUpdate,
      ...matches.slice(matchIndex + 1)
    ];
    console.log(updatedMatches[matchIndex].images.items.length)
    this.setState({ matches: updatedMatches })
  }

  updateAdminPageProps = (company, action) => {
    // Updating company list on any company changes performed in Admin Page.
    // Company arg is either a company object (add action)
    // or a string var with company id (delete action)
    if (action === 'add') {
      this.setState({ companyList: [...this.state.companyList, company] });
      console.log('add', company);
    } else {
        
        const companyList = this.state.companyList.filter(
          companyFromList => companyFromList.id !== company
        );
        this.setState({ companyList });
        console.log('delete', companyList);
    }
  }
  handleUserAccept = async (user) => {
    const input = {
      id: user.id,
      accepted: true
    }

    await API.graphql(
      graphqlOperation(updateUser, { input })
    );

    this.setState(prevState => ({
      user: {                   
          ...prevState.user,    
          acceptedConditions: true
      }
    }));
    
  }
  render () {
    {/* !!! faq begins */}
    const { user, matches, open, openEmail, openInfo } = this.state;
    {/* !!! faq ends */}

    if (!matches || !user)
      return null;
    
    if (user.signedInSomewhereElse)
      return (
        <div className="userIsLoggedInAlready">
          
          <div className="ui form warning">
            <div className="ui warning message">
              <div className="header">User is logged in on another device/browser</div>
              <ul className="list">
              
                <span>
              {/* <span className="italic">{user.email}</span> currently has an active sign-in session on another device or browser.  */}    
                  This application only permits a user to log in from one device at a time. Click  <span className="iconGreenProfile">Log in now</span> to proceed and terminate the active sign-in session on another device/browser.
                </span>
              </ul>
            </div>
  
            <div className="ui buttons">
  
              <div className="ui button" onClick={() => logOut(user, true)}>Exit</div>
              <div className="or"></div>
              <div className="ui positive button" onClick={() => this.signInAndGetNewTokens(user)}>Log in now</div>
            </div>

          </div>              
      </div>
      )
    else if (!user.isManager && !user.acceptedConditions) {
      return (<div className="userIsLoggedInAlready userAcceptConditions">
          
      {/* <div className="ui form warning"> */}
        <div className="ui floating message">
        
          <div className="header">Please note</div>
          {/* <i class="inbox icon"></i> */}
          <br />
          <ul className="list">
          
            {/* <span> */}
          {/* <span className="italic">{user.email}</span> currently has an active sign-in session on another device or browser.  */}    
              
              By clicking <span className="iconGreenProfile">Accept</span> you agree to the following 
              conditions:
              <List bulleted>
              <List.Item>
              These videos, which are accessible via "Reporting & Analytics Application - Virtual Advertising 
              Documentation Platform", serve exclusively as proof of the proper fulfilment of the contractual 
              obligations by the respective rights holder and are made available to the customer for 
              documentation purposes and internal use only.
              </List.Item>
              <List.Item>
              These videos may not be passed on to third parties or published, in particular not for advertising 
              purposes of any kind. 
              </List.Item>
              <List.Item>
              Any violation of the above mentioned restrictions will be prosecuted.
              </List.Item>
              </List>
            {/* </span> */}
          </ul>
      <br />
      {/* <br /> */}
          <div className="ui buttons">

          <div className="ui button" onClick={() => logOut(user, true)}>Decline</div>
          <div className="or"></div>
          <div className="ui positive button" onClick={() => this.handleUserAccept(user)}>Accept</div>
          </div>
        </div>
  </div>
      )
    }
    else
      console.log(this.state)
      return (
      <UserContext.Provider value={{ user }}>
        <MuiThemeProvider theme={noRippleTheme}>
      <Router>
          <div className="pageContainer">
          <div className="contentWrap"> 
            <div className={"MuiPaper-root MuiPaper-elevation4 MuiPaper-rounded myHeader " + THEME}> 
              <this.LogoArea />

              {/* !!! faq begins */}
              <this.UserArea 
                openPasswordChangeModal={this.openModal} 
                openEmailChangeModal={this.openModalEmail}
                openModalInfo={this.openModalInfo} 
                user={user} 
              />
              {/* !!! faq ends */}
            </div>
            {user.isManager &&
              <div className="myMenu">
                <NewMenu isManager={user.isManager} />
              </div>
            }
            <div className={user.isManager ? "myContent" : "myContentClient"}>
            <Switch>
              <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />
              {/* <Route exact path="/sportfive" render={(props) => <ReportingPage {...props} update={this.update} 
                companyList={this.state.companyList} user={user} matches={this.state.matches} />} /> */}
              <Route exact path="/"  render={(props) => <ReportingPage {...props} update={this.update} 
                companyList={this.state.companyList} user={user} matches={this.state.matches} />} >
              </Route>
              {user.isManager &&
              <React.Fragment>
                <Route path="/images" render={(props) => <ImagesPage {...props} 
                  companies={this.state.companyList} user={user} />} 
                />
                
                <Route path="/videos" render={(props) => <VideosPage {...props} 
                  companies={this.state.companyList} user={user} matches={this.state.matches} />} 
                />
                <Route path="/admin" render={(props) => <AdminPage {...props} 
                  matches={this.state.matches} updateAdminPageProps={this.updateAdminPageProps} userInfo={user}/>} 
                />
                {/* !!! faq begins */}
                <Route path="/faq" render={(props) => <FaqPage {...props} 
                  userInfo={user}/>} 
                />
                {/* !!! faq ends */}
              </React.Fragment>
              }    
                <Redirect to="/" />
            </Switch>

            </div>
            {/* <div className="globalFooter"></div> */}
            </div> {/* --- contentWrap ends --- */}
            
            <div className={"MuiPaper-root MuiPaper-elevation4 MuiPaper-rounded globalFooter " + THEME}>
            {/* <div className="break"></div> */}
              <hr className="globalPageFooterLine"></hr>
              {/* <img alt="" className="logoSupponor" 
                src={process.env.PUBLIC_URL + '/vector-logo.png'} /> */}
              <span className={"footerText " + THEME}>Copyright © {CURRENT_YEAR} Supponor Oy. All rights reserved &nbsp;&nbsp;|&nbsp;&nbsp; Virtual Advertising Documentation Platform <span className="appVersionText">{APP_VERSION}</span> &nbsp;&nbsp;|&nbsp;&nbsp; <a href = "mailto: alexandru.oat@supponor.com">Give feedback</a></span>
              
            </div>

          </div>
      </Router>
      <Dialog open={open} TransitionComponent={Transition} onClose={this.closeModal} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Change password</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Please, confirm your current password and enter a new one (min. 6 characters).
            </DialogContentText>
            <TextField
                inputRef={c => this.oldPwdRef = c}
                autoFocus
                // inputProps={{
                //   autocomplete: 'new-password',
                //   form: {
                //     autocomplete: 'off',
                //   },
                // }}
                // more info: https://stackoverflow.com/questions/48304062/material-ui-textfield-disable-browser-autocomplete
                autoComplete='new-password'
                margin="dense"
                id="oldPassword"
                label="Current password"
                type="password"
                required
                fullWidth
            />
            <TextField
                inputRef={c => this.newPwdRef = c}
                autoComplete='new-password'
                margin="dense"
                id="newPassword"
                label="New password"
                type="password"
                required
                fullWidth
            />
            <TextField
                inputRef={c => this.confirmNewPwdRef = c}
                autoComplete='new-password'
                margin="dense"
                id="confirmNewPassword"
                label="Confirm new password"
                type="password"
                required
                fullWidth
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={this.closeModal} color="primary">
                Cancel
            </Button>
            <Button onClick={(event) => this.handleResetPassword(this.oldPwdRef.value, this.newPwdRef.value, this.confirmNewPwdRef.value)} color="secondary">
                Reset password
            </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={openEmail} TransitionComponent={Transition} onClose={this.closeModalEmail} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Change my email</DialogTitle>
            <DialogContent>
            <DialogContentText>
                Change the email address for your account. This will be your new username
            </DialogContentText>
            <TextField
                inputRef={c => this.newEmailRef = c}
                margin="dense"
                id="newEmail"
                label="New email"
                type="email"
                required
                fullWidth
            />
            <TextField
                inputRef={c => this.confirmPwdRef = c}
                autoComplete='new-password'
                margin="dense"
                id="confirmPassword"
                label="Confirm your password"
                type="password"
                required
                fullWidth
            />
            </DialogContent>
            <DialogActions>
            <Button onClick={this.closeModalEmail} color="primary">
                Cancel
            </Button>
            <Button onClick={(event) => this.handleChangeEmail(this.newEmailRef.value, this.confirmPwdRef.value)} color="secondary">
                Change email
            </Button>
            </DialogActions>
        </Dialog>
        {/* !!! faq begins */}
        <Dialog 
          open={openInfo} 
          TransitionComponent={Transition} 
          onClose={this.closeModalInfo} 
          aria-labelledby="form-dialog-title"
          scroll="paper"
        >
            <DialogTitle id="form-dialog-title">Frequently Asked Questions</DialogTitle>
            {this.state.questions.length < 1 ?
              <DialogContent> Section is empty at the moment </DialogContent>
              :
              <DialogContent dividers={true}>
                {this.state.questions.map((question) =>
                    <DialogContentText key={question.question}>
                        Q: <b>{question.question}</b>
                        <br />
                        A: {question.answer}
                    </DialogContentText>
                )}
            
              </DialogContent>
            }
            <DialogActions>
            <Button onClick={this.closeModalInfo} color="primary">
                Close
            </Button>
            </DialogActions>
        </Dialog>
        {/* !!! faq ends */}

        </MuiThemeProvider>    
      </UserContext.Provider>
    );
  }
}
const theme = {
  ...AmplifyTheme,
  container: {
    ...AmplifyTheme.container,
    padding: 0,
    marginTop: "-8px"
  },
  navBar: {
    ...AmplifyTheme.navBar,
    background: "#dbe8f4",  
    backgroundImage:"-webkit-linear-gradient(top,#08364e,#094361)",
    boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    border: 0
  },
  button: {
    ...AmplifyTheme.button,
    backgroundColor: "#41769c",
    color: "white"
  },
  sectionBody: {
    ...AmplifyTheme.sectionBody,
    padding: "0px"
  },
  sectionHeader: {
    ...AmplifyTheme.sectionHeader,
    backgroundColor: "var(--squidInk)",
    display: "none"

  }
};

// https://techinscribed.com/authentication-in-serverless-react-application-using-aws-amplify/
const signUpConfig = {
  header: 'Create an Account',
  hideAllDefaults: true,
  defaultCountryCode: '1',
  signUpFields: [
    {
      label: 'Name',
      key: 'name',
      required: true,
      displayOrder: 1,
      type: 'string'
    },
    {
      label: 'Email',
      key: 'username',
      required: true,
      displayOrder: 2,
      type: 'string'
    },
    {
      label: 'Password',
      key: 'password',
      required: true,
      displayOrder: 3,
      type: 'password'
    }
  ]
};

export class LoginPage extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }
  showComponent(theme) {
    return (
      <Router>
        <Switch>
          {/* remove trailing slash */}
          <Redirect from="/:url*(/+)" to={window.location.pathname.slice(0, -1)} />  
            <Route exact path="/">
              <Redirect to="/" /> 
            </Route>
        </Switch>
        <LoginPage_2 />
      </Router>
    );
  }
}
export class LoginPageFooter extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }
  showComponent(theme) {
    return (
      <div className="loginPageFooter">
        <hr className="loginPageFooterLine"></hr>
        <p className="footerText">Copyright © {CURRENT_YEAR} Supponor Oy. All rights reserved &nbsp;&nbsp;|&nbsp;&nbsp; Virtual Advertising Documentation Platform {APP_VERSION} &nbsp;&nbsp;|&nbsp;&nbsp; <a href = "mailto: alexandru.oat@supponor.com">Give feedback</a></p>
      </div>
    );
  }
}

export default withAuthenticator(App, {
  // Render a sign out button once logged in
  includeGreetings: false, 
  signUpConfig,
  // Show only certain components
  authenticatorComponents: [
    <LoginPage/>,
    <SignIn/>,
    <LoginPageFooter/>,
    // <CustomSignIn/>,
    <ConfirmSignIn/>,
    <VerifyContact/>,
    <SignUp/>,
    <ConfirmSignUp/>,
    <ForgotPassword/>,
    <RequireNewPassword />],
  federated: null, 
  theme: theme});
