import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
// import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
// import Paper from '@material-ui/core/Paper';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 12,
  },
  root: {
      border: 0,
      padding: 5
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

function createData(name, value) {
  return { name, value };
}

// const rows = [
//   createData('Place', 'Dortmund'),
//   createData('Date', '29 June 2020'),
//   createData('Minutes', 12),
//   createData('Regions', 'EMEA AMER CEAS FOX'),
// ];

const useStyles = makeStyles({
  table: {
    minWidth: 180,
  },
});

export default function CustomizedTables(props) {
  const classes = useStyles();
  
  const cellClass = props.cellClass ? props.cellClass : "";
  return (
    <TableContainer>
      <Table className={classes.table} aria-label="customized table">
        <TableBody>
          {props.rows.map((row) => (
            <StyledTableRow key={row[0]}>
              <StyledTableCell component="th" scope="row">
                {row[0]}
              </StyledTableCell>
              <StyledTableCell className={cellClass} align="right">{row[1]}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}