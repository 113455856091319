export const APP_VERSION = 'v. 1.7.5';
export const THEME = 'sportfive';
export const DISPLAY_NEW_FEATURES_1_4_4 = THEME === 'sportfive';
export const CHECK_SINGLE_SESSION = true;
export const ACCESS_DB_ON_TABLE_LOAD = true;

export const CHECK_HUB_DB_ON_STARTUP = false;

export const MEDIA_BUCKET = 'analytics-app-videos';
export const HUB_DB_DUMP_FILE = 'hub/event.json';
export const VIDEO_PATHS_FILE = 'video/test/videos.json';
export const VIDEO_PREFIX = 'video/';
export const UPLOAD_PREFIX = 'upload/';
export const HIGHLIGHT_PREFIX = 'highlight/';

export const DAYS_FULLLENGTH_VISIBLE_TO_CLIENTS = 7;

export const DEFAULT_LOGO = 'https://tmpfilestoragefcb10557-fcbayern.s3.eu-central-1.amazonaws.com/events/0/logo.png';
export const DEFAULT_COMP = 'Bundesliga 2023-24';

export const FEEDS = {
    1: "Canada",
    2: "Czech",
    3: "NBC",
    4: "USA", 
    5: "World"
}

export const HUB_FEEDS = {
    1: "Canada",
    2: "Czech",
    3: "NBC",
    4: "USA", 
    5: "World"
};
export const FEEDS_SPORTFIVE_5_FEED_MODEL = {
    1: "Canada",
    2: "Czech",
    3: "NBC",
    4: "USA", 
    5: "World"
};
export const DATE_5_FEED_MODEL = '2021-11-18';

export const RENAME_MATCHES = false; // trim to 3 words (e.g. BVB v VfL)

export const RESIZE_LAMBDA_NAME = 'resizenhlapplambda';//THEME === 'sportfive' ? 'testlambda' : 'resizelambda';
export const RESIZE_LAMBDA_PATH = '/resize';//THEME === 'sportfive' ? '/test' : '/resize';

export const CLOUDFRONT_DOMAIN_NAME = '';//'http://d2rqley2o7owei.cloudfront.net/';

// tmp. Will be replaced with db stored urls
export const TMP_MATCH_DATA = 
    THEME === 'sportfive' ?
    [
        {id: "0", fullName: "", competition: "2021-22 Season", url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/icon-event.png"},
        { id: "1", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/1/logo.png"
        },    
        { id: "2", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/2/logo.png"
        },
        { id: "3", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/3/logo.jpg"
        },
        { id: "4", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/4/logo.jpg"
        },
        { id: "5", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/5/logo.jpg"
        },
        { id: "6", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/6/logo.jpg"
        },
        { id: "7", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/7/logo.jpg"
        },
        { id: "1584", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/1584/logo.png"
        },
        { id: "1585", competition: "2022 NHL All-Star", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/1585/logo.png"
        },
        { id: "1587", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/1587/logo.jpg"
        },
        { id: "1588", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/1588/logo.png"
        },
        { id: "1575", competition: "2021-22 Season", fullName: "past match",
            url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/1575/logo.jpg"
        },
        // { id: "1584", competition: "2021-22 Season", fullName: "past match",
        //     url: "https://tempfilestoragenhlapp184844-nhlapp.s3.eu-central-1.amazonaws.com/events/7/logo.jpg"
        // },
    ]

    // sportfive
    : 
    [
    // default
    {id: "0", competition: "Bundesliga", fullName: "", url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/icon-event.png"},
    // FCB - BVB 30.09
    {id: "102", competition: "DFL Supercup", fullName: "past match",
    url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/Begegnungen_VA-Tool_Vorlage_FCB-BVB.png"
    //url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/FCBBVB.jpg"
    },
    // BVB - BMG 19.09
    {id: "94", competition: "Bundesliga", fullName: "FCB_v_FSV_KO-1800_CET",
    url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/Begegnungen_VA-Tool_Vorlage_BVB-BMG.png"
    },
    // url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/BVBBMG_resized.png"},
    //url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/bvb_bmg.png"},
    // BVB - SCF 03.10
    {id: "96", competition: "Bundesliga", fullName: "past match",
    url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/Begegnungen_VA-Tool_Vorlage_BVB-SCF.png"
    },
    // "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/BVBSCF_resized.png"},
    //"https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/bvb_scf_iygMreo.jpg"},
    // BVB - S04 24.10
    {id: "97", competition: "Bundesliga", fullName: "past match",
    url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/Begegnungen_VA-Tool_Vorlage_BVB-S04.png",
    //url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/BVBS04_i4hKVy3.jpg"
    },
    // BVB - FCB 07.11
    {id: "98", competition: "Bundesliga", fullName: "past match",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/Begegnungen_VA-Tool_Vorlage_BVB-FCB.png"
    //url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/BVBFCB_PuVXavx_resized.jpg"
    },
    // BVB - FCK 28.11
    {id: "107", competition: "Bundesliga", fullName: "BVB_v_FCK_KO-1530_CET",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/BVB-KoLN.png"
    },
    // SGE - BVB 05.12
    {id: "106", competition: "Bundesliga", fullName: "SGE_v_BVB_KO-1530_CET",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/SGE-BVB.png"
    },
    // BVB - VfB 12.12
    {id: "108", competition: "Bundesliga", fullName: "BVB_v_VfB_KO-1530_CET",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/BVB-VFB.png"
    },
    // BVB - VfL 03.01
    {id: "109", competition: "Bundesliga", fullName: "BVB_v_VfL_KO-1530_CET",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/Begegnungen_VA-Tool_Vorlage_BVB-WOB.png"
    },
    // BVB - FSV 16.01
    {id: "110", competition: "Bundesliga", fullName: "BVB_v_FSV_KO-1530_CET",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/Begegnungen_VA-Tool_Vorlage_BVB-M05_small.png"
    },
    //FCA - FCB ___ 20.01
    {id: "103", competition: "Bundesliga", fullName: "FCA_v_FCB_KO-2030_CET",
    url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/icon-event.png"
    //   url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/FCA-_BVB_resized.jpg"
    },
    //FCA - FCA ___ 30.01
    {id: "111", competition: "Bundesliga", fullName: "BVB_v_FCA_KO-2030_CET",
    url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/30_01_Begegnungen_VA-Tool_Vorlage_BVB-FCA.png"
    //   url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/FCA-_BVB_resized.jpg"
    },
    //BVB - TSG ___ 13.02
    {id: "112", competition: "Bundesliga", fullName: "BVB_v_TSG_KO-1530_CET",
    url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/13_02_Begegnungen_VA-Tool_Vorlage_BVB-TSG.png"
    //   url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/FCA-_BVB_resized.jpg"
    },
        //SGE - FCB ___ 20.02
        {id: "114", competition: "Bundesliga", fullName: "SGE_v_FCB_KO-1530_CET",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/114/logo.png"
        //   url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/FCA-_BVB_resized.jpg"
        },
    //BVB - DSC ___ 27.02
    {id: "113", competition: "Bundesliga", fullName: "BVB_v_DSC_KO-1530_CET",
    url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/27_02_Begegnungen_VA-Tool_Vorlage_BVB-DSCA.png"
    //   url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/FCA-_BVB_resized.jpg"
    },
    //BVB - BSC 13.05
    {id: "118", competition: "Bundesliga", fullName: "BVB_v_BSC_KO-1830_CET",
    url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/118/logo.png"
    //   url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/FCA-_BVB_resized.jpg"
    },
    //BVB - SGE 03.04
    {id: "119", competition: "Bundesliga", fullName: "BVB_v_SGE_KO-1530_CET",
    url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/119/logo.png"
    //   url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/FCA-_BVB_resized.jpg"
    },
    //BVB - SG 14.08
    { id: "131", competition: "Bundesliga", fullName: "BVB_v_SG_KO-1830_CET",
      url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/131/logo.png"
      //   url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/FCA-_BVB_resized.jpg"
    },
    //BVB - FCB 17.08 ATTENTION! logo.jpg - NOT PNG
    { id: "134", competition: "DFL Supercup", fullName: "BVB_v_FCB_KO-2030_CET",
      url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/134/logo.jpg"
      //   url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/hub/images/FCA-_BVB_resized.jpg"
    },
    { id: "135", competition: "Bundesliga", fullName: "BVB_v_TSG_KO-2030_CET",
      url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/135/logo.png"
    },
    // BVB - FCU, 19.09
    { id: "138", competition: "Bundesliga", fullName: "BVB_v_FCU_KO-2030_CET",
      url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/138/logo.png"
    },
    // BVB - FCA, 02.10
    { id: "141", competition: "Bundesliga", fullName: "BVB_v_FCA_KO-1530_CET",
      url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/141/logo.png"
    },
        // BVB - FSV, 16.10
        { id: "145", competition: "Bundesliga", fullName: "BVB_v_FCA_KO-1530_CET",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/145/logo.png"
      },
    // BVB - FCK, 30.10
    { id: "146", competition: "Bundesliga", fullName: "BVB_v_FCA_KO-1530_CET",
      url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/146/logo.png"
    },
    // BVB - VfB, 20.11
    { id: "149", competition: "Bundesliga", fullName: "BVB_v_VFB_KO-1530_CET",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/149/logo.png"
    },
    // BVB - FCB, 04.12
    { id: "150", competition: "Bundesliga", fullName: "BVB_v_FCB_KO-1530_CET",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/150/logo.png"
    },
    // BVB v SpVgg, 15.12
    { id: "155", competition: "Bundesliga", fullName: "BVB_v_SpVgg_KO-2030_CET",
        url: "https://demod721ccda4bf7422ea103976571d6d0da181359-analytics.s3.eu-central-1.amazonaws.com/events/155/logo.png"
    },
]