import aws_exports from '../aws-exports';
import moment from 'moment';
import { listCompanys, listUsers, listCompanyMatchBookings } from '../graphql/queries';
import { API, Auth, graphqlOperation } from "aws-amplify";
import { MEDIA_BUCKET, HUB_DB_DUMP_FILE } from '../utils/constants';

const AWS = require('aws-sdk');
AWS.config.update ({
    region: aws_exports.aws_project_region
});
let S3, cognito;
const user_pool_id = aws_exports.aws_user_pools_id;

// TODO ------- 18.05 - remove or correct syncDB
export async function syncDb () {

    const options = {
        Bucket: aws_exports.aws_user_files_s3_bucket,
        Key: 'public/event.json',
        ResponseContentType: 'application/json'
    };
    const credentials = await Auth.currentCredentials();
    S3 = new AWS.S3({ 
        signatureVersion: 'v4', 
        credentials: Auth.essentialCredentials(credentials) 
    });

    S3.getObject(options, function(err, data) {
        if (err){
            //console.log("Error: %s %s", err, err.stack);
            return false;
        }
        else {
            const arr = data.Body;
            let str = "";   
            for (let i = 0; i < arr.byteLength; i++) {
                str += String.fromCharCode(arr[i]);
            }
            const result = JSON.parse(str);
            console.log(result);
            const curYear = moment().format('YY');
            const dates = result.filter(match => 
                moment(match.fields.when).format('YY') == curYear)
                .map(match => match.fields);
            console.log(dates)
            return dates;
            
        }
    });
}

// get list of all users in the current pool (limit = 60)
async function handleListUsers() {
    try
    {
        const credentials = await Auth.currentCredentials();
        const cognito = new AWS.CognitoIdentityServiceProvider({
            credentials: Auth.essentialCredentials(credentials)
        });
   
        const maxNumOfIterations = 5;
        let iteration = 0;
        let result = await cognito.listUsers({
            UserPoolId: user_pool_id
        }).promise();

        let allUsers = result.Users;
        let nextToken = result.PaginationToken;

        while (nextToken && iteration < maxNumOfIterations) {
            iteration++;
            result = await cognito.listUsers({
                UserPoolId: user_pool_id,
                PaginationToken: nextToken,
            }).promise();
            nextToken = result.PaginationToken;
            allUsers.push(...result.Users);
        }

        return allUsers;//result.Users;
    } catch (err) {
        throw err;
    }
}

// get users from a certain group (managers in our case)
async function handleListUsersInGroup(groupName)  {
    try 
    {
        const credentials = await Auth.currentCredentials();
        const cognito = new AWS.CognitoIdentityServiceProvider({
            credentials: Auth.essentialCredentials(credentials)
        });

        const result = await cognito.listUsersInGroup({
            UserPoolId: user_pool_id,
            GroupName: groupName
          }).promise();
        // if (result)
        //     console.log(result.Users);
        return result.Users;
    } catch (err) {
          throw err;
    }
}

export async function getUserDbRecords(limit = 1000) {
    // const limit = 100;
    const result = await API.graphql(graphqlOperation(listUsers, { limit }));
    return result.data.listUsers.items;
}
export async function getUsers() {
    const usersData = await handleListUsers();
    const userDbRecords = await getUserDbRecords(1000);
    let users = [];

    usersData.map((user, i) => {
        const userDbRecord = userDbRecords.find(record => record.id === user.Username);
        const company = userDbRecord ? userDbRecord.company : "";
        const role = userDbRecord.isManager ? 'manager' : 'client';
        const email = userDbRecord.email;
        const dateMoment = moment(user.UserCreateDate, "DD/MM/YYYY").format('DD.MM.YYYY');
        const lastActivityDate = (userDbRecord.extra && role === 'client') ? 
            moment(new Date (userDbRecord.extra)).format('DD.MM.YYYY') : "";
        users.push({
            role,
            username: user.Username,
            status: user.UserStatus,
            enabled: user.Enabled,
            dateCreated: dateMoment,
            lastActivityDate,
            email,
            company
        }) 
    })
    return users;
}
export async function getCompanies(limit = 1000) {
    const result = await API.graphql(graphqlOperation(listCompanys, { limit }));
    return result.data.listCompanys.items.sort((a, b) => a.name.localeCompare(b.name));
}

// get all the entries from DB (beyond the max query limit of 1000)
export async function getAllCompanyMatchBookings(limit = 1000, maxNumOfIterations = 5) {
    let iteration = 0;
    let result = await API.graphql(graphqlOperation(listCompanyMatchBookings, { limit }));
    let allItems = result.data.listCompanyMatchBookings.items;
    let nextToken = result.data.listCompanyMatchBookings.nextToken;

    while (nextToken && iteration < maxNumOfIterations) {
        iteration++;
        result = await API.graphql(graphqlOperation(listCompanyMatchBookings, { limit, nextToken }));
        nextToken = result.data.listCompanyMatchBookings.nextToken;
        console.log(result.data.listCompanyMatchBookings.items);
        allItems.push(...result.data.listCompanyMatchBookings.items);
    }

    return allItems;
}

export async function isManager(username) {
    const managers = await handleListUsersInGroup('Managers');
    let result = false;
    console.log(managers);
    if (managers.some(e => e.Username === username)) {
        result = true;
    }
    return result;
}

// returns JSON file contents
export async function getJsonObject (bucket=aws_exports.aws_user_files_s3_bucket, key=HUB_DB_DUMP_FILE) {
    try {
        const options = {
            Bucket: bucket,
            Key: key,
            ResponseContentType: 'application/json',
            ResponseCacheControl: 'No-cache'            // ensure json file is not cached
        };      

        const credentials = await Auth.currentCredentials();
        S3 = new AWS.S3({ 
            signatureVersion: 'v4', 
            credentials: Auth.essentialCredentials(credentials) 
        });
    
        const data = await S3.getObject(options).promise();
        if (!data) {
            console.log("COULD NOT FETCH JSON from S3");
            return null;
        }

        const arr = data.Body;
        let str = "";
        for (let i = 0; i < arr.byteLength; i++) {
            str += String.fromCharCode(arr[i]);
        }
        
        return JSON.parse(str);

    } catch (e) {
        console.log(`Could not retrieve file from S3: ${e.message}`);
        return null;
    }
}

// convert size in bytes to KB/MB
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

// insert string in filename before extension suffix 
// e.g. public/file.jpg -> public/file_resized.jpg
export function appendToFilename(filename, string, prefix=null){
    if (prefix)
        filename = prefix + filename;
    var dotIndex = filename.lastIndexOf(".");
    if (dotIndex == -1) return filename + string;
    else return filename.substring(0, dotIndex) + string + filename.substring(dotIndex);
}

export function appendToFilenameAndPrefix(filename, string, prefix=null){
    if (prefix)
        filename += prefix;
    var dotIndex = filename.lastIndexOf(".");
    if (dotIndex == -1) return filename + string;
    else return filename.substring(0, dotIndex) + string + filename.substring(dotIndex);
}

// extract the filename from a file path
// e.g. public/dir/filename.jpg -> filename.jpg
export function getFileNameFromUrl(fileUrl) {
    let fileName = fileUrl;
    const n = fileUrl.lastIndexOf('/');
    if (n > -1) // does the url contain any '/' characters?
        fileName = fileUrl.substring(n + 1);
    return fileName;
}

// build s3 image URL (public!)
// TODO: handle no-image cases, add 'noimage.jpg'
export function buildS3Url(image, isFullsize) {
    let key;
    if (isFullsize || !image.thumbnail) // fallback to orig in case there's no thumb file
        key = image.fullsize.key;
    else
        key = image.thumbnail.key;
    return "https://" + image.bucket + ".s3." + image.region + ".amazonaws.com/public/" + key;
}

// get last segment of url (for menu component)
export function getUrl() {
    let result = window.location.pathname.split("/").pop();
    return '/' + result;
}

export async function getSignedUrl(key, folder="video/", expires=86400) { 
    // key in the db is stored without folder
    const fullKey = folder + key;
    const credentials = await Auth.currentCredentials();
    S3 = new AWS.S3({ 
        signatureVersion: 'v4', 
        credentials: Auth.essentialCredentials(credentials) 
    });

    return new Promise((resolve,reject) => {
        let params = { Bucket: MEDIA_BUCKET, Key: fullKey, Expires: expires };
        S3.getSignedUrl('getObject', params, (err, url) => {
            if (err) reject(err)
            resolve(url);
      })
    });
}