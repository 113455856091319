import { createTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';

export const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 280,
      fontSize: theme.typography.pxToRem(14),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);

// check https://material-ui.com/customization/theming/
// for more info on MUI customization
export const menuTheme = createTheme({
  typography: {
    fontFamily: 'Baloo 2, cursive',
    // fontSize: 15
  },
  overrides: {
    MuiList: {
      root: {
        width: 220
      } 
    },
    MuiMenuItem: {
      root: {
        // background: 'transparent',
        '&$selected': { // <-- mixing the two classes
          background: 'transparent',
          color: '#4ca2b5',
          // color: '#03a9f4'
        },
        '&$hover': { // <-- mixing the two classes
          background: 'transparent',
          background: '#f6f8fa',
          backgroundColor: '#f6f8fa',
          // '#2f9cd014',
          color: '#4ca2b5',
          // color: '#03a9f4'
        }
      }
    }, 
    // MuiSvgIcon: {
    //   root: {          
    //     fill: '#3572908a',
    //   } 
    // }
  }
});

export const reportingPageTheme = createTheme({
    typography: {
      fontFamily: 'Baloo 2, cursive',
      fontSize: 14,
    },
    palette: {
        background: '#aaa',
        color: 'primary'
      }
});

export const panelTheme = createTheme({
    overrides: {
      MuiButton: {
        label: {
          justifyContent: 'left'
        },
        root: {
            textTransform: 'none',
            marginBottom: '10px',
            minWidth: '105px',
            width: '100%'
        },
        containedPrimary: {
          backgroundColor: '#4ca2b5',
        },
        iconSizeSmall: {
            fontSize: '12px'
        },
        containedSizeSmall: {
            fontSize: '0.75rem',
            padding: '6px 10px'
        }
      },
    },
  });
export const adminPageTheme = createTheme({
    typography: {
      fontFamily: 'Baloo 2, cursive',
      fontSize: 14,
    },
    palette: {
        background: '#aaa',
        color: 'primary'
      }
});