import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import watermark from 'videojs-watermark';
import 'videojs-watermark/dist/videojs-watermark.css';
// https://github.com/videojs/video.js/blob/master/docs/guides/react.md

class VideoPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: false,
          }
        }
  
  
    componentDidMount() {
    
    // register watermark-plugin (only if it hasn't been registered already on this page)
    if(!videojs.getPlugin('watermark')) // 
        videojs.registerPlugin('watermark', watermark);

    const { videoSrc, poster } = this.props;
    const { playerRef } = this.refs;

    // instantiate Video.js
    this.player = videojs(playerRef, { 
            controls: true, 
            autoplay: false, 
            muted: true,
            controlBar: { 
              // this hides muteSound button and volume control bar
              volumePanel: false, muteToggle: true, 
              // to hide progress bar uncomment line below
              // progressControl: false 
            },
            poster: process.env.PUBLIC_URL + poster,
            width: 512, height: 384  
          }, () => {

              if (this.props.watermark)
                    this.player.watermark({
                        image: process.env.PUBLIC_URL + '/watermark.png',
                        fadeTime: null,
                        // xpos: 0,
                        // ypos: 100,
                        // top: 20,
                        // right: 40,
                        xrepeat: 0,
                        opacity: 0.9,
                    });

                this.player.src({src: videoSrc, type: 'video/mp4'});
            });
    if (this.props.watermark)        
        this.player.on('fullscreenchange', this.handleFullscreenChange);
    // show poster image after video has ended
    this.player.on('ended', () => {
          if(this.player.isFullscreen())
              this.player.exitFullscreen();
          this.player.hasStarted(false);
    });

///////// 19.10.20 - show video segment only
  //     this.player.on('timeupdate', () => {
  //       // if (this.player.currentTime() > 1)
  //       // this.player.controls(false);
  //       // this.player.controlBar.;
  //       const curTime = this.player.currentTime();
  //       // if (curTime > 20 && curTime < this.player.duration()) {
  //       if (curTime > 20 && curTime < 200) {
  //           // this.player.currentTime(this.player.duration());
  //           // this.player.currentTime(this.player.duration());
  //           // this.player.pause();
  //           this.player.currentTime(210);
            
  //       }
  //       else if (curTime > 220) {
  //         this.player.currentTime(10);
  //         this.player.pause();
  //       }
  //   })
  //   this.player.on('ready', () => {
  //     this.player.currentTime(10);
  // })
/////////////////////////////////

  }
  handleFullscreenChange = () => {
      this.setState({ isFullscreen: this.player.isFullscreen() });
  } 
  componentDidUpdate(prevProps){
    // overlay code here though it is not registering the state change. including it only for reference. 

    // this.player.overlay(() =>{
    //   if(liveVideoPlaying){
    //     content: 'Live'
    //  }
    // else if (isAdRolling){
    //     content: 'overlay ad'
    //  }
  // the player is instantiated and then I've got the below:
  // console.log("update")
  //   this.player.on('fullscreenchange', function (e){
  //        if(this.player.isFullscreen()){
  //          this.player.addClass('full-screen-badge')
  //          console.log("FULL")
  //        }
  //      });
  //   this.player.on('fullscreenchange', function(e) {
  //       if (this.player.isFullscreen()) {
  //         this.player.controls(true);
  //         console.log("FULL---")
  //       } else {
  //         this.player.controls(false);
  //         console.log("NOT FULL")
  //       }
  //     });
}

  // destroy player on unmount
  componentWillUnmount() {
      if (this.player) {
          //   this.player.dispose();
          console.log("video.js player unmounted");
      }
  }
  // componentWillReceiveProps(newProps) {
    // When a user moves from one title to the next, the VideoPlayer component will not be unmounted,
    // instead its properties will be updated with the details of the new video. In this case,
    // we can update the src of the existing player with the new video URL.
    // if (this.player) {
    //   this.player.sources = [{
    //     type: newProps.video.mime_type,
    //     src: newProps.video.video_url
    //   }];
    // } 
  // }

  render() {
    return (
        <div className={this.state.isFullscreen ? "fullscreenVideo" : "nonFullscreenVideo"}>
          <div data-vjs-player>
            <video 
              // onClick={this.test} 
              // onFullScreenChange={this.test2}
              //onTimeUpdate={this.test} onChange={this.test2} 
              ref="playerRef" 

              // preload="none"
              className="video-js vjs-16-9" 
              playsInline 
            />
          </div>
        </div>
    )
  }
}

export default VideoPlayer;