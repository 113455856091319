import React, { Component } from 'react';
import { Storage, API, graphqlOperation, Auth } from "aws-amplify";
import { createImage, deleteImage } from '../graphql/mutations';
import { getUser } from '../graphql/queries';
import { appendToFilename, getFileNameFromUrl, buildS3Url, getSignedUrl } from '../utils/functions';
import { FEEDS, CHECK_SINGLE_SESSION, THEME, DISPLAY_NEW_FEATURES_1_4_4 } from '../utils/constants';
import ImageGallery from 'react-image-gallery';
import { Segment, Divider as SemanticDivider, Pagination, Icon, Menu, Message } from 'semantic-ui-react';
import Grid from '@material-ui/core/Grid';
import aws_exports from '../aws-exports';
import Toast from 'light-toast';
import "../App.css";
import { HtmlTooltip } from '../utils/customThemes';
import VideoPlayer from '../components/videoPlayer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import Button from '@material-ui/core/Button';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const AWS = require('aws-sdk');
AWS.config.update ({
    region: aws_exports.aws_project_region
});
let S3;

class Panel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isUploading: false,
            images: null,
            feedImages: null,
            matchInfo: '',
            tab: null,
            feedVideos: [],
            activeItem: 'Images',
            activeVideo: 1
        };
    }
    async componentDidMount() {
        const credentials = await Auth.currentCredentials();
        S3 = new AWS.S3({ 
            signatureVersion: 'v4', 
            credentials: Auth.essentialCredentials(credentials) 
        });

        this.ensureUserSingleSession();
        console.log(this.props)
        const allGalleryImages = this.getAllGalleryImages();
        const allVideos = await this.getAllVideos();
        const visibleFeeds = this.getVisibleFeeds(allGalleryImages, allVideos);

        let initialTab = 1;
        if (visibleFeeds.length > 0)
            initialTab = visibleFeeds[0];

        const feedImages = this.getFeedImages(initialTab, allGalleryImages);
        const feedVideos = this.getFeedVideos(initialTab, allVideos);
        let activeItem = 'Images';
        if (feedImages.length === 0) {
            const feedHighlights = feedVideos.filter(video => video.extra !== 'F');
            if (feedHighlights.length > 0)
                activeItem = 'Highlight Videos';
            else
                activeItem = 'Match Records Full Length';
        }

        const minuteAllocation = DISPLAY_NEW_FEATURES_1_4_4 ? await this.getMinuteAllocation() : null;
        
        ///// MESSAGE TO CLIENTS
        let messageToClients;
        if (DISPLAY_NEW_FEATURES_1_4_4) {
            const visibleToClients = this.parseMessageToClients(1);
            console.log(visibleToClients);
            if (visibleToClients)
                // if a message is visible to this client
                messageToClients = visibleToClients.includes(this.props.user.userCompany.id) ?
                                this.parseMessageToClients(0) : null;

            console.log(messageToClients, visibleToClients);
        }
        ///// MESSAGE TO CLIENTS ENDS
        
        
        this.setState({ 
            messageToClients,
            allGalleryImages,
            allVideos,
            minuteAllocation,
            visibleFeeds,
            activeItem,
            tab: initialTab,//visibleFeeds.length > 0 ? get,
            // feedImages: this.props.match.images.items.filter(image => image.feedNumber === 1),
            feedImages,
            feedVideos,
            // replace spaces with "_"
            // substring(0,10) purpose is to leave date only (cut off all the rest)
            matchInfo: (this.props.match.details + '_' +
                this.props.match.date.substring(0, 10)).replace(/[ ]|[/]/g, '_')
        });
    }

    componentWillUnmount() {
    }

    // messageToClients string includes the message itself
    // and the ids of the companies that have access to it.
    // There's a separator ('& & &') between message and the
    // ids in question. See example below:
    // 'message text& & &dc13a0f9-ff87-42f2-84bf-6da27593f000,ba081646-87cb-467e-8549-67969a8c9ffb'
    // Message 'message text' is visible in this case to 2 clients.
    // parseMessageToClients returns the message itself if part == 0
    // and it returns client ids in an array if part == 1
    parseMessageToClients = (part) => {
        const separator = '& & &';
        const messageParts = this.props.match.message ? this.props.match.message.split(separator) : [];
        let result;
        
        if (messageParts.length > part) {
            result = this.props.match.message.split(separator)[part];
            if (part === 1) {
                result = result.split(','); // convert string to array
            }
        }
        return result;

    }
    getMinuteAllocation = async () => {
        const bucket = aws_exports.aws_user_files_s3_bucket;
        const key = `events/${this.props.matchId}/minutes.csv`;
        const options = {
            Bucket: bucket,
            Key: key,
            // ResponseContentType: 'application/json',
            ResponseCacheControl: 'No-cache'            // ensure json file is not cached
        };
        try {
            const data = await S3.getObject(options).promise();
            const content = data.Body.toString('utf-8');
            let minuteAllocation;
            var rows = content.split(/\r\n|\n/);
            const numberOfFeeds = Object.keys(this.props.FEEDS).length;
            const numberOfColumns = (numberOfFeeds * 2) + 1;
            for (const row of rows) {
                if (row.length >= numberOfColumns) {
                    const cells = row.replaceAll(";", ",").split(",");
                    if (this.props.user.userCompany.name.toLowerCase() === cells[0].toLowerCase()) {
                        // old format (4 columns per feed)
                        //const values = [cells[5], cells[8], cells[1], cells[4], cells[9], cells[12], cells[13], cells[16]];
                        // new format (2 columns per feed)
                        let values = [];
                        if (numberOfFeeds === 5) // 5 feed model
                            values = [cells[1], cells[2], cells[3], cells[4], cells[5], cells[6], cells[7], cells[8], cells[9], cells[10]];
                        else
                            values = [cells[3], cells[4], cells[1], cells[2], cells[5], cells[6], cells[7], cells[8]];

                        const validatedValues = values.map(value => this.validateMinuteAllocationValue(value) ? value : '');
                        //console.log(values, validatedValues);
                        minuteAllocation = [this.props.user.userCompany.name, ...validatedValues];
                        //minuteAllocation = [this.props.user.userCompany.name, cells[5], cells[8], cells[1], cells[4], cells[9], cells[12], cells[13], cells[16]];
                        break;
                    }
                }
            }
            return minuteAllocation;
        } catch (err) {
            console.log("Minute allocation file not found or doesn't exist: " + err);
        }
        
    }
    validateMinuteAllocationValue = (value) => {
        let isOk = false;

        if (!value)
            return isOk;

        if (value.trim().length === 5) {
            const splitted = value.split(':');

            if (splitted.length === 2) {
                const minutes = splitted[0];
                const seconds = splitted[1];
                const isMinutesNumber = /^\d+$/.test(minutes);
                const isSecondsNumber = /^\d+$/.test(seconds);
                const minutesInt = parseInt(minutes);
                const secondsInt = parseInt(seconds);

                if (minutesInt <= 90 && minutesInt >= 0 && 
                    secondsInt >= 0 && secondsInt < 60 &&
                    isMinutesNumber && isSecondsNumber) {
                    
                    isOk = true;
                }
            }
        }
        
        return isOk;
    }

    ensureUserSingleSession = async () => {
        // console.log("ensureUserSingleSession")
        try {
        if (CHECK_SINGLE_SESSION && !this.props.user.isManager) {
            const input = { id: this.props.user.id };
            const userDbRecord = await API.graphql(
                graphqlOperation(getUser, input)
            );
            // console.log(userDbRecord);
            let acquiredToken = this.props.user.refreshToken;
            if (acquiredToken) {
                console.log("Checking login session...");
            }
            else {
                const user = await Auth.currentAuthenticatedUser();
                console.log(user);
                acquiredToken = user.signInUserSession.refreshToken.token;
            }
            
            if (userDbRecord.data.getUser.token !== null &&
                userDbRecord.data.getUser.token !== acquiredToken) {
                    Toast.info(`Signed in from another device. Logging out...`, 2500);
                    console.log("Signed in from another device. Logging out...", userDbRecord.data.getUser.token);
                    Auth.signOut()
                    .then(data => console.log(data))
                    .catch(err => console.log(err));
                    return;
            }
        }
        } catch (err) {
            Toast.fail('Error checking login session', 1000, () => {
                console.log(err);
          });
        }
    }
    getVisibleFeeds(allGalleryImages, allVideos) {
        let visibleFeeds = [];
        for (const feedNumberKey in this.props.FEEDS) {
            const feedNumber = parseInt(feedNumberKey); 
            if (this.getFeedImages(feedNumber, allGalleryImages).length > 0 ||
                this.getFeedVideos(feedNumber, allVideos).length > 0)
                    visibleFeeds.push(feedNumber);
        }
        return visibleFeeds;
    }
    getAllVideos = async () => {
        let allVideos = [];


        // 22.11.20
        // added temporarily (files for bayern-analytics are uploaded into video/bayern/)            
        const folderName = "video/";//THEME === "bayern" ? "video/bayern/" : "video/"
        ///////


        if (this.props.user.userCompany) {
            for (let video of this.props.match.videos.items) {
                if (video.company) { // just in case checking for "orphan" videos (to prevent error with null company)           
                    if (this.props.user.userCompany.id === video.company.id) {
                        if (video.extra === "F") {
                            if (!video.bucket) // TODO: remove this check later
                                continue;
                            
                            const lastAssignedVideoAvailabilityDate = new Date(video.bucket);
                            // set time to 00:00:00 in today's date 
                            const today = new Date().setHours(0,0,0,0);
                            if (lastAssignedVideoAvailabilityDate >= today)
                                console.log(video, "client's full-length visible");
                            else
                                continue;
                        }
                        // added temporarily (files for bayern-analytics are uploaded into video/bayern/)
                        if (video.key.startsWith("video/") || video.key.startsWith("highlight/") || video.key.startsWith("upload/")) // if video wasn't uploaded to a "proper" place
                            video.signedUrl = await getSignedUrl(video.key, ""); 
                        else
                            video.signedUrl = await getSignedUrl(video.key, folderName); 

                        allVideos.push(video);
                    }
                }
            }
        }
        else if (this.props.user.isManager) {
            for (let video of this.props.match.videos.items) {
                if (video.company) { // just in case checking for "orphan" videos (to prevent error with null company)           
                    if (video.key.startsWith("video/") || video.key.startsWith("highlight/") || video.key.startsWith("upload/")) // if video wasn't uploaded to a "proper" place
                        video.signedUrl = await getSignedUrl(video.key, ""); 
                    else
                        video.signedUrl = await getSignedUrl(video.key, folderName); 

                    allVideos.push(video);
                }
            }
        }
        return allVideos;
    }
    getFeedVideos = (feedNumber, allVideos) => {
        if (!allVideos)
            allVideos = this.state.allVideos;
        return allVideos.filter(video => video.feedNumber === feedNumber);
    }

    // populate images array for the gallery
    getAllGalleryImages = () => {
        let allGalleryImages = [];
        // console.log(this.props.match.images.items)
        // no-company users can't see any images, even for ALL. Return [] for them
        if (this.props.user.userCompany) {
            this.props.match.images.items.map((image) => {
                if (image.company) { // just in case checking for "orphan" images (to prevent error with null company)
                    if (this.props.user.userCompany.id === image.company.id) {
                        const origUrl = buildS3Url(image, true);
                        const thumbUrl = buildS3Url(image, false);
                        allGalleryImages.push ({
                            original: origUrl,
                            thumbnail: thumbUrl,
                            key: image.fullsize.key,
                            id: image.id,
                            feedNumber: image.feedNumber,
                            companyId: image.company ? image.company.id : null
                        });
                    }
                }
            });
        }
        else if (this.props.user.isManager) {
            this.props.match.images.items.map((image) => {
                if (image.company) { // just in case checking for "orphan" images (to prevent error with null company)
                    const origUrl = buildS3Url(image, true);
                    const thumbUrl = buildS3Url(image, false);
                    allGalleryImages.push ({
                        original: origUrl,
                        thumbnail: thumbUrl,
                        id: image.id,
                        key: image.fullsize.key,
                        feedNumber: image.feedNumber,
                        companyId: image.company.id
                    });
                }
            });
        }
        return allGalleryImages;
    }
    getFeedImages = (feedNumber, allGalleryImages) => {
        if (!allGalleryImages)
            allGalleryImages = this.state.allGalleryImages;
        
        return allGalleryImages.filter(image => image.feedNumber === feedNumber);
    }

    handleDeleteImage = async (image) => {
        try {
            // delete physical image from S3 storage    
            if (image.fullsize)
                Storage.remove(image.fullsize.key)
                    .then(result => console.log(result))
                    .catch(err => console.log(err));
            if (image.thumbnail)
                Storage.remove(image.thumbnail.key)
                    .then(result => console.log(result))
                    .catch(err => console.log(err));
            else
                console.log("No thumbnail file was found")
            // delete from DB
            const input = {id: image.id};
            const result = await API.graphql(
                graphqlOperation(deleteImage, { input })
            );
            console.log({ result });
            // console.log("before",this.props.match)
            // update props in grandparent component (app.js)
            // this.props.updateChildPropsAfterChange(image, this.props.match.id)
            // // console.log("after",this.props.match)
            // // delete from state
            // // const match = this.props.match;
            // // const imageIndex = match.images.items
            // //     .findIndex(im => im.id === image.id);
            // // match.images.items.splice(imageIndex, 1);
            // // this.props.updateChildPropsAfterChange("match")
            // // console.log(this.props.match)

            // const images = this.state.images.filter(
            //     el => el !== image
            // );
            // this.setState({ images });



            
            // console.log(this.props.match)
            
            const images = this.state.images.filter(
                el => el !== image
            );
            this.setState({ images });
            this.props.updateChildPropsAfterChange(image, this.props.match.id, 'delete');
            Toast.success('Image deleted', 500, () => {
                console.log(result);
            });
        } catch (err) {
            Toast.fail('Error deleting image', 1500, () => {
                console.log(err);
          });
        }
    }
    handleRightClick(ev) {
        console.log(ev);
        ev.preventDefault()
    }
    handleDownloadImage = async (key) => {
        const downloadFile = (bytesArray, fileName) => {
                // npm install js-file-download --save
                // alternative
                // FileDownload(bytesArray, fileName); 
                const blob = new Blob([bytesArray]);
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                link.click();
        }
        // Toast.loading('Saving to disk...');
        let fileName = getFileNameFromUrl(key);
        try {
            const publicKey = "public/" + key;
            console.log(publicKey);
            const options = {
                Bucket: aws_exports.aws_user_files_s3_bucket,
                Key: publicKey,
                ResponseContentType: 'application/json'
            };      
            const data = await S3.getObject(options).promise();
            // console.log(data.Body);
            downloadFile(data.Body, fileName);
            // Toast.hide();
        } catch (e) {
            throw new Error(`Could not retrieve file from S3: ${e.message}`)
        }
        // Storage.get(key, {download: true})
        //     .then(result => {
        //         console.log(result.Body);
        //         downloadFile(result.Body, fileName);
        //     })
        //     .catch(err => console.log(err));

    }
    resizeImage = async(bucket, srcKey, dstKey, width) => {
        let success = false;
        try {
                const generateThumbnail = await API.put("testlambda", "/test", {
                    body: {
                        bucket,
                        srcKey,
                        dstKey,
                        width
                    }
                });
                console.log(generateThumbnail);
                success = true;
        } catch (err) {
            Toast.info('Image will not be resized', 1500, () => {
                console.log(err);
          });
        }
        return success;
    }
    handleAddImage = async(file) => {
        // console.log(this.state.images.length)
        // if (this.state.images.length > 11) {
        //     Toast.info(`This prototype has a temporary limit of 12 images per match. \nThis limitation will be removed soon`, 6000, () => {});
        //     return true;
        // }
        try {
            // this.setState({ isUploading: true });
            const getUploadedFileDimensions = file => new Promise((resolve, reject) => {
                try {
                    let img = new Image();
                    img.onload = () => {
                        const width  = img.naturalWidth,
                              height = img.naturalHeight;
                        window.URL.revokeObjectURL(img.src);
                        return resolve({width, height})
                    }
                    img.src = window.URL.createObjectURL(file);
                } catch (exception) {
                    return reject(exception);
                }
            });
            let fileDimensions = await getUploadedFileDimensions(file);
            if (!fileDimensions.width || !fileDimensions.height)
                fileDimensions = {width: 0, height: 0};

            let fileSize = 0;
            if (file.size)
                fileSize = parseInt(file.size);

            if(fileSize > 10*1024*1024) {
                Toast.info("Unfortunately, file size exceeds temporary limit set for this prototype (10MB). \nThis limitation will be removed soon", 5000);
                return true;
            }
            Toast.loading('Uploading...');

            const { identityId } = await Auth.currentCredentials(); // TODO: remove after state issue solved (atm need this, no state otherwise) 
            const initialName = `${Date.now()}-${file.name}`;
            const fullsizeName = 'fullsize/' + initialName;  
            // Storage.put adds '/public' prefix
            const uploadedFile = await Storage.put(fullsizeName, file, {
                contentType: file.type
            });
            
            Toast.loading('File uploaded! Creating thumbnail preview...');
            const srcKey = 'public/' + uploadedFile.key;
            const resizeName = 'resized/' + appendToFilename(initialName, '_resized');
            const dstKey = 'public/' + resizeName;

            const bucket = aws_exports.aws_user_files_s3_bucket;
            const width = 120;
            const resizeSuccess = await this.resizeImage(bucket, srcKey, dstKey, width);
            // const generateThumbnail = await API.put("testlambda", "/test", {
            //     body: {
            //         bucket,
            //         srcKey,
            //         dstKey,
            //         width
            //     }
            // });
            let thumbnail = {
                key: resizeName
            }
            if (!resizeSuccess)
                thumbnail = null;
            const fullsize = {
                key: fullsizeName,
                size: fileSize,
                width: fileDimensions.width,
                height: fileDimensions.height,
                type: file.name // temporary! Will be replaced after adding "name" field in graphql
            };
            const input = {
                imageMatchId: this.props.matchId,
                fullsize: fullsize,
                thumbnail: thumbnail,
                bucket: aws_exports.aws_user_files_s3_bucket,
                region: aws_exports.aws_project_region,
                feedNumber: this.state.tab
            };
            const result = await API.graphql(
                graphqlOperation(createImage, { input })
            );
            // update the state
            const newImage = result.data.createImage; // new item added to db
            this.setState({ 
                images: [...this.state.images, newImage],
                feedImages: [...this.state.feedImages, newImage]
             });
            const match = this.props.match;
            match.images.items.push(newImage);
            console.log(this.props.match)

            // this.setState({ isUploading: false });
            
            Toast.success('Image added', 400, () => {
                console.log(result.data.createImage);
            });
        } catch (err) {
            Toast.fail('Error adding image', 1500, () => {
                console.log(err);
          });
        }
    };
    handleDownloadZip = async (feedOnly) => {
        let imagesToDownload = feedOnly 
            ? this.state.allGalleryImages.filter(image => image.feedNumber === this.state.tab) 
            : this.state.allGalleryImages;
        console.log(imagesToDownload);
        // TODO: just disable <download zip> button
        if (imagesToDownload.length === 0) {
            Toast.info(`No images yet`, 500, () => {});
            return true; // no images 
        }
        // Toast.loading('Zipping...');
        // fetch images' uint8Arrays from S3
        const getFilesDataAndFeeds = async (images) => {
            let filesData = [], key, feed;
            for(let i = 0; i < images.length; i++) {
                key = 'public/' + images[i].key; // S3 methods need public/ in path
                feed = this.props.FEEDS[images[i].feedNumber].replace(/[ ]|[/]/g, '_'); // feed name
                let options = {
                    Bucket: aws_exports.aws_user_files_s3_bucket,
                    Key: key,
                    ResponseContentType: 'application/json'
                };
                let fileData = await S3.getObject(options).promise()
                    .then(result => {
                        return { 
                            arr: result.Body, 
                            name: getFileNameFromUrl(key),
                            feed: feed
                        }; 
                    })
                    .catch(err => console.log(err));
                console.log(fileData);
                filesData.push(fileData);
            }
            return filesData;
        }
        // zip fetched images and save archive package to disk
        const generateZip = async (filesData, matchInfo) => {         
            let zip = new JSZip();         
            // let folder = zip.folder(matchInfo).folder("feed");
            filesData.map((file) => {
                zip.folder(matchInfo).folder(file.feed).file(file.name, file.arr); //requires filesaver.js
            });
            zip.generateAsync({ type: 'blob' })
                       .then(function(content) {
                        saveAs(content, (matchInfo+".zip"));
                });
            return true;
        }
        const filesData = await getFilesDataAndFeeds(imagesToDownload);
        generateZip(filesData, this.state.matchInfo);
        // Toast.hide();
    };

    confirmDelete = (isConfirmed, image) => {
        if(isConfirmed) {
            console.log(image);
            this.handleDeleteImage(image);
        }
        else
            console.log("cancel");
    };
    
    // Feed horizontal menu
    handleChoice = (tab) => {
        this.ensureUserSingleSession();
        const feedImages = this.getFeedImages(tab);
        const feedVideos = this.getFeedVideos(tab);
        
        // In case no images have been uploaded for the feed, re-set activeItem  
        // either to "Highlight Videos" (if any) or to "Match Records Full Length"
        let activeItem = 'Images';
        if (feedImages.length === 0) {
            const feedHighlights = feedVideos.filter(video => video.extra !== 'F');
            if (feedHighlights.length > 0)
                activeItem = 'Highlight Videos';
            else
                activeItem = 'Match Records Full Length';
        }
        this.setState({ feedImages, feedVideos, tab, activeItem, activeVideo: 1 });
    }

    // Images/Highlights/Full length vertical menu
    handleItemClick = (e, { name }) => this.setState({ activeItem: name, activeVideo: 1 })
    
    handleVideoChange = (e, { activePage }) => this.setState({ activeVideo: activePage });
     
    render () {
        const { feedImages, minuteAllocation, messageToClients, tab, activeItem, feedVideos, visibleFeeds, activeVideo } = this.state;
        //console.log(minuteAllocation);
        // console.log(this.props.user.userCompany)
        if (!feedImages || !feedVideos)
            return null;
        else {
            // const matchDate = moment(this.props.match.date).format('DD.MM.YYYY');
            // const feedName = FEEDS[tab];
            const nothingUploadedYet = visibleFeeds.length === 0;

            const feedHighlights = feedVideos.filter(video => video.extra !== 'F');
            const feedFullVideos = feedVideos.filter(video => video.extra === 'F');
            const feedHighlightNumber = feedHighlights.length;
            const feedFullVideoNumber = feedFullVideos.length;
            const numberOfFeedImages = feedImages.length;

            // MINUTE ALLOCATION
            let soldMinutes;
            let playedMinutes;
            const offset = (tab - 1) * 2;
            if (minuteAllocation && minuteAllocation.length > (offset + 2)) {
                soldMinutes = minuteAllocation[1 + offset];
                playedMinutes = minuteAllocation[2 + offset];
            }
            // console.log(soldMinutes, playedMinutes);
            //console.log(minuteAllocation);

            // MINUTE ALLOCATION ENDS

            //const maxLength = 300;
            //if (this.props.match.message) {
            //    if (this.props.match.message.length > maxLength)
            //        messageToClients = this.props.match.message.substring(0, maxLength) + "...";
            //}
                        
        return (
            <React.Fragment>
            {nothingUploadedYet ?
                <div>
                <br /><br /><br />
                <Message info className="panelNewMessage">
                    <Icon name='images' size='large' className='messageInfoIcon' />
                    <span className='messageInfoText'>No images or videos have been uploaded yet. The upload will take place a few days after the match day.</span>
                </Message>
                <br /><br /><br />
                </div>
                
                :

            <div className="tablePanel">
                    <Tabs
                        value={visibleFeeds.findIndex(feed => feed === tab)}
                        indicatorColor="secondary"
                        textColor="secondary"
                        className="panelNewFeedTabs"
                        centered
                    >
                        {visibleFeeds.map((i) => 
                            <Tab key={i} 
                                label={this.props.FEEDS[i]} 
                                onClick={(event) => this.handleChoice(i)} 
                            />
                        )}
                    </Tabs>

                    {/* <SemanticDivider horizontal>
                        <span className="dividerTextPanelNew">

                        <Breadcrumb>
                            <Breadcrumb.Section>{matchDate}</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section>{this.props.match.details}</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right arrow' />
                            <Breadcrumb.Section>{feedName}</Breadcrumb.Section>
                        </Breadcrumb>                    
                        </span>
                    </SemanticDivider> */}

                {/* {minuteAllocation && */}
                {soldMinutes && playedMinutes &&
                    <Message className="minuteAllocationMessage">
                        {/* <Icon name='images' size='large' className='messageInfoIcon' /> */}
                        {/* <span className='messageInfoText'>No images or videos have been uploaded by SPORTFI<u className="underlinedV">V</u>E yet. The upload will take place a few days after the match day.</span> */}
                        <span className='minuteAllocationMessageText'> Minutes sold: <b>{soldMinutes}</b> &nbsp;&nbsp;&nbsp;&nbsp; Minutes played: <b>{playedMinutes}</b></span>
                    </Message>
                }
                
                <Grid container spacing={2}>
                    
                    <Grid item xs={3} className='panelNewLeft'>
                        
                    {messageToClients &&
                    <Message warning className="messageToClientsPanelNew">
                        {/* <Icon name='info circle' size='small' className='' /> */}
                        {/* <span className='messageInfoText'>No images or videos have been uploaded by SPORTFI<u className="underlinedV">V</u>E yet. The upload will take place a few days after the match day.</span> */}
                        <span className=''> {messageToClients}</span>
                    </Message>
                    }
                        
                        <Menu pointing vertical attached>
                            {numberOfFeedImages > 0 &&
                                <Menu.Item
                                name='Images'
                                active={activeItem === 'Images'}
                                onClick={this.handleItemClick}
                                />
                            }
                            {feedHighlightNumber > 0 &&
                                <Menu.Item
                                name='Highlight Videos'
                                active={activeItem === 'Highlight Videos'}
                                onClick={this.handleItemClick}
                                />
                            }
                            {feedFullVideoNumber > 0 &&
                                // <HtmlTooltip
                                //     title={
                                //         <React.Fragment>
                                //                 Full-length match record has not been uploaded by SPORTFI<u>V</u>E yet. The upload will take place a few days after the match day.
                                //         </React.Fragment>
                                //     } arrow
                                // >
                                // <Menu.Item
                                // disabled
                                // name='Match Records Full Length'
                                // active={activeItem === 'Match Records Full Length'}
                                // // onClick={this.handleItemClick}
                                // />
                                // </HtmlTooltip>
                            
                                <Menu.Item
                                name='Match Records Full Length'
                                active={activeItem === 'Match Records Full Length'}
                                onClick={this.handleItemClick}
                                />
                            }
                        
                        </Menu>
                    
                    </Grid>
                    
                    {activeItem === "Images" ? 
                    <React.Fragment>
                    <Grid className="panelNewGallery" item xs={6}>

                    {feedImages.length > 0 ?
                        <ImageGallery 
                            // onScreenChange={this._onScreenChange} 
                            // onSlide={() => this.onSlide()} 
                            // ref={this.setImageRef}
                            items={feedImages}
                        />
                    :

                        <Message info className="panelNewMessage">
                            <Icon name='images' size='large' className='messageInfoIcon' />
                            <span className='messageInfoText'>No images have been uploaded yet. The upload will take place a few days after the match day.</span>
                        </Message>
                    }
                    </Grid>
                    
                    <Grid item xs className='panelNewRight'>
                    <Segment attached  placeholder className="panelNewButtonsSegment">
                        <HtmlTooltip
                            title="Download images either from all available match feeds or from the current feed only. Images will be saved to disk in a Zip file."
                            position="bottom"
                        >
                        <span className="panelNewLabel">Download images (zip)&nbsp;<Icon size="small" 
                        className='info circle'/></span> 
                        </HtmlTooltip>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disableElevation
                            onClick={() => this.handleDownloadZip(false)}
                            // color={"#55b6cc"}
                            className="panelButton clientDownloadButton"
                            startIcon={<SaveOutlinedIcon fontSize='default'/>}
                        >
                            All match
                        </Button>
                    
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            disableElevation
                            onClick={() => this.handleDownloadZip(true)}
                            // color={"#55b6cc"}
                            className="panelButton clientDownloadButton"
                            startIcon={<SaveOutlinedIcon fontSize='default'/>}
                        >
                            Feed only
                        </Button>
                        </Segment>
                </Grid>
                </React.Fragment> 
                : activeItem === "Highlight Videos" ?
                
                <React.Fragment>
                    <Grid item xs={7} className='panelNewVideoBlock'>
                        {feedHighlightNumber > 0 ?
                        <React.Fragment>
                        <div className="panelNewVideoPaginationBlock" onContextMenu={this.handleRightClick}>
                            <VideoPlayer key={feedHighlights[activeVideo-1].signedUrl + feedHighlights[activeVideo-1].feedNumber} watermark={false} 
                            videoSrc={feedHighlights[activeVideo-1].signedUrl} poster={'/infront/poster.png'} />
                        </div>
                        {/* {feedHighlightNumber > 1 &&
                                <div className="panelNewVideoPaginationBlock">
                                <br/>
                                <SemanticDivider horizontal>
                                    <Icon name="play circle outline" />
                                    
                                       &nbsp;&nbsp; Video {activeVideo} of {feedHighlightNumber}
                                    
                                </SemanticDivider> 
                                <Pagination
                                    boundaryRange={0}
                                    attached
                                    className="panelNewPagination"
                                    // defaultActivePage={1}
                                    activePage={activeVideo}
                                    onPageChange={this.handleVideoChange}
                                    ellipsisItem={null}
                                    firstItem={null}
                                    lastItem={null}
                                    siblingRange={2}
                                    totalPages={feedHighlightNumber}
                                />
                            </div>

                        } */}
                        </React.Fragment>
                        :
                        <Message info className="panelNewMessage">
                        <Icon name='play circle outline' size='large' className='messageInfoIcon' />
                        <span className='messageInfoText'>No highlight videos have been uploaded yet. The upload will take place a few days after the match day.</span>
                        </Message>
                        }
                    </Grid>
                    <Grid item xs className='panelNewRight'></Grid>
                </React.Fragment> :
                <React.Fragment>
                <Grid item xs={7} className='panelNewVideoBlock'>
                    {feedFullVideoNumber > 0 ?
                    <React.Fragment>
                    <div className="panelNewVideoPaginationBlock" onContextMenu={this.handleRightClick}>
                    {/* <span className="watermarkText">FOR INTERNAL USE ONLY</span> */}
                    <VideoPlayer key={feedFullVideos[activeVideo-1].signedUrl + feedFullVideos[activeVideo-1].feedNumber} watermark={true} 
                    videoSrc={feedFullVideos[activeVideo-1].signedUrl} poster={'/infront/poster.png'} />

                    {/* <video key={feedFullVideos[activeVideo-1].signedUrl} 
                        preload="none" 
                        // ref={this.videoRef} 
                        poster={process.env.PUBLIC_URL + '/sportfive_logo_transparent.png'} 
                        onContextMenu={this.handleRightClick} 
                        width="640" height="384" controls controlsList="nodownload"
                        muted
                    > 
                    <source 
                        src={feedFullVideos[activeVideo-1].signedUrl}
                        // src={CLOUDFRONT_DOMAIN_NAME + video.key} 
                        type="video/mp4" />
                    </video> */}
                    </div>
                    {/* {feedFullVideoNumber > 1 &&
                            <div className="panelNewVideoPaginationBlock">
                            <br/>
                            <SemanticDivider horizontal>
                                <Icon name="play circle outline" />
                    
                                   &nbsp;&nbsp; Video {activeVideo} of {feedFullVideoNumber}
                    
                            </SemanticDivider> 
                            <Pagination
                                boundaryRange={0}
                                attached
                                className="panelNewPagination"
                                // defaultActivePage={1}
                                activePage={activeVideo}
                                onPageChange={this.handleVideoChange}
                                ellipsisItem={null}
                                firstItem={null}
                                lastItem={null}
                                siblingRange={2}
                                totalPages={feedFullVideoNumber}
                            />
                        </div>

                    } */}
                    </React.Fragment>
                    :
                    <Message info className="panelNewMessage">
                    <Icon name='play circle outline' size='large' className='messageInfoIcon' />
                    <span className='messageInfoText'>Full length match record has not been uploaded yet. The upload will take place a few days after the match day.</span>
                    </Message>
                    }
                </Grid>
                <Grid item xs className='panelNewRight'></Grid>
            </React.Fragment>
            }
            </Grid>
        </div>}
        </React.Fragment>
        );
    }
    } // end return
}
export default Panel;