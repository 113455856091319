import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import IconBreadcrumbs from '../components/breadcrumbs'
import { getCompanyImages } from '../utils/customQueries';
import { listImages } from '../graphql/queries';
import Toast from 'light-toast';
import { API, graphqlOperation, Auth } from "aws-amplify";
import aws_exports from '../aws-exports';
import { formatBytes, getFileNameFromUrl } from '../utils/functions';
import moment from 'moment';
import Table from '../components/smallTable';
import { Divider as SemanticDivider, 
  Icon, 
  Dropdown, 
  Message } from 'semantic-ui-react'
import Button from '@material-ui/core/Button';
import SaveAltIcon from '@material-ui/icons/SaveAlt'; 
import "../App.css";
import { FEEDS } from '../utils/constants';

const AWS = require('aws-sdk');
AWS.config.update ({
    region: aws_exports.aws_project_region
});
let S3;

const BUCKET = aws_exports.aws_user_files_s3_bucket;
const REGION = aws_exports.aws_project_region;

class ImagesPage extends Component {
  constructor(props) {
    super(props);
    // let's use it as a class variable, as we need this calculation only once
    // concatinating "visible to all" item and the whole list of available companies
    this.dropdownCompanies = this.props.companies.map((company, index) => {
      return { key: company.id, value: company.id, text: company.name }
    });

    this._imageGallery = null;

    this.setImageRef = element => {
      this._imageGallery = element;
    };
  }
    state = {
      // matches: data.matches, // no need any more
      data: [],
      images: null,
      initialImages: [],
      date: "April 2020",
      match: "BVB v BMG KO 1830 CET",
      fileName: "test-02.jpg",
      index: 0,
      isLoading: false,
      imageRef: null
    };
    async componentDidMount() {
      const credentials = await Auth.currentCredentials();
      AWS.config.update ({
          credentials: Auth.essentialCredentials(credentials)
      });
      S3 = new AWS.S3({ signatureVersion: 'v4' });

      const imagesForALL = [];

      let images = [], value = null;
      if (this.props.user.isManager) {
        // if there are no companies in dropdown list (only ALL exists)
        if (this.dropdownCompanies.length === 0) {
          value = null; 
          images = imagesForALL;
        }
        // default (first) dropdown option is the first company in the dropdown list
        else {
          value = this.dropdownCompanies[0].value;
          images = await this.getClientImages(value);
        }
      }
      // client user
      else {
        // value will be null in case client user has no company
        if (this.props.user.userCompany) {
          value = this.props.user.userCompany.id;
          const clientImages = await this.getClientImages(value);
          images = [...imagesForALL, ...clientImages];
        }
      }
      console.log(images);
      
      this.setState({ imagesForALL, images: this.populateImages(images), value });
    }

    componentWillReceiveProps(nextProps) {
      if (this._imageGallery) {
        this._imageGallery.slideToIndex(0)
      }
    }

    getClientImages = async (value) => {
      // we don't handle ALL images in this function
      if (value === "ALL")
        return [];
      
      else {
        try {
          const input = { id : value };
          const result = await API.graphql(
              graphqlOperation(getCompanyImages, input)
          );
          console.log(result);
          return result.data.getCompany.images.items;
        } catch (err) { 
          Toast.fail('Error fetching images', 1500, () => {
              console.log(err);
              return [];
          });
        }
      }
    }
    
    getAllExistingImages = async(limit) => {
      try {
          const result = await API.graphql(graphqlOperation(listImages, { limit }));
          return result.data.listImages.items;
      } catch (err) { 
          Toast.fail('Error fetching images', 1200, () => {
              console.log(err);
              return [];
          });
      }
    }
    populateImages = (initialArray) => {
      console.log(initialArray);
      // sort images by match date
      initialArray.sort(function(a, b) {
        var keyA = new Date(a.match.date),
          keyB = new Date(b.match.date);
        // Compare the 2 dates
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
        return 0;
      });
      console.log(initialArray)
      let images = [];
      // now populate the final image-array
      initialArray.map((image) => {
        // we don't use buildS3Url function here as we don't get with customQuery region and bucket
        // for the same reason we have BUCKET and REGION global vars
        const origUrl = "https://" + BUCKET + ".s3." + REGION + ".amazonaws.com/public/" + image.fullsize.key;
        const thumbUrl = image.thumbnail ? 
            "https://" + BUCKET + ".s3." + REGION + ".amazonaws.com/public/" + image.thumbnail.key
            : origUrl;

        let size = "";
        let resolution = "";
        if (image.fullsize.size)
          size = formatBytes(image.fullsize.size);
        if (image.fullsize.width)
          resolution = image.fullsize.width + "x" + image.fullsize.height;
          const timeOffset = moment.duration('02:00:00');
        images.push({
          original: origUrl,
          thumbnail: thumbUrl,
          match: image.match,
          key: image.fullsize.key,
          date: moment(image.match.date).format('DD.MM.YYYY'),
          name: image.fullsize.type ? image.fullsize.type.substr(-20) : origUrl.split("/").pop().substr(-20),
          size: size,
          resolution: resolution,
          // month: moment(image.match.date).format('DD.MM.YYYY'),
          matchKickoff: moment(image.match.date).subtract(timeOffset).format('HH:mm'), 
          feed: FEEDS[image.feedNumber]
        })
      })
      if (images.length > 0)
        this.setState({
            index: 0,
            match: images[0].match.details, 
            date: images[0].date,
            fileName: images[0].name.split("/").pop() // remove path before file
        })
      return images;
    }
    onSlide(event) {
      const i = this._imageGallery.getCurrentIndex();
      this.setState({ 
          index: i, 
          match: this.state.images[i].match.details, 
          date: this.state.images[i].date,
          fileName: this.state.images[i].name.split("/").pop() // remove path before file
      });
    }
    // handleBreadcrumbClick(event, isMonthClicked) {
    //   event.preventDefault();
    //   let index = 0;
    //   if (isMonthClicked) {
    //     const {date} = this.state;
    //     index = this.state.images.find(image => image.date === date);
    //     console.log(date)
    //   }
    //   else {
    //     const {match} = this.state;
    //     index = this.state.images.find(image => image.match === match);
    //     console.log(match)
    //     console.log(this.state.images[index].match)
    //   }
    //   console.log(index)
    //   // if(this._imageGallery)
    //   //   this._imageGallery.slideToIndex(index);
    // }
    breadcrumbClick = (isMonthClicked) => {
      let index = 0;
      if (isMonthClicked) {
        const {date} = this.state;
        index = this.state.images.findIndex(image => image.date == date);
      }
      else {
        const {match} = this.state;
        index = this.state.images.findIndex(image => image.match.details == match);
      }
      console.log(index)
      if(this._imageGallery)
        this._imageGallery.slideToIndex(index);
    }

    // imageRef = null;
    // setRef = (ref) => {
    //     this.setState({ imageRef: ref});
    // };
    // test = () => { console.log(this._imageGallery)}
     
    handleMonthClick = (event) => {
      event.preventDefault();
      if(this._imageGallery)
        this._imageGallery.slideToIndex(1);      
    }
     handleMatchClick = (event) => {
      event.preventDefault();
    }
    handleDownloadImage = async(key) => {
      const downloadFile = (bytesArray, fileName) => {
          const blob = new Blob([bytesArray]);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
      }
      // Toast.loading('Saving to disk...');
      let fileName = getFileNameFromUrl(key);
      try {
          const publicKey = "public/" + key;
          console.log(publicKey) 
          const options = {
              Bucket: aws_exports.aws_user_files_s3_bucket,
              Key: publicKey,
              ResponseContentType: 'application/json'
          };      
          const data = await S3.getObject(options).promise();
          console.log(data.Body);
          downloadFile(data.Body, fileName);
      } catch (e) {
          throw new Error(`Could not retrieve file from S3: ${e.message}`)
      }
  }
  handleChangeDropdown = async (event, { value }) => {
    try {
        let unsortedImages;
        if (value === "ALL")
          unsortedImages = [...this.state.imagesForALL];
        else {
          unsortedImages = await this.getClientImages(value);  
        }

        const images = this.populateImages(unsortedImages);

        this.setState({ value, images, index: 0 });
    } catch (err) {
        Toast.fail('Error fetching images', 1500, () => {
            console.log(err);
        });
    }
};

    render () {
        const { images, index, match, date, value } = this.state;
        if (!images)
          return (<div className="imagesPage"><div className="lds-dual-ring"></div></div>);
        else {
          const image = images[index];
          const numOfImages = images.length;
          let companyName;
          if (value === "ALL" || !value)
            companyName = value;
          else 
            companyName = this.dropdownCompanies.find(company => company.value === value).text;
        return <div className="imagesPage">

          {numOfImages > 0 ?
            <span>
              <IconBreadcrumbs 
                breadcrumbClick={this.breadcrumbClick}
                g={this._imageGallery}
                date={date}
                match={match}
                image={image.feed}
              />

              <div className="galleryContainer MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded">
                <ImageGallery 
                  onScreenChange={this._onScreenChange} 
                  onSlide={() => this.onSlide()} 
                  ref={this.setImageRef}
                  items={images} 
                />
              </div>
            </span>
          :
            <div className="galleryContainer allignCenter MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded">
            <Message
              icon='images'
              size='large'
              className='noVideosImagesMessage'
              content={companyName ? 'No images for ' + companyName : 'No images'}
            />
            </div>
          }

            <div className="imagePageRightSide MuiPaper-root MuiPaper-elevation1 MuiPaper-rounded">
              {this.props.user.isManager &&
                <div className="galleryViewButtons">
                    <SemanticDivider horizontal>
                        <span className="dividerText">
                            <Icon name='user outline' className='lowOpacityIcon' />
                            Client
                        </span>
                    </SemanticDivider>
                
                    <Dropdown
                        onChange={this.handleChangeDropdown}
                        options={this.dropdownCompanies}
                        placeholder='Select client'
                        button
                        scrolling
                        value={value}
                    />
                </div>
              }


            {numOfImages > 0 &&
              <span>
                <SemanticDivider horizontal>
                  <span className="dividerText">
                      <Icon name='bar chart' />
                      Match
                  </span>
                </SemanticDivider>

                      <Table rows={[
                          [ "Name", image.match.details ],
                          [ "Date", image.date ],
                          [ "Kick-off", image.matchKickoff ],
                      ]}/>

                  <SemanticDivider horizontal>
                      <span className="dividerText">
                          <Icon name='map marker alternate' className='lowOpacityIcon' />
                          Feed
                      </span>
                  </SemanticDivider>
                  
                  <Table 
                          cellClass="panelDialogFeedTableCell"
                          rows={[
                              [ "Region", image.feed ],
                              ]}
                  />

                <SemanticDivider horizontal>
                  <span className="dividerText">
                      <Icon name='image' />
                      {"Image (" + (index + 1) + "/" + numOfImages + ")"}
                  </span>
                </SemanticDivider>
                <div className="galleryViewImageInfo">
                    <Table rows={[
                            [ "File", image.name ],
                            [ "Size", image.size ],
                            [ "Dimensions", image.resolution ],
                            ]}/>
                </div>
                <div className="galleryViewButtons">
                    <span 
                    onClick={() => this.handleDownloadImage(image.key)}
                    >
                        <Button
                            variant="contained"
                            size="small"
                            disableElevation
                            className="imagePageDownloadButton"
                            startIcon={<SaveAltIcon fontSize='default'/>}
                        >
                            <span className="panelDialogButtonText">Download image</span>
                        </Button>
                    </span>

                </div>
              </span>
            }
            </div>
        </div>;
      }
    }
}

export default ImagesPage;