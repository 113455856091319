import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Grid as SemanticGrid, Dropdown } from 'semantic-ui-react';
import List from '@material-ui/core/List';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { HtmlTooltip } from '../utils/customThemes';
// import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
// import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
// import ArchiveIcon from '@material-ui/icons/Archive';
// import UnarchiveIcon from '@material-ui/icons/Unarchive';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
// import SaveIcon from '@material-ui/icons/Save';
import Divider from '@material-ui/core/Divider';
import { Divider as SemanticDivider, Icon, TextArea } from 'semantic-ui-react'
import Table from '../components/smallTable';
import { DISPLAY_NEW_FEATURES_1_4_4 } from '../utils/constants';
// import Switch from '../components/switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import { getUsers, getCompanies } from '../utils/functions';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 'auto',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 200,
    height: 330,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}
function sortCompaniesByName(arr) {
  if (arr.length <= 1)
    return arr;
  return arr.sort((a, b) => a.localeCompare(b));
}
export default function TransferList(props) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  // companies that had access before
  const rightItems = sortCompaniesByName(props.match.clients.map(company => company.name));
  const allItems = props.companies.map(company => company.name);
  const leftItems = sortCompaniesByName(not(allItems, rightItems));
  const [left, setLeft] = React.useState(leftItems);
  const [right, setRight] = React.useState(rightItems);
  const [changesMade, setChangesMade] = React.useState(false);

  // messageToClients string includes the message itself
  // and the ids of the companies that have access to it.
  // There's a separator ('& & &') between message and the
  // ids in question. See example below:
  // 'message text& & &dc13a0f9-ff87-42f2-84bf-6da27593f000,ba081646-87cb-467e-8549-67969a8c9ffb'
  // Message 'message text' is visible in this case to 2 clients.
  // parseMessageToClients returns the message itself if part == 0
  // and it returns client ids in an array if part == 1
  const parseMessageToClients = (part) => {
    const separator = '& & &';
    const messageParts = props.match.message ? props.match.message.split(separator) : [];
    let result;
    
    if (messageParts.length > part) {
      
      result = props.match.message.split(separator)[part];
      
      if (part === 1) {
        result = result.split(','); // convert string to array
      }

    }
    return result;
  }


  // const cleanMessage = props.match.message ? props.match.message.split('sa') : null;
  // const cleanClients = 
  const [messageToClients, setMessageToClients] = React.useState(parseMessageToClients(0));
  const [visibleToClients, setVisibleToClients] = React.useState(parseMessageToClients(1));
  //let visibleToClients = [];
  // hide/show match
  const isHidden = props.match.hidden ? true : false; // might be undefined, then false
  const [hidden, setHidden] = React.useState(isHidden);
  // match name
  let matchNameInputRef = useRef();
  let messageToClientsInputRef = useRef();

  const [matchName, setMatchName] = React.useState(props.match.details);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const dropdownCompanies = props.companies.map((company, index) => {
    return { key: company.id, value: company.id, text: company.name }
});

  // let changesMade = false;

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    setChangesMade(true);
    setRight(sortCompaniesByName(right.concat(leftChecked)));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setChangesMade(true);
    setLeft(sortCompaniesByName(left.concat(rightChecked)));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleSetHidden = () => {
    setChangesMade(true);
    setHidden(!hidden);
  }
  const handleSetMatchName = (newName) => {
    if (newName) {
      setChangesMade(true);
      setMatchName(newName.trim());
      matchNameInputRef.placeholder = newName.trim();
      matchNameInputRef.value = "";
      console.log(newName.trim())
      // console.log(matchName)
    }
    else
      console.log("No name provided");

  }
  // const toggleHideShow = (x) => {
  //   console.log(x);
    
  // }

  const handleAddMessageToClients = (message) => {    
    console.log(message);
    console.log(props.match);

    setChangesMade(true);
    setMessageToClients(message.trim());
    messageToClientsInputRef.placeholder = message.trim();
    messageToClientsInputRef.value = "";
  }

  const handleRemoveMessageToClients = () => {
    console.log('remove message');

    setChangesMade(true);
    setMessageToClients('');
    // setVisibleToClients('');

    messageToClientsInputRef.placeholder = '';
    messageToClientsInputRef.value = '';
  }

  const handleSave = () => {
    let companyIds;
  
    if (right) {
      companyIds = right.map(item => props.companies.find(company => company.name === item));
    }

    let messageToClientsWithVisibilityInfo = '';
    if (messageToClients) {
      messageToClientsWithVisibilityInfo = messageToClients + '& & &' + visibleToClients;
    }

    props.handleAccess(props.match.id, companyIds, hidden, messageToClientsWithVisibilityInfo , matchName);
    console.log(props.match.details, right, messageToClients, matchName);
  }
  const handleDropdownChange = (e, {value}) => {
    // console.log(value[0]);
    setChangesMade(true);
    // console.log(messageToClients + '& & &' + value)
    // console.log(messageToClients)
    setVisibleToClients(value);
    // console.log(visibleToClients)
    // console.log(dropdownCompanies)
  }
  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            className='transferListCheckBoxIcon'
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  className='transferListCheckBoxIcon'
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
  <div className="panelDialogMain">


  <div className="galleryViewPanelDialogMain">
  
    <div className="galleryViewLeftSideTransferList">
    <SemanticDivider horizontal>
        <span className="dividerText">
            <Icon name='eye' className='lowOpacityIcon' />
            Control match visibility 
        </span>
    </SemanticDivider>
    <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
    
      <Grid item>{customList('Don\'t have access', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Have access', right)}</Grid>
    </Grid>
    

    {messageToClients && DISPLAY_NEW_FEATURES_1_4_4 &&
    <div className="leftMessageVisibility">
    <SemanticDivider horizontal>
        <span className="dividerText">
            <Icon name='eye' className='lowOpacityIcon' />
            Control message visibility 
        </span>
    </SemanticDivider>
        <HtmlTooltip
                        title={
                            <React.Fragment>
                                    Click on the dropdown list and add (or remove) clients to whom you want match message to be visible. Click [Save and close] button to save your changes.
                            </React.Fragment>
                        }
                        placement="top"
                    >
                        <span className="panelNewLabel">Message visible to clients &nbsp; <Icon size="small" 
                        name='info circle'/>
                        </span>
                    </HtmlTooltip>
                    <Dropdown key='Dropdown-component' 
                        placeholder='Assign to clients'
                        className="assignToClientsDropdownMessage" 
                        
                        onChange={handleDropdownChange}
                        
                        fluid multiple selection options={dropdownCompanies} 
                        defaultValue={visibleToClients}
                    />

    </div>
    }
    </div>
    <div className="galleryViewRightSideTransferList">
    
        <SemanticDivider horizontal>
        <span className="dividerText">
            <Icon name='bar chart' />
            Match
        </span>
        </SemanticDivider>
        <div className="galleryViewMatchInfo">
            <Table rows={[
                [ "Name", matchName ],
                // [ "Place", props.match.place ],
                [ "Date", moment(props.match.date).format('DD.MM.YYYY') ],
                [ "Kick-off", props.match.kickoff ],
                [ "Status", hidden ? "Archived" : "Visible" ],
                [ "Message to clients", messageToClients ? "Added" : "No"]
                ]}/>
        </div>
        
        <SemanticDivider horizontal>
        <span className="dividerText">
            <Icon name='edit outline' />
            Edit
        </span>
        </SemanticDivider>
        

        {/* <Tooltip title="Archive match" placement="bottom" arrow>
                
                <Switch label="Hide match" className="panelDialogSwitch" change={toggleHideShow} />
                
            </Tooltip> */}

        <div className="galleryViewButtonsTransferListWindow">

          <HtmlTooltip
            title="You can archive this match, thus hiding it from the matchlist. All the match-client bookings will be removed, but images and videos will be left untouched. You can restore this match later from the archive on this Settings page. Also, to apply any changes made on this page, remember to click <Save and close> button before exit"
            position="top"
          >
            <span className="panelNewLabel">Hide/show this match&nbsp;<Icon size="small" 
            className='info circle'/></span> 
          </HtmlTooltip>

          <div className="transferListButton" onClick={() => handleSetHidden()}>
          {hidden ? 
            <Button
                variant="contained"
                size="small"
                disableElevation
                // color={"#55b6cc"}
                className="panelDialogButtonAddImage widerButton"
                startIcon={<VisibilityOutlinedIcon fontSize='medium'/>}
            >
                <span className="panelDialogButtonText">Restore from archive</span>
            </Button>
            :
            <Button
                variant="contained"
                size="small"
                disableElevation
                // color={"#55b6cc"}
                className="panelDialogButton widerButton redBackgroundButton"
                startIcon={<VisibilityOffOutlinedIcon fontSize='medium'/>}
            >
                <span className="panelDialogButtonText">Archive this match</span>
            </Button>
          }
          </div>
        
        <br />
        {!DISPLAY_NEW_FEATURES_1_4_4 ?

        <div>
         <HtmlTooltip
            title="To rename this match, enter the new name in the textbox below, then click a circle checkbox button to the right. Please note, to apply any changes made on this page, remember to click <Save and close> button before exit"
            position="top"
          >
            <span className="panelNewLabel">Change match name&nbsp;<Icon size="small" 
            className='info circle'/></span> 
        </HtmlTooltip> 
        <div className="ui small action input ">
          <input type="text" 
          ref={ref => matchNameInputRef = ref}
          // inputRef={c => matchNameInputRef = c} 
          placeholder={props.match.details} />
          <button onClick={() => handleSetMatchName(matchNameInputRef.value)} className="ui icon button">
            <i className="check circle outline icon"></i>
          </button>
        </div>
        </div>

        :
        <div>
        <HtmlTooltip
            title="Enter a message below. All clients that have access to this match will see this message in the corresponding match panel"
            position="top"
          >
            <span className="panelNewLabel">A message to clients&nbsp;<Icon size="small" 
            className='info circle'/></span> 
        </HtmlTooltip>

        <div className="ui form settingsPageTextArea">
          <div className="field">
            <textarea rows="4" placeholder={messageToClients} ref={ref => messageToClientsInputRef = ref}></textarea>
          </div>

          <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    // disabled={!changesMade}
                    // color={"#55b6cc"}
                    onClick={() => handleAddMessageToClients(messageToClientsInputRef.value)}
                    className={changesMade ? "panelDialogButton messageToClientsButton" : "panelDialogButton messageToClientsButton"}
                    startIcon={<CheckCircleOutlineIcon fontSize='medium'/>}
                >
                    <span className="panelDialogButtonText">Apply</span>
          </Button>
          <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    // disabled={!changesMade}
                    // color={"#55b6cc"}
                    onClick={() => handleRemoveMessageToClients()}
                    className={messageToClients ? "messageToClientsButton redBackgroundButton panelDialogButton" : "messageToClientsButton panelDialogButton redBackgroundButton panelButtonDisabled"}
                    startIcon={<RemoveCircleOutlineIcon fontSize='medium'/>}
                >
                    <span className="panelDialogButtonText">Remove</span>
            </Button>
        </div>
        




        



        </div>
        }
        {/* <div className="ui small action input ">
          <input type="text" 
          ref={ref => matchNameInputRef = ref}
          // inputRef={c => matchNameInputRef = c} 
          placeholder={props.match.details} />
          <button onClick={() => handleSetMatchName(matchNameInputRef.value)} className="ui icon button">
            <i className="check circle outline icon"></i>
          </button>
        </div>
         */}
            {/* <span onClick={() => console.log(true)}>
                <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    // color={"#55b6cc"}
                    className="panelDialogButtonAddImage"
                    startIcon={<EditOutlinedIcon fontSize='medium'/>}
                >
                    <span className="panelDialogButtonText">Edit match</span>
                </Button>
              </span> */}
        <br/>
        <SemanticDivider horizontal>
        <span className="dividerText">
            <Icon name='check circle outline' />
            Apply changes
        </span>
        </SemanticDivider>
            {/* <div className="labelInfo">Сlick to apply any changes made on this page</div> */}
            <span onClick={handleSave}>
                <Button
                    variant="contained"
                    size="small"
                    disableElevation
                    disabled={!changesMade}
                    // color={"#55b6cc"}
                    className={changesMade ? "panelDialogButton widerButton" : "panelDialogButton widerButton panelButtonDisabled"}
                    startIcon={<SaveOutlinedIcon fontSize='medium'/>}
                >
                    <span className="panelDialogButtonText">Save and close</span>
                </Button>
            </span>
        </div>
        {/* <div className="ui info message pointing label"> */}
        {/* <div className="ui info message">
          <span>Сlick to apply any changes</span>
        </div> */}

    </div></div></div>
  );
}
