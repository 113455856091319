import React, { Component } from 'react';
import MaterialTable from '@material-table/core';
import { createFeed, updateFeed, deleteFeed } from '../graphql/mutations';
import { listFeeds } from '../graphql/queries';
import { TextField } from "@material-ui/core";
import Toast from 'light-toast';
// import AddBoxIcon from '@material-ui/icons/AddBox';
import moment from 'moment';
import aws_exports from '../aws-exports';
import { MEDIA_BUCKET } from '../utils/constants';
import { API, graphqlOperation } from "aws-amplify";


const AWS = require('aws-sdk');
AWS.config.update ({
    region: aws_exports.aws_project_region
});

// const cognito = new AWS.CognitoIdentityServiceProvider();
// const user_pool_id = aws_exports.aws_user_pools_id;
// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />;
//   });

class FaqPage extends Component {
    state = { 
        columns: [
            { title: 'Question', field: 'question' },
            { title: 'Answer', field: 'answer',
            editComponent: ({ value, onChange }) => (   
                <TextField
                  onChange={e => onChange(e.target.value)}
                  value={value}
                  multiline
                />
              ) },
            { title: 'Last changed', field: 'lastChanged',
                cellStyle: {
                    width: 160,
                    maxWidth: 160
                },
                headerStyle: {
                    width: 160,
                    maxWidth: 160
                },
                type: 'date', dateSetting: { locale: "en-GB" }, editable: 'never' 
            },
        ],  
        tableData: [
            // { question: 'What is the name of this application? Can you provide some more information', answer: 'I don"t know the name of this application! Can"t you provide some more information', lastChanged: '13.10.2020'  },
            // { question: 'Zerya Betül', answer: 'Baran', lastChanged: '30/10/2017' },    
        ]
    };
    async componentDidMount() {
        try {
            const questions = await API.graphql(graphqlOperation(listFeeds));
            // console.log(questions.data.listFeeds.items)
            const tableData = questions.data.listFeeds.items.map(question =>
                this.parseQuestionDbRecord(question.name, question.id)
            );
            this.setState({ tableData });
        }
        catch (err) {
            console.log(err)
        }
    }
    async handleAddQuestion (newData) {
        console.log('add', newData.question)
        console.log(newData)
        if (newData.question || newData.answer) {
            const sameQuestion = this.state.tableData.some(el => el.question === newData.question);
            if (sameQuestion) {
                Toast.info(`Such question exists already`, 2000);
                return;
            }

            
            console.log(this.createQuestionDbRecord(newData));
            const input = { name: this.createQuestionDbRecord(newData) }
            let result = await API.graphql(
                graphqlOperation(createFeed, { input })
            );
            
            console.log(result.data.createFeed.id);
            const newRow = {
                question: newData.question, 
                answer: newData.answer, 
                lastChanged: moment().format('DD.MM.YYYY'), 
                id: result.data.createFeed.id 
            }
            this.setState({ tableData: [...this.state.tableData, newRow] });
        }
        else
            console.log("NO data");
    }

    async handleUpdateQuestion (newData, oldData) {
        // console.log(newData, oldData);
        // console.log(newData.id);
        const tableData = [...this.state.tableData];
        const index = tableData.findIndex(item => item.id === oldData.id);
        const updatedQuestion = { ...tableData[index], 
            question: newData.question, 
            answer: newData.answer, 
            lastChanged: moment().format('DD.MM.YYYY') 
        };
        // console.log(updatedQuestion)
        // console.log(index)
        // tableData[index] = updatedUser;
        const updatedTableData = [
            ...tableData.slice(0, index),
            updatedQuestion,
            ...tableData.slice(index + 1)
        ];

        const input = { 
            id: oldData.id,
            name: this.createQuestionDbRecord(newData) 
        }
        const result = await API.graphql(
            graphqlOperation(updateFeed, { input })
        );
        // console.log(input)

        // console.log(result)
        
        this.setState({ tableData: updatedTableData });

    }
    async handleDeleteQuestion (question) {
        // console.log(newData, oldData);
        // console.log(newData.id);
        // const tableData = [...this.state.tableData];

        let input = {
            id: question.id
        }
        console.log(input)
        const updatedTableData = this.state.tableData.filter(
            item => item.id !== question.id
        );
        
        const result = await API.graphql(
                    graphqlOperation(deleteFeed, { input })
        );
        console.log(result);

        this.setState({ tableData: updatedTableData });

    }

    async test (newValue, rowData) {
        
            return new Promise((resolve, reject) => {
              console.log('newValue: ' + newValue + rowData);
              setTimeout(resolve, 1000);
            });
          
    }
    createQuestionDbRecord (record) {
        const question = record.question ? record.question : '';
        const answer = record.answer ? record.answer : '';
        const lastChanged = moment().format('DD.MM.YYYY');
        const separator = '& & &';
        return question + separator + answer + separator + lastChanged;
    }
    parseQuestionDbRecord (dbString, id) {
        const separator = '& & &';
        const stringParts = dbString ? dbString.split(separator) : [];
        // console.log(stringParts)
        let result = {question: '', answer: '', lastChanged: ''};
        
        if (stringParts.length == 3) {          
          result = {question: stringParts[0], answer: stringParts[1], lastChanged: stringParts[2], id};
        }
        // console.log(result)
        return result;
    }
    render () {
        // console.log(this.state.tableData)
        return (
            <MaterialTable
                title="Frequently asked questions"
                columns={this.state.columns}
                data={this.state.tableData}
                options={{ pageSize: 5 }}  
                editable={{
                    onRowAdd: newValue => this.handleAddQuestion(newValue), 
                    onRowUpdate: (newData, oldData) => this.handleUpdateQuestion(newData, oldData),
                    onRowDelete: newData => this.handleDeleteQuestion(newData),
                
                }}
              
            />
          )
    }
}

  export default FaqPage;