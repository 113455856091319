import React from 'react';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';

import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const visibleToListItemStyle = {
  
    padding: '0 !important',
}
const myCSS = {
  visibleToListItem: {
    padding: '0 !important',
    backgroundColor: 'green'
  }
}
const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1),
    overflowY: 'hidden !important' // hide vertical scroll for 'longer' lists
  }
}));

export default function MouseOverPopover( props ) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const className = props.rowData.clients.length > 24 ? 
  //   props.rowData.clients.length > 26 ?

    //   :
    // :
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        component='div'
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
      {props.rowData.hidden ?
        <UnarchiveOutlinedIcon />
        :
        <GroupOutlinedIcon className="iconGreen" />
      }
      <span className="numberOfCompanies">
          {props.rowData.clients.length}
      </span>
      </Typography>

      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 140,
          }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography component='div'>
          <List className={
              props.rowData.clients.length > 26 ? 
                props.rowData.clients.length > 47 ? 
                  "visibleToList longList" : 
                  "visibleToList middleSizeList" 
                : 
                "visibleToList"
              } 
              aria-labelledby="list-subheader"
              subheader={
                  <ListSubheader component="div" id="list-subheader" className="visibleToListSubheader">
                  Visible to
                  </ListSubheader>
              }
          >
          <Divider />
          {props.rowData.clients.sort((a, b) => a.name.localeCompare(b.name)).map((item) => 
                      <ListItem className="visibleToListItem" key={item.name}>
                      <ListItemText primary={item.name} />
                      </ListItem>
          )}
          </List>
        </Typography>
      </Popover>
    </div>
  );
}
