export const getMatchImages = `query GetMatch($id: ID!) {
    getMatch(id: $id) {
      id
      date
      details
      minutes
      regions
      place
      images(sortDirection: DESC, limit: 1000) {
            items {
                id
                region
                bucket
                feedNumber
                company {
                  id
                }
                fullsize {
                    key
                    width
                    height
                    size
                    type
                }
                thumbnail {
                    key
                }
            }
            nextToken
      }
      videos(sortDirection: DESC, limit: 1000) {
        items {
            id
            key
            bucket
            feedNumber
            extra
            company {
              id
            }
        }
        nextToken
      }
    }
}`;

export const listMatchesWithImages = /* GraphQL */ `
  query ListMatchs(
    $filter: ModelMatchFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMatchs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        details
        date
        place
        minutes
        message
        logo
        competition
        hidden
        feeds
        images(sortDirection: DESC, limit: 1000) {
            items {
                id
                region
                bucket
                feedNumber
                company {
                  id
                }
                fullsize {
                    key
                }
                thumbnail {
                    key
                }
            }
        }
        videos(sortDirection: DESC, limit: 200) {
          items {
              id
              key
              bucket
              feedNumber
              extra
              company {
                id
              }
          }
          nextToken
        }
        companies(sortDirection: DESC, limit: 100) {
          items {
              company {
                id
                name
                description
              }
          }
        }
        createdAt
      }
      nextToken
    }
  }
`;


export const getCompanyVideos = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      videos(limit: 1000) {
        items {
          id
          feedNumber
          key
          extra
          link
          match {
            details
            date
            hidden
          }
        }
        nextToken
      }
    }
  }
`;

export const getCompanyImages = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      images(limit: 1000) {
        items {
            id
            feedNumber
            fullsize {
                key
                width
                height
                size
                type
            }
            thumbnail {
                key
            }
            match {
              details
              date
              hidden
            }
        }
        nextToken
      }
    }
  }
`;

// for company delete
export const getCompanyToDelete = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      users {
        items {
          id
        }
        nextToken
      }
      images(limit: 1000) {
        items {
          id
          fullsize {
            key
          }
          thumbnail {
              key
          }
        }
        nextToken
      }
      videos(limit: 1000) {
        items {
          id
          key
        }
        nextToken
      }
    }
  }
`;

