import React, { Component } from 'react';
import { Icon, Menu } from 'semantic-ui-react'
import { Link } from 'react-router-dom';
import { getUrl } from '../utils/functions';
import { THEME } from '../utils/constants';

export default class MenuExampleLabeledIconsVertical extends Component {
    // a bit ugly way to find the initial selected menu item
    state = { activeItem: getUrl() }

    handleItemClick = (e, { name }) => {this.setState({ activeItem: name })}

    render() {
        const { activeItem } = this.state;
    return (
        <Menu icon='labeled' vertical>
            <Menu.Item
                name='/'
                as={ Link } 
                to='/'
                active={activeItem === '/'}
                onClick={this.handleItemClick}
            >
                <Icon className='menuIcon' name='chart bar outline' />
                Matches
            </Menu.Item>

            {/* <Menu.Item
                name='/calendar'
                as={ Link } 
                to='/calendar'
                active={activeItem === '/calendar'}
                onClick={this.handleItemClick}
            >
                <Icon className='menuIcon' name='calendar alternate outline' />
                Calendar
            </Menu.Item> */}

            <Menu.Item
                name='/images'
                as={ Link } 
                to='/images'
                active={activeItem === '/images'}
                onClick={this.handleItemClick}
            >
                <Icon className='menuIcon' name='images outline' />
                Images
            </Menu.Item>

            <Menu.Item
                name='/videos'
                as={ Link } 
                to='/videos'
                active={activeItem === '/videos'}
                onClick={this.handleItemClick}
            >
                <Icon className='menuIcon' name='file video outline' />
                Videos
            </Menu.Item>
            {this.props.isManager ?
                <Menu.Item
                    name='/admin'
                    as={ Link } 
                    to='/admin'
                    active={activeItem === '/admin'}
                    onClick={this.handleItemClick}
                >
                    <Icon className='menuIcon' name='users' />
                    Users
                </Menu.Item>
            : ""}
            {/* !!! faq begins */}
            {this.props.isManager ?
                <Menu.Item
                    name='/faq'
                    as={ Link } 
                    to='/faq'
                    active={activeItem === '/faq'}
                    onClick={this.handleItemClick}
                >
                    <Icon className='menuIcon' name='question circle outline' />
                    FAQ
                </Menu.Item>
            : ""}
            {/* !!! faq ends */}
      </Menu>
    )

  }
}