import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EmojiEventsOutlinedIcon from '@material-ui/icons/EmojiEventsOutlined';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';

const useStyles = makeStyles((theme) => ({
  link: {
    display: 'flex',
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
    color: '#444'
  },
  // link: {
  //   color: '#444'
  // }
}));


export default function IconBreadcrumbs(props) {
  const classes = useStyles();
  function handleMonthClick(event) {
    event.preventDefault();
    props.breadcrumbClick(true)
    
  }
  function handleMatchClick(event) {
    event.preventDefault();
    // console.log(props.g)
    props.breadcrumbClick(false)
    
  }
  return (
    <Breadcrumbs aria-label="breadcrumb">
      <Link color="inherit" href="/" onClick={handleMonthClick} className={classes.link}>
        <EventAvailableIcon className={classes.icon} />
        {props.date}
      </Link>
      <Link
        color="inherit"
        href=""
        onClick={handleMatchClick}
        className={classes.link}
      >
        <EmojiEventsOutlinedIcon className={classes.icon} />
        {props.match}
      </Link>
      <Typography color="textPrimary" className={classes.link}>
        <PlaceOutlinedIcon className={classes.icon} />
        {props.image}
      </Typography>
    </Breadcrumbs>
  );
}
