import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogContentText from '@material-ui/core/DialogContentText';
import Divider from '@material-ui/core/Divider';
// import Slide from '@material-ui/core/Slide';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));
  
  export default function ModalCompanyChangeComponent( props ) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [company, setCompany] = React.useState('');
  
    const handleChange = (event) => {
      setCompany(event.target.value || '');
      
    };
  
    // const handleClickOpen = () => {
    //   setOpen(true);
    // };
  
    const handleClose = () => {
        setOpen(false);
        props.closeModalChangeCompany();
    };
    const handleConfirm = () => {
        setOpen(false);
        props.handleCompanyChange(company);
    };
    return (
      <div>
            <Dialog disableBackdropClick disableEscapeKeyDown open={open} 
            TransitionComponent={props.Transition} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Set company for <span className="red">' {props.user}'</span>
                </DialogTitle>
                <Divider variant="middle" />
                <br/>
                <DialogContent>
                <DialogContentText>
                    Please, choose the company user <span className="italic"> {props.user}</span> will be part of
                </DialogContentText>
                <form className={classes.container}>
                <FormControl className="companySelect">
                    <InputLabel id="demo-dialog-select-label">Company</InputLabel>
                    <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    value={company  || "None"}
                    //   inputRef={c => this.companyRef = c}
                    onChange={handleChange}
                    input={<Input />}
                    >
                    <MenuItem value="None">
                        <em>None</em>
                    </MenuItem>
                    {props.companies.map((item) =>
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    )}
                    </Select>
                </FormControl>
                </form>
                </DialogContent>
                <DialogActions>
                <Button onClick={props.closeModalChangeCompany} color="primary">
                    Cancel
                </Button>
                <Button className="red" onClick={(event) => handleConfirm()} color="secondary">
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
      </div>
    );
  }