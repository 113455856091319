import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import DialogContentText from '@material-ui/core/DialogContentText';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Toast from 'light-toast';
// import Slide from '@material-ui/core/Slide';

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const useStyles = makeStyles((theme) => ({
    container: {
      display: 'inline',
      marginRight: 50,
      // flexWrap: 'wrap',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  }));
  
  export default function ModalCompanyDeleteComponent( props ) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const [company, setCompany] = React.useState('');
  
    const handleChange = (event) => {
      setCompany(event.target.value || '');
      
    };
  
    // const handleClickOpen = () => {
    //   setOpen(true);
    // };
  
    const handleClose = () => {
        setOpen(false);
        props.closeModalDeleteCompany();
    };
    const handleConfirm = () => {
        
        const companyName = props.companies.find(c => c.id === company).name;


        if (companyName !== companyRef.value)
          Toast.info('Incorrect name', 700);
        else {
          props.handleDeleteCompany(company);
          setOpen(false);
        }
    };
    let companyRef = useRef();
    return (
      <div>
            <Dialog disableBackdropClick disableEscapeKeyDown open={open} 
            TransitionComponent={props.Transition} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Select company to delete
                </DialogTitle>
                <Divider variant="middle" />
                <DialogContent>
                <DialogContentText>
                    <span className="red">ATTENTION!</span> Any <span className="red">users, images</span> and <span className="red">videos</span> related to this company will be also <span className="red">deleted</span>! Are you absolutely sure? Please, select the company from the dropdown list, then enter company name manually to confirm your choice.
                </DialogContentText>

                <form className={classes.container}>
                <FormControl className="companySelect">
                    <InputLabel id="demo-dialog-select-label">Company</InputLabel>
                    <Select
                    labelId="demo-dialog-select-label"
                    id="demo-dialog-select"
                    // value={company  || "None"}
                    value={company}
                    placeholder="Select company"
                    //   inputRef={c => this.companyRef = c}
                    onChange={handleChange}
                    input={<Input />}
                    >
                    {/* <MenuItem value="None">
                        <em>None</em>
                    </MenuItem> */}
                    {props.companies.map((item) =>
                    <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
                    )}
                    </Select>
                </FormControl>
                </form>
                <TextField
                    inputRef={c => companyRef = c}
                    required
                    className="enterCompanyNameToDelete"
                    margin="dense"
                    id="user"
                    label="Enter company name to confirm"
                    type="text"
                    
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={props.closeModalDeleteCompany} color="primary">
                    Cancel
                </Button>
                <Button className="red" onClick={(event) => handleConfirm(companyRef)} color="secondary">
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
      </div>
    );
  }