import React, { Component } from 'react';
import MaterialTable from '@material-table/core';
import aws_exports from '../aws-exports';
import { getUsers, getCompanies, getAllCompanyMatchBookings } from '../utils/functions';
import { registerUser, updateUser, deleteUser, createCompany, 
    deleteCompany, deleteImage, deleteVideo, deleteCompanyMatchBooking } from '../graphql/mutations';
import { getUser } from '../graphql/queries';
import { getCompanyToDelete } from '../utils/customQueries';
import { MEDIA_BUCKET } from '../utils/constants';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import ClearIcon from '@material-ui/icons/Clear';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Toast from 'light-toast';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import ModalCompanyChangeComponent from '../components/modalCompanyChange';
import ModalCompanyDeleteComponent from '../components/modalCompanyDelete';
import ModalAddUserComponent from '../components/modalAddUser';
import moment from 'moment';
import Slide from '@material-ui/core/Slide';
import Tooltip from '@material-ui/core/Tooltip';
import { Segment } from 'semantic-ui-react';
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";

const AWS = require('aws-sdk');
AWS.config.update ({
    region: aws_exports.aws_project_region
});

let S3, cognito;

const user_pool_id = aws_exports.aws_user_pools_id;
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

class AdminPage extends Component {
    state = {
        users: null,
        companies: null,
        tableData: null,
        visible: false,
        openAddUser: false,
        openAddCompany: false,
        openDeleteUser: false,
        openResetPwd: false,
        openChangeCompany: false,
        openDeleteCompany: false,
        userToWorkWith: null,
        companyToWorkWith: null,
        showManagers: false
    };
    openModalAddUser = () => { this.setState({ openAddUser : true }); }
    closeModalAddUser = () => { this.setState({ openAddUser : false }); }
    openModalAddCompany = () => { this.setState({ openAddCompany : true }); }
    closeModalAddCompany = () => { this.setState({ openAddCompany : false }); }
    openModalDeleteCompany = () => { this.setState({ openDeleteCompany : true }); }
    closeModalDeleteCompany = () => { this.setState({ openDeleteCompany : false }); }
    openModalDeleteUser = (user) => { this.setState({ openDeleteUser : true, userToWorkWith : user }); }
    closeModalDeleteUser = () => { this.setState({ openDeleteUser : false, userToWorkWith : null }); }
    openModalMakeUserAManager = (user) => { this.setState({ openMakeUserAManager : true, userToWorkWith : user }); }
    closeModalMakeUserAManager = () => { this.setState({ openMakeUserAManager : false, userToWorkWith : null }); }
    openModalResetPwd = (email) => { this.setState({ openResetPwd : true, userToWorkWith : email }); }
    closeModalResetPwd = () => { this.setState({ openResetPwd : false, userToWorkWith : null }); }
    // TODO: either change userToWorkWith var name or make this var usage uniform
    // note: in other modal windows, email is passed only via this var, not the whole user object
    openModalChangeCompany = (user) => { this.setState({ openChangeCompany : true, userToWorkWith : user }); }
    closeModalChangeCompany = () => { this.setState({ openChangeCompany : false, userToWorkWith : null, companyToWorkWith: null }); }
    
    // needUpdate = false;
    // shouldComponentUpdate(prevState) {
    //     console.log(prevState)
    //     return true;
    //     // return this.state.openChangeCompany = nextState.openChangeCompany;
    // }
    async componentDidMount() {
        
        const credentials = await Auth.currentCredentials();
        AWS.config.update ({
            credentials: Auth.essentialCredentials(credentials)
        });
        S3 = new AWS.S3({ signatureVersion: 'v4' });
        cognito = new AWS.CognitoIdentityServiceProvider();

        try {           
            let users = [];
            let companies = [];
            [users, companies] = await Promise.all([
                getUsers().then(console.log('getUsers: done')),
                getCompanies().then(console.log('getCompanies: done'))
            ]);
        
            const tableData = users.map((user, i) =>
            {
                return { 
                    email: user.email,
                    role: user.role,
                    status: user.status,
                    active: user.enabled,
                    dateCreated: user.dateCreated,
                    username: user.username,
                    company: user.company ? user.company.name : "",
                    lastActivityDate: user.lastActivityDate
                }
            })
            if (!this.state.showManagers)
                this.setState({ tableData: tableData.filter(user =>
                    user.role !== 'manager')});
            else
                this.setState ({ tableData });

            this.setState({ users, companies });
        } catch (err) {
            Toast.fail('Error fetching user data', 1500, () => {console.log(err);});
        }
    };
    handleDisableEnableUser = async (email) => {
        // await cognito.adminUserGlobalSignOut({
        //     UserPoolId: user_pool_id,
        //     Username: email,
        // }).promise();
        
        // TODO: try this option
//         var params = {
//             UserPoolId: user_pool_id,
//             Username: email,
//           };
//           cognito.adminUserGlobalSignOut(params, function(err, data) {
//             if (err) console.log(err, err.stack); // an error occurred
//             else     console.log(data);           // successful response
//           });
//           return
        try 
        {
            const tableData = [...this.state.tableData];
            const index = tableData.findIndex(user => user.email === email);
            let userToUpdate = tableData[index];
            if (userToUpdate.active)
                await cognito.adminDisableUser({
                    UserPoolId: user_pool_id,
                    Username: email,
                }).promise();
            else
                await cognito.adminEnableUser({
                    UserPoolId: user_pool_id,
                    Username: email,
                }).promise();
            userToUpdate.active = !userToUpdate.active; // toggle boolean
            tableData[index] = userToUpdate;         
            this.setState({ tableData });
        } catch (err) {
            Toast.fail('Error updating user', 1500, () => {console.log(err);});
        }
    }

    handleAddUser = async (email, companyId) => {
        const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,9})+$/;
        if(!email.match(mailformat))
            Toast.fail('Please, enter valid email address', 1300, () => {console.log(email);});
        else if (/[A-Z]/.test(email))
            Toast.fail('Please, use lowercase only', 1100);
        else {
            this.setState({ openAddUser: false });
            try 
            {
                let result = await cognito.adminCreateUser({
                    UserPoolId: user_pool_id,
                    Username: email,
                    // this (the whole UserAttributes array) removes email verification step 
                    // completely as per Sportfive suggestion (11.07.20). TODO: perhaps need a checkbox 
                    // to give manager a choice whether to require this verification
                    UserAttributes: [ 
                        {
                            Name: 'email',
                            Value: email
                        },
                        {
                            Name: 'email_verified',
                            Value: 'true'
                        }
                    ],
                //   MessageAction: 'SUPPRESS',
                //   TemporaryPassword: password,
                }).promise();
                // check chosen company. If none option was chosen, set companyId to null
                let company = this.state.companies.find(item => item.id === companyId);
                let input;
                if (!company) {
                    company = '';
                    input = {
                        id: result.User.Username,
                        email: email,
                        role: 'Client',
                        isManager: false
                    }
                }
                else {
                    company = company.name;
                    input = {
                        id: result.User.Username,
                        userCompanyId: companyId,
                        email: email,
                        role: 'Client',
                        isManager: false
                    }
                }

                const registerUserResult = await API.graphql(
                            graphqlOperation(registerUser, { input })
                );
                console.log(registerUserResult);
                
                const dateMoment = moment(result.User.UserCreateDate, "DD/MM/YYYY").format('DD.MM.YYYY');
                const newUser = {
                        role: 'Client',
                        status: result.User.UserStatus,
                        active: result.User.Enabled,
                        dateCreated: dateMoment,
                        email: email,
                        username: result.User.Username,
                        company: company
                }
                this.setState({ tableData: [...this.state.tableData, newUser] });
                const successMessage = 'Invitation sent to ' + email;
                Toast.success(successMessage, 2300, () => {
                    console.log(result);
                });
            } catch (err) {
                if (err.name === 'UsernameExistsException') // handle same email user error
                    Toast.fail('User with email ' + email + ' already exists', 2000, () => {console.log(err);});
                else
                    Toast.fail('Error adding user', 1500, () => {console.log(err);});
            }
        }
    }
    handleDeleteUser = async (emailForConfirmation) => {
        const email = this.state.userToWorkWith.email;
        const userToWorkWith = this.state.userToWorkWith;

        if (email !== emailForConfirmation)
            Toast.fail('Email you entered is incorrect', 2000, () => {console.log(emailForConfirmation);});
        else {
            this.setState({ openDeleteUser: false, userToWorkWith: null });
            try 
            {
                // before deleting DynamoDB user object, check whether it exists
                const userDbRecord = await API.graphql(
                    graphqlOperation(getUser, { id: userToWorkWith.username } )
                );
                if(userDbRecord.data.getUser) {
                    let input = {
                        id: userToWorkWith.username
                    }
                    const result = await API.graphql(
                                graphqlOperation(deleteUser, { input })
                    );
                    console.log(result);
                }
                else
                    console.log("ATTENTION! No userDbRecord found. Deleting Cognito object only...");
                // delete Cognito object
                const res = await cognito.adminDeleteUser ({
                    UserPoolId: user_pool_id,
                    Username: email,
                }).promise();
                
                Toast.success(`User ${email} deleted`, 1000, () => {console.log(res)});
                // update state
                const tableData = this.state.tableData.filter(
                    user => user.email !== email
                );
                this.setState({ tableData });
            } catch (err) {
                Toast.fail('Error deleting user', 1500, () => {console.log(err);});
            }
        }
    }
    handlePasswordReset = async () => {
        const email = this.state.userToWorkWith;
        this.setState({ openResetPwd: false, userToWorkWith: null });
        try 
        {
            const res = await cognito.adminResetUserPassword ({
                UserPoolId: user_pool_id,
                Username: email,
            }).promise();
            Toast.success(`Reset code sent to ${email}`, 1500, () => {
                console.log(res)});
            // update state
            const tableData = [...this.state.tableData];
            const index = tableData.findIndex(user => user.email === email);
            let userToUpdate = tableData[index];
            userToUpdate.status = "RESET_REQUIRED";
            tableData[index] = userToUpdate;
            this.setState({ tableData });
        } catch (err) {
            Toast.fail('Error resetting password', 1500, () => {console.log(err);});
        }
    }
    toggleManagers = () => {
        // tableData.length - make a separate var to set table size
        // hide managers
        let { showManagers } = this.state;
        showManagers = !showManagers; // toggle boolean
        console.log(showManagers);
        if (showManagers) {
            const managers = this.state.users
                .filter(user => user.role === 'manager')
                .map((user, i) =>
                    {
                        return { 
                            email: user.email,
                            role: user.role,
                            status: user.status,
                            active: user.enabled,
                            dateCreated: user.dateCreated,
                            username: user.username,
                            company: user.company ? user.company.name : ""
                        }
                    });
            this.setState({ tableData: [...this.state.tableData, ...managers] });
        }
        // hide managers
        else {
            this.setState({ tableData: this.state.tableData.filter(user =>
                user.role !== 'manager')});
        }
        this.setState({ showManagers });
    }
    // TODO!! sanitize name and description
    handleAddCompany = async (name, description) => {
        this.setState({ openAddCompany: false });
        console.log(name, description);
        // validation
        if (!name) {
            Toast.fail('All field marked with an * are required', 2000);
        }
        else if(this.state.companies.map(c => c.name).includes(name)) {
            Toast.fail(`Company ${name} already exists`, 2000);
        }
        else {
            try {
                let input = {
                    name,
                    description,
                    active: true
                }
                let result = await API.graphql(
                    graphqlOperation(createCompany, { input })
                );

                const newCompany = result.data.createCompany;
                // add new company to array of existing companies and sort it
                let companies = [...this.state.companies, newCompany]
                                    .sort((a, b) => a.name.localeCompare(b.name));
                // update AdminPage state
                this.setState({ companies });
                
                // update main state in app.js
                this.props.updateAdminPageProps(newCompany, 'add');

                Toast.success(`Company ${name} added`, 800, () => {
                    console.log(result);
                    // window.location.reload();
                });
            } catch (err) {
                Toast.fail('Error creating compay', 1500, () => {console.log(err);});
            }
        }
    }
    // delete company and derivatives (media, client users, bookings)
    handleDeleteCompany = async (companyId) => {
        this.setState({ openDeleteCompany: false });
        console.log(this.state.companies);
        console.log(companyId);
        if (!companyId) {
            Toast.fail('Please, select a company first', 1300);
            return false;
        }
        try {
            
            Toast.loading('Deleting company...');
            // finding out what do we need to delete apart from the company itself 
            // (client users, images, videos)
            const company = await API.graphql(
                graphqlOperation(getCompanyToDelete, { id: companyId } )
            );
            console.log(company);
            const companyName = company.data.getCompany.name;
            const users = company.data.getCompany.users.items;
            const images = company.data.getCompany.images.items;
            const videos = company.data.getCompany.videos.items;
            console.log(users, images, videos);
            let input;
            
            // delete CompanyMatchBookings
            const allBookings = await getAllCompanyMatchBookings();
            console.log(allBookings);
            const bookingsToRemove = allBookings.filter(item => item.company.id === companyId);
            console.log(bookingsToRemove);

            for (let booking of bookingsToRemove) {
                input = { id: booking.id };
                let resultDeleteBooking = await API.graphql(graphqlOperation(deleteCompanyMatchBooking, { input }));
                console.log("removed: ", resultDeleteBooking);
            }

            // delete client users who are part of company in question (if any)
            for (let user of users) {
                
                // first delete user DB record from DynamoDB
                input = { id: user.id };   
                let resultDeleteDb = await API.graphql(
                    graphqlOperation(deleteUser, { input })
                );
                console.log(resultDeleteDb);
                
                // delete Cognito object afterwards
                let resultDeleteCognito = await cognito.adminDeleteUser ({
                        UserPoolId: user_pool_id,
                        Username: user.id,
                    }).promise();
                console.log(resultDeleteCognito);
            }

            // delete images
            for (let image of images) {
                
                if (image.fullsize)
                Storage.remove(image.fullsize.key)
                    .then(result => console.log(result))
                    .catch(err => console.log(err));
                if (image.thumbnail)
                    Storage.remove(image.thumbnail.key)
                        .then(result => console.log(result))
                        .catch(err => console.log(err));
                else
                    console.log("No thumbnail file was found");
                console.log(image.thumbnail)
                // delete from DB
                input = { id: image.id };
                let resultDeleteImageDb = await API.graphql(
                    graphqlOperation(deleteImage, { input })
                );
                console.log(resultDeleteImageDb);
            }

            // delete videos
            for (let video of videos) {
                if (video.key && video.extra !== "F") {
                    // delete physical video from S3 storage
                    const publicKey = "public/" + video.key;
                    const params = {
                        Bucket: MEDIA_BUCKET,
                        Key: publicKey,
                    };
                    const data = await S3.deleteObject(params).promise();
                    console.log("delete video from S3", data);
                }
                else { console.log("video Full")}
    
                // delete corresponding record from DB
                input = { id: video.id };
                const result = await API.graphql(
                    graphqlOperation(deleteVideo, { input })
                );
                console.log("delete video record from DB", result);
            }
            
            // finally, remove the actual company object
            input = { id: companyId };
            let resultDeleteCompany = await API.graphql(
                graphqlOperation(deleteCompany, { input })
            );
            console.log(resultDeleteCompany);

            // update state (users in tableData and companies)
            const tableData = this.state.tableData.filter(
                user => user.company !== companyName
            );
            const companies = this.state.companies.filter(
                company => company.id !== companyId
            );

            this.setState({ tableData, companies });
            this.props.updateAdminPageProps(companyId, 'delete');
            // Toast.hide();
            Toast.success(`Company successfully deleted`, 900);
            
        } catch (err) {
            Toast.fail('Cannot delete company', 1500, () => {console.log(err);});
        }
    }
    handleCompanyChange = async (companyId) => {
        const { companies, userToWorkWith } = this.state;
        console.log(userToWorkWith);
        // console.log(companyId);
        // const companyName = 
        this.setState({ openChangeCompany: false, userToWorkWith: null });
        try {
            // NOTE!! companyId is equal to "None" if such option was picked
            // This should be in sync with the value from modalCompanyChange.js
            let company = companies.find(item => item.id === companyId);
            // company is undefined if nothing was found
            if (company) 
                company = company.name;
            else {
                company = "";
                companyId = null;
            }

            // update state
            // TODO
            // MAKE THIS STATE UPDATE ROUTINE UNIFORM ALL OVER APPLICATION
            // PERHAPS, IT'S A SAFER WAY (NO MUTATIONS).
            // ALSO, CREATE A SEPARATE FUNCTION
            // !!!!!!!!!!!!!!!!!!!!
            let tableData = [...this.state.tableData];
            const index = tableData.findIndex(user => userToWorkWith.email === user.email);
            const updatedUser = { ...tableData[index], company };
            const updatedTableData = [
                ...tableData.slice(0, index),
                updatedUser,
                ...tableData.slice(index + 1)
            ];
            let input = {
                id: updatedUser.username,
                userCompanyId: companyId
            }
            let result = await API.graphql(
                graphqlOperation(updateUser, { input })
            );
            console.log(result);

            this.setState({ tableData: updatedTableData });
            // Toast.success(userToWorkWith.email + " successfully assigned to company " + companyId, 1000, () => {
            //     console.log(companyId);
            // });
        } catch (err) {
            Toast.fail('Error assigning user to a company', 1500, () => {console.log(err);});
        }
    }
    // CompanySelect = (props) => {
    //     const { companies, userToWorkWith } = this.state;
    //     console.log(userToWorkWith)
    //     console.log(companies)
    //     const handleChange = (event) => {
    //         this.setState({companyToWorkWith : event.target.value || ''});
    //     };
    //     return (
    //     <FormControl >
    //         <InputLabel id="demo-dialog-select-label">Company</InputLabel>
    //         <Select
    //         labelId="demo-dialog-select-label"
    //         id="demo-dialog-select"
    //         value={userToWorkWith.company || "None"}
    //         //   inputRef={c => this.companyRef = c}
    //         onChange={handleChange}
    //         input={<Input />}
    //         >
    //         <MenuItem value="None">
    //             <em>None</em>
    //         </MenuItem>
    //         {companies.map((company) =>
    //             <MenuItem key={company.id} value={company.id}>{company.name}</MenuItem>
    //         )}
    //         </Select>
    //     </FormControl>        
    //     )
    // }
    createUserDbRecord = async (user) => {
        try {
            console.log(user);
            let input = {
                id: user.username,
                email: user.email,
                role: user.role,
                isManager: false
            }
            console.log(input);
            const result = await API.graphql(
                        graphqlOperation(registerUser, { input })
            );
            console.log(result);
        } catch (err) {
            Toast.fail('Error registering user', 1500, () => {console.log(err);});
        }
    }
    deleteUserDbRecord = async (user) => {
        try {
            console.log(user);
            
            let input = {
                id: user.username
            }
            const result = await API.graphql(
                        graphqlOperation(deleteUser, { input })
            );
            console.log(result);
        } catch (err) {
            Toast.fail('Error. Can\'t delete user', 1500, () => {console.log(err);});
        }
    }
    handleMakeUserAManager = async (emailForConfirmation) => {
        const user = this.state.userToWorkWith;
        console.log(user);
        if (user.email !== emailForConfirmation) {
            Toast.fail('Email you entered is incorrect', 2000, () => {console.log(emailForConfirmation);});
            return;
        }
        try {
            this.setState({ openMakeUserAManager: false, userToWorkWith: null });
            let tableData = [...this.state.tableData];
            const index = tableData.findIndex(item => item.email === user.email);
            const updatedUser = { ...tableData[index], role: 'manager', company: '' };

            const updatedTableData = [
                ...tableData.slice(0, index),
                updatedUser,
                ...tableData.slice(index + 1)
            ];
            let input = {
                id: updatedUser.username,
                isManager: true,
                userCompanyId: null,
                role: 'Manager'
            }
    
            await API.graphql(
                graphqlOperation(updateUser, { input })
            );

            this.setState({ tableData: updatedTableData });
        } catch (err) {
            Toast.fail('Error. Change user role', 1500, () => {console.log(err);});
        }
    }

    test = async (user) => {

        try {

            let input = {
                id: user.username,
                email: user.email,
                role: user.role
            }
            console.log(input)
        } catch (err) {
            Toast.fail('Error', 1500, () => {console.log(err);});
        }
    }

    render () { 
        const { users, companies, tableData, openAddUser, openAddCompany, openDeleteUser, 
            openMakeUserAManager, openResetPwd, openChangeCompany, openDeleteCompany, 
            userToWorkWith, showManagers } = this.state;

        if (!users)
             return (<div className="lds-dual-ring"></div>);
        else
        return (         
        <Segment className="customizedSegment">
        <div className="adminPage">
            {/* <ThemeProvider theme={adminPageTheme}> */}
            <MaterialTable
                title={showManagers ? "Clients and managers" : "Client accounts"}
                columns={[
                    { title: 'Email', field: 'email', defaultSort: 'asc' },
                    
                    { title: 'Company', field: 'company', 
                        render: rowData =>  rowData.company ? 
                            rowData.company : 
                            rowData.role === 'manager' ? 
                                <Tooltip title='Manager account'><span className="material-icons iconGreen MuiIcon-root userEnabledIcon"><HowToRegOutlinedIcon /></span></Tooltip> 
                                :
                                <Tooltip title='No company assigned'>
                                    <ClearIcon className="iconRed" />
                                </Tooltip>,
                        cellStyle: {textAlign: "center"}
                        // (rowData) => {
                        //     if (rowData === "") return {textAlign: "center"} 
                        //     else  return {textAlign: "left"} 
                        // } 
                    },
                    
                    { title: 'Role', field: 'role', render: rowData =>  rowData.role === 'manager' ? 
                        <Tooltip title='Manager'><span className="material-icons iconGreen MuiIcon-root userEnabledIcon"><HowToRegOutlinedIcon /></span></Tooltip> 
                        :
                        <Tooltip title='Client'><span className="material-icons MuiIcon-root userDisabledIcon"><PersonOutlineOutlinedIcon /></span></Tooltip>,
                        cellStyle: {
                            width: 40,
                            maxWidth: 40
                        },
                        headerStyle: {
                            width: 40,
                            maxWidth: 40
                        }
                    },
                    
                    { title: 'Status', field: 'status', render: rowData =>  rowData.status === 'CONFIRMED' ? 
                        <Tooltip title='Confirmed'><span className="material-icons iconGreen MuiIcon-root userEnabledIcon"><VerifiedUserIcon /></span></Tooltip> 
                        :
                        <Tooltip title={rowData.status}><span className="material-icons iconRed MuiIcon-root userDisabledIcon"><PriorityHighIcon /></span></Tooltip>,
                        cellStyle: {
                            width: 40,
                            maxWidth: 40
                        },
                        headerStyle: {
                            width: 40,
                            maxWidth: 40
                        }
                    },
                    { title: 'Active', field: 'active', render: rowData =>  rowData.active ? 
                        <Tooltip title='Active'><span className="material-icons iconGreen MuiIcon-root userEnabledIcon">done</span></Tooltip>
                        :
                        <Tooltip title='Account is disabled'><span className="material-icons iconRed MuiIcon-root userDisabledIcon">block</span></Tooltip>,
                        cellStyle: {
                            width: 40,
                            maxWidth: 40
                        },
                        headerStyle: {
                            width: 40,
                            maxWidth: 40
                        }
                    },
                    // { title: 'Created', field: 'dateCreated',
                    //     cellStyle: {
                    //         width: 110,
                    //         minWidth: 110
                    //     },
                    //     headerStyle: {
                    //         width: 110,
                    //         minWidth: 110
                    //     }
                    // },
                    { title: 'Last active', field: 'lastActivityDate',
                        cellStyle: {
                            width: 120,
                            minWidth: 120
                        },
                        headerStyle: {
                            width: 120,
                            minWidth: 120
                        }
                    },
                ]}
                components={{
                    Container: props => <Paper {...props} elevation={0}/>
                }}
                data={tableData}
                actions={[
                    rowData => ({
                        icon: () => <GroupOutlinedIcon />,
                        tooltip: 'Set/change company',
                        onClick: (event, rowData) => this.openModalChangeCompany(rowData),
                        disabled: rowData.role === "manager"
                    }),
                    rowData => ({
                        icon: () => <VpnKeyIcon />,
                        tooltip: 'Reset password',
                        onClick: (event, rowData) => this.openModalResetPwd(rowData.email),
                        disabled: rowData.role === "manager"
                    }),
                    rowData => ({
                        icon: () => <HowToRegOutlinedIcon />,
                        tooltip: 'Make this user a manager',
                        onClick: (event, rowData) => this.openModalMakeUserAManager(rowData),
                        disabled: rowData.role === "manager"
                    }),
                    rowData => ({
                        icon: () => rowData.active ? <LockOutlinedIcon /> : <LockOpenIcon />,
                        tooltip: rowData.active ? 'Temporarily disable account' : 'Enable account',
                        onClick: (event, rowData) => this.handleDisableEnableUser(rowData.email),
                        disabled: rowData.role === "manager"
                    }),
                    rowData => ({
                        icon: () => <DeleteOutlinedIcon />,
                        tooltip: 'Delete user',
                        onClick: (event, rowData) => this.openModalDeleteUser(rowData),
                        disabled: rowData.role === "manager"
                    }),    
                    {
                        icon: () => showManagers ? <HowToRegOutlinedIcon /> : <HowToRegOutlinedIcon className="iconGreen" />,
                        tooltip: showManagers ? 'Hide manager users' : 'Show manager users',
                        isFreeAction: true,
                        onClick: () => 
                        this.toggleManagers()
                    },

                    {
                        icon: () => <PersonAddIcon />,
                        tooltip: 'Add user',
                        isFreeAction: true,
                        onClick: () => this.openModalAddUser()
                    },
                    {
                        icon: () => <GroupAddIcon />,
                        tooltip: 'Add company',
                        isFreeAction: true,
                        onClick: () => this.openModalAddCompany()
                    },
                    {
                        icon: () => <DeleteOutlinedIcon className="deleteCompanyIcon" />,
                        tooltip: 'Delete company',
                        isFreeAction: true,
                        onClick: () => this.openModalDeleteCompany()
                    }
                ]}
                // a workaround to change pageSize dynamically
                // more details: https://github.com/mbrn/material-table/issues/1480
                key={tableData.length}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: tableData.length, 
                    sorting: true,
                    emptyRowsWhenPaging: false // do not render extra rows to complete page size
                }}
            />
            {/* </ThemeProvider> */}
            {openAddUser &&
                    <ModalAddUserComponent
                        Transition={Transition}
                        closeModalAddUser={this.closeModalAddUser}
                        companies={companies}
                        handleAddUser={this.handleAddUser}
                    />
            // <Dialog open={openAddUser} TransitionComponent={Transition} onClose={this.closeModalAddUser} aria-labelledby="form-dialog-title">
            //     <DialogTitle id="form-dialog-title">Invite a new user</DialogTitle>
            //     <DialogContent>
            //     <DialogContentText>
            //         Invite a new client user by email.  
            //         Optionally, specify the company you want this user to be part of.
            //     </DialogContentText>
            //     <TextField
            //         inputRef={c => this.myRef = c}
            //         autoFocus
            //         margin="dense"
            //         id="name"
            //         label="Email address"
            //         type="email"
            //         fullWidth
            //     />
            //     </DialogContent>
            //     <DialogActions>
            //     <Button onClick={this.closeModalAddUser} color="primary">
            //         Cancel
            //     </Button>
            //     <Button onClick={(event) => this.handleAddUser(this.myRef.value)} color="primary">
            //         Send invitation
            //     </Button>
            //     </DialogActions>
            // </Dialog>
            }
            {openAddCompany &&
            <Dialog open={openAddCompany} TransitionComponent={Transition} onClose={this.closeModalAddCompany} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add a company</DialogTitle>
                <DialogContent>
                <Divider />
                <br/>
                <DialogContentText>
                    Please, enter company name. Optionally, you may add a description.
                </DialogContentText>
                <TextField
                    inputRef={c => this.nameRef = c}
                    required
                    margin="dense"
                    id="name"
                    label="Company name"
                    // type="email"
                    fullWidth
                />
                <TextField
                    inputRef={c => this.descRef = c}
                    margin="dense"
                    id="description"
                    label="Description"
                    // type="email"
                    fullWidth
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={this.closeModalAddCompany} color="primary">
                    Cancel
                </Button>
                <Button onClick={(event) => this.handleAddCompany(this.nameRef.value, this.descRef.value)} color="primary">
                    Create company
                </Button>
                </DialogActions>
            </Dialog>
            }

            {openDeleteUser && 
            <Dialog open={openDeleteUser} TransitionComponent={Transition} onClose={this.closeModalDeleteUser} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Delete user <span className="red">'{userToWorkWith.email}'</span>
                </DialogTitle>
                <Divider />
                <br />
                <DialogContent>
                <DialogContentText>
                    User <span className="italic">{userToWorkWith.email}</span> will be
                    constantly removed. Are you absolutely sure?
                </DialogContentText>
                <TextField
                    inputRef={c => this.emailRef = c}
                    autoFocus
                    required
                    margin="dense"
                    id="user"
                    label="Please enter user's email to confirm"
                    type="text"
                    fullWidth
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={this.closeModalDeleteUser} color="primary">
                    Cancel
                </Button>
                <Button className="red" onClick={(event) => this.handleDeleteUser(this.emailRef.value)} color="secondary">
                    Delete
                </Button>
                </DialogActions>
            </Dialog>
            }
            {openMakeUserAManager && 
            <Dialog open={openMakeUserAManager} TransitionComponent={Transition} onClose={this.closeModalMakeUserAManager} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Assign manager role to <span className="red">'{userToWorkWith.email}'</span>
                </DialogTitle>
                <Divider />
                <br />
                <DialogContent>
                <DialogContentText>
                    Client user <span className="italic">{userToWorkWith.email}</span> will be given
                    manager privileges. You won't be able to edit this account afterwards. Are you sure?
                </DialogContentText>
                <TextField
                    inputRef={c => this.emailRef = c}
                    autoFocus
                    required
                    margin="dense"
                    id="user"
                    label="Please enter user's email to confirm"
                    type="text"
                    fullWidth
                />
                </DialogContent>
                <DialogActions>
                <Button onClick={this.closeModalMakeUserAManager} color="primary">
                    Cancel
                </Button>
                <Button className="red" onClick={(event) => this.handleMakeUserAManager(this.emailRef.value)} color="secondary">
                    Make manager
                </Button>
                </DialogActions>
            </Dialog>
            }
            {openResetPwd &&
            <Dialog open={openResetPwd} TransitionComponent={Transition} onClose={this.closeModalResetPwd} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Reset password for user <span className="red">'{userToWorkWith}'</span>
                </DialogTitle>
                <Divider variant="middle" />
                <br/>
                <DialogContent>
                <DialogContentText>
                    A reset code will be sent to <span className="italic">{userToWorkWith}</span>. Are you sure?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.closeModalResetPwd} color="primary">
                    Cancel
                </Button>
                <Button className="red" onClick={(event) => this.handlePasswordReset()} color="secondary">
                    Reset password
                </Button>
                </DialogActions>
            </Dialog>
            }
            {openChangeCompany &&
            <ModalCompanyChangeComponent
                Transition={Transition}
                closeModalChangeCompany={this.closeModalChangeCompany}
                user={userToWorkWith.email}
                company={userToWorkWith.company}
                companies={companies}
                handleCompanyChange={this.handleCompanyChange}
            />
            }
            {openDeleteCompany &&
            <ModalCompanyDeleteComponent
                Transition={Transition}
                closeModalDeleteCompany={this.closeModalDeleteCompany}
                // user={userToWorkWith.email}
                // company={userToWorkWith.company}
                companies={companies}
                handleDeleteCompany={this.handleDeleteCompany}
            />
            }
        </div>
        </Segment> 
        )
    }
}

export default AdminPage;