/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const registerUser = /* GraphQL */ `
  mutation RegisterUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    registerUser(input: $input, condition: $condition) {
      id
      role
      email
      token
      lastActive
      accepted
      extra
      isManager
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      matches {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      role
      email
      token
      lastActive
      accepted
      extra
      isManager
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      matches {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      role
      email
      token
      lastActive
      accepted
      extra
      isManager
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      matches {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMatchBooking = /* GraphQL */ `
  mutation CreateMatchBooking(
    $input: CreateMatchBookingInput!
    $condition: ModelMatchBookingConditionInput
  ) {
    createMatchBooking(input: $input, condition: $condition) {
      id
      user {
        id
        role
        email
        token
        lastActive
        accepted
        extra
        isManager
        company {
          id
          name
          description
          active
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
        createdAt
        updatedAt
      }
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMatchBooking = /* GraphQL */ `
  mutation UpdateMatchBooking(
    $input: UpdateMatchBookingInput!
    $condition: ModelMatchBookingConditionInput
  ) {
    updateMatchBooking(input: $input, condition: $condition) {
      id
      user {
        id
        role
        email
        token
        lastActive
        accepted
        extra
        isManager
        company {
          id
          name
          description
          active
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
        createdAt
        updatedAt
      }
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMatchBooking = /* GraphQL */ `
  mutation DeleteMatchBooking(
    $input: DeleteMatchBookingInput!
    $condition: ModelMatchBookingConditionInput
  ) {
    deleteMatchBooking(input: $input, condition: $condition) {
      id
      user {
        id
        role
        email
        token
        lastActive
        accepted
        extra
        isManager
        company {
          id
          name
          description
          active
          createdAt
          updatedAt
        }
        matches {
          nextToken
        }
        createdAt
        updatedAt
      }
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createMatch = /* GraphQL */ `
  mutation CreateMatch(
    $input: CreateMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    createMatch(input: $input, condition: $condition) {
      id
      details
      date
      place
      minutes
      bookedMinutes
      message
      competition
      feeds
      logo
      regions
      hidden
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          feeds
          createdAt
          updatedAt
        }
        nextToken
      }
      images {
        items {
          id
          feedNumber
          bucket
          region
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          bucket
          key
          link
          extra
          misc
          feedNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateMatch = /* GraphQL */ `
  mutation UpdateMatch(
    $input: UpdateMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    updateMatch(input: $input, condition: $condition) {
      id
      details
      date
      place
      minutes
      bookedMinutes
      message
      competition
      feeds
      logo
      regions
      hidden
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          feeds
          createdAt
          updatedAt
        }
        nextToken
      }
      images {
        items {
          id
          feedNumber
          bucket
          region
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          bucket
          key
          link
          extra
          misc
          feedNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteMatch = /* GraphQL */ `
  mutation DeleteMatch(
    $input: DeleteMatchInput!
    $condition: ModelMatchConditionInput
  ) {
    deleteMatch(input: $input, condition: $condition) {
      id
      details
      date
      place
      minutes
      bookedMinutes
      message
      competition
      feeds
      logo
      regions
      hidden
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      companies {
        items {
          id
          feeds
          createdAt
          updatedAt
        }
        nextToken
      }
      images {
        items {
          id
          feedNumber
          bucket
          region
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          bucket
          key
          link
          extra
          misc
          feedNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyMatchBooking = /* GraphQL */ `
  mutation CreateCompanyMatchBooking(
    $input: CreateCompanyMatchBookingInput!
    $condition: ModelCompanyMatchBookingConditionInput
  ) {
    createCompanyMatchBooking(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      feeds
      createdAt
      updatedAt
    }
  }
`;
export const updateCompanyMatchBooking = /* GraphQL */ `
  mutation UpdateCompanyMatchBooking(
    $input: UpdateCompanyMatchBookingInput!
    $condition: ModelCompanyMatchBookingConditionInput
  ) {
    updateCompanyMatchBooking(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      feeds
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompanyMatchBooking = /* GraphQL */ `
  mutation DeleteCompanyMatchBooking(
    $input: DeleteCompanyMatchBookingInput!
    $condition: ModelCompanyMatchBookingConditionInput
  ) {
    deleteCompanyMatchBooking(input: $input, condition: $condition) {
      id
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      feeds
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      name
      description
      active
      users {
        items {
          id
          role
          email
          token
          lastActive
          accepted
          extra
          isManager
          createdAt
          updatedAt
        }
        nextToken
      }
      matches {
        items {
          id
          feeds
          createdAt
          updatedAt
        }
        nextToken
      }
      images {
        items {
          id
          feedNumber
          bucket
          region
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          bucket
          key
          link
          extra
          misc
          feedNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      name
      description
      active
      users {
        items {
          id
          role
          email
          token
          lastActive
          accepted
          extra
          isManager
          createdAt
          updatedAt
        }
        nextToken
      }
      matches {
        items {
          id
          feeds
          createdAt
          updatedAt
        }
        nextToken
      }
      images {
        items {
          id
          feedNumber
          bucket
          region
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          bucket
          key
          link
          extra
          misc
          feedNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      name
      description
      active
      users {
        items {
          id
          role
          email
          token
          lastActive
          accepted
          extra
          isManager
          createdAt
          updatedAt
        }
        nextToken
      }
      matches {
        items {
          id
          feeds
          createdAt
          updatedAt
        }
        nextToken
      }
      images {
        items {
          id
          feedNumber
          bucket
          region
          createdAt
          updatedAt
        }
        nextToken
      }
      videos {
        items {
          id
          bucket
          key
          link
          extra
          misc
          feedNumber
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFeed = /* GraphQL */ `
  mutation CreateFeed(
    $input: CreateFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    createFeed(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateFeed = /* GraphQL */ `
  mutation UpdateFeed(
    $input: UpdateFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    updateFeed(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteFeed = /* GraphQL */ `
  mutation DeleteFeed(
    $input: DeleteFeedInput!
    $condition: ModelFeedConditionInput
  ) {
    deleteFeed(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createImage = /* GraphQL */ `
  mutation CreateImage(
    $input: CreateImageInput!
    $condition: ModelImageConditionInput
  ) {
    createImage(input: $input, condition: $condition) {
      id
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      feedNumber
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      bucket
      region
      fullsize {
        key
        width
        height
        size
        type
      }
      thumbnail {
        key
        width
        height
        size
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateImage = /* GraphQL */ `
  mutation UpdateImage(
    $input: UpdateImageInput!
    $condition: ModelImageConditionInput
  ) {
    updateImage(input: $input, condition: $condition) {
      id
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      feedNumber
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      bucket
      region
      fullsize {
        key
        width
        height
        size
        type
      }
      thumbnail {
        key
        width
        height
        size
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteImage = /* GraphQL */ `
  mutation DeleteImage(
    $input: DeleteImageInput!
    $condition: ModelImageConditionInput
  ) {
    deleteImage(input: $input, condition: $condition) {
      id
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      feedNumber
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      bucket
      region
      fullsize {
        key
        width
        height
        size
        type
      }
      thumbnail {
        key
        width
        height
        size
        type
      }
      createdAt
      updatedAt
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      bucket
      key
      link
      extra
      misc
      feedNumber
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      bucket
      key
      link
      extra
      misc
      feedNumber
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      bucket
      key
      link
      extra
      misc
      feedNumber
      match {
        id
        details
        date
        place
        minutes
        bookedMinutes
        message
        competition
        feeds
        logo
        regions
        hidden
        users {
          nextToken
        }
        companies {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      company {
        id
        name
        description
        active
        users {
          nextToken
        }
        matches {
          nextToken
        }
        images {
          nextToken
        }
        videos {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
