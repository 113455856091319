import React, { Component } from 'react';
import { listCompanyMatchBookings, getUser } from '../graphql/queries';
import { createMatch, updateMatch, updateUser, createCompanyMatchBooking, deleteCompanyMatchBooking, createCompany } from '../graphql/mutations';
import { listMatchesWithImages } from '../utils/customQueries';
import { DEFAULT_COMP, DEFAULT_LOGO,
    CHECK_SINGLE_SESSION, ACCESS_DB_ON_TABLE_LOAD, THEME } from '../utils/constants';
import { API, graphqlOperation, Auth } from "aws-amplify";
import "../App.css";
import MaterialTable from '@material-table/core';
import Panel from '../components/panelNew';
import PanelDialog from '../components/panelDialog';
import Toast from 'light-toast';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';
import UnarchiveOutlinedIcon from '@material-ui/icons/UnarchiveOutlined';
import IconButton from '@material-ui/core/IconButton';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import ClearIcon from '@material-ui/icons/Clear';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import Paper from '@material-ui/core/Paper';
import { getAllCompanyMatchBookings } from '../utils/functions';
import moment from 'moment';
import aws_exports from '../aws-exports';
import { Segment } from 'semantic-ui-react';
import Tooltip from '@material-ui/core/Tooltip';
import TransferList from '../components/transferList';
import Popover from '../components/popover';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import Tab from '@material-ui/core/Tab';

const AWS = require('aws-sdk');
AWS.config.update ({
    region: aws_exports.aws_project_region
});
let S3;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
class ReportingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matches: null,
            newMatches: null,
            openUpdateDB: false,
            open: false,
            diffMatches: null,
            currentMatch: {},
            tab: 1,
            showArchivedMatches: false,
            openVideoDialog: false
        }
    }
    openUpdateDBModal = () => { this.setState({ openUpdateDB : true }); }
    closeUpdateDBModal = () => { this.setState({ openUpdateDB : false }); }
    handleOpen = () => {
        this.setState({ open: true });
    };
    handleClose = () => {
        this.setState({ open: false, tab: 1 });
    };
    async componentDidMount() {
        const credentials = await Auth.currentCredentials();
        AWS.config.update ({
            credentials: Auth.essentialCredentials(credentials)
        });
        S3 = new AWS.S3({ signatureVersion: 'v4' });

        if (CHECK_SINGLE_SESSION && !this.props.user.isManager) {
            const input = { id: this.props.user.id };
            const userDbRecord = await API.graphql(
                graphqlOperation(getUser, input)
            );
            
            // set "Last activity date"
            const todayDB = moment().toDate().toString(); // this goes to db
            const today = new Date(todayDB).setHours(0,0,0,0);            
            const lastActivityDate = userDbRecord.data.getUser.extra;

            if (!lastActivityDate || new Date(lastActivityDate).setHours(0,0,0,0) < today) {
                let input = {
                    id: userDbRecord.data.getUser.id,
                    extra: todayDB
                }
                await API.graphql(
                      graphqlOperation(updateUser, { input })
                );
            }
            
            // ensure login session is unique 
            let acquiredToken = this.props.user.refreshToken;
            if (acquiredToken) {
                console.log("acquiredToken is not null");
            }
            else {
                const user = await Auth.currentAuthenticatedUser();
                console.log("acquiredToken is null: using await Auth.currentAuthenticatedUser()");
                acquiredToken = user.signInUserSession.refreshToken.token;
            }
            
            if (userDbRecord.data.getUser.token !== null &&
                userDbRecord.data.getUser.token !== acquiredToken) {
                    Toast.info(`Signed in from another device. Logging out...`, 1500);
                    console.log("Signed in from another device. Logging out...", userDbRecord.data.getUser.token);
                    Auth.signOut()
                    .then(data => console.log(data))
                    .catch(err => console.log(err));
                    return;
            }
        }
        const maxMatches = 1000;
        this.getMatches(maxMatches);
    }
    // Make sure all detailPanels are closed before we leave reporting page.
    // tableData property is added to array used to populate MaterialTable and
    // stores 'showDetailPanel' func(?) to indicate that this particular panel was opened.
    // We are removing this thing on unmounting table component to avoid some bugs
    // related to multiple panels being opened at once.
    // More details: https://github.com/mbrn/material-table/issues/357
    componentWillUnmount() {
        // console.log("unmount - reporting")
        // handleChange: function (e) {
        //     // 1. Make a shallow copy of the items
        //     let items = [...this.state.items];
        //     // 2. Make a shallow copy of the item you want to mutate
        //     let item = {...items[1]};
        //     // 3. Replace the property you're intested in
        //     item.name = 'newName';
        //     // 4. Put it back into our array. N.B. we *are* mutating the array here, but that's why we made a copy first
        //     items[1] = item;
        //     // 5. Set the state to our new copy
        //     this.setState({items});
        // },
        // let matches = [...this.state.matches];
        // const index = matches.findIndex(el => el.tableData.showDetailPanel);
        // console.log(index);
        // console.log("initial: ", matches.map(el => el.tableData))
        // if (index > -1) {
        //     let match = {
        //         ...matches[index],
        //         tableData: { id: index }
        //     }
        //     // console.log("match: ", match)
        //     const updatedMatches = [
        //         ...matches.slice(0, index),
        //         match,
        //         ...matches.slice(index + 1)
        //     ];
        //     console.log("upd matches: ", updatedMatches.map(el => el.tableData))
        //     this.setState({ matches: updatedMatches });
        //     console.log(this.state.matches.map(el => el.tableData))
        // }
        // console.log("reportingPage - componentWillUnmount");
    }
    getVisibleMatches = (allMatches) => {
        const matches = [...allMatches];
        
        const getVisibleMatchesViaDbBooking = true;
        
        if (getVisibleMatchesViaDbBooking) {
            let visibleMatches = [];
            if (this.props.user.userCompany) {
                visibleMatches = matches.filter(match => match.clients
                    .map(client => client.id).indexOf(this.props.user.userCompany.id) > -1);

            }
            return visibleMatches;
        }
        else {
            let visibleMatchesWithMedia = [];
            if (this.props.user.userCompany) {
                visibleMatchesWithMedia = matches.filter(match => {
                    for (let i = 0; i < match.videos.items.length; i++) {
                        // if match has at least one "VISIBLE TO ALL" video/image, show it to client
                        if (!match.videos.items[i].company)
                            return (match);
                        // we don't expect here any "null" companies (handled in previous lines)
                        if (match.videos.items[i].company.id === this.props.user.userCompany.id) {    
                            return (match);
                        }
                    }
                    for (let i = 0, len = match.images.items.length; i < len; i++) {
                        // if match has at least one "VISIBLE TO ALL" video/image, show it to client
                        if (!match.images.items[i].company)
                            return (match);

                        if (match.images.items[i].company.id === this.props.user.userCompany.id) {
                            return (match);
                        }
                    }
                })
            }

            return visibleMatchesWithMedia;
        }
    }

    getMatches = async (limit) => {
        let matches;

        // always fetch matches from DB for manager users. This is temporary, will be optimized later.
        // To optimize, any changes to user/companies in Admin page as well as any changes done in 
        // settings (transfer list) tab should be sent to app.js via updatePropsAfterChange()
        if (this.props.user.isManager && ACCESS_DB_ON_TABLE_LOAD)
        {
            const result = await API.graphql(graphqlOperation(listMatchesWithImages, { limit }));
            matches = result.data.listMatchs.items;
        }
        else {
            if (!this.props.matches) { // user reloaded page, i.e. no props (as we get them via Router from app.js)
                const result = await API.graphql(graphqlOperation(listMatchesWithImages, { limit }));
                matches = result.data.listMatchs.items;
            } else {
                matches = [...this.props.matches];
                if (matches.length > 0 && matches[0].tableData) {
                    for (let i = 0; i < matches.length; i++) {
                        matches[i].tableData = { id : i }
                    }
                }
            }
        }

        // Hub db has timestamps in UTC format, however in Germany Hub CET (+2 hours) timezone is used
        // also, need to check for dayligth saving time (DST) - added on 15.11.2020
        let timeOffsetDST = moment.duration('02:00:00');
        let timeOffset = moment.duration('01:00:00');
        let matchesWithClients = matches.map(obj => ({ ...obj, 
            feeds: this.convertFeeds(obj.feeds),
            clients: obj.companies ? obj.companies.items.map(item => item['company']) : [],
            kickoff: moment(obj.date).isDST() ? 
                moment(obj.date).subtract(timeOffsetDST).format('HH:mm') 
                :
                moment(obj.date).subtract(timeOffset).format('HH:mm'),
            tableImage: obj.logo ? obj.logo : DEFAULT_LOGO,
            competition: obj.competition ? obj.competition : DEFAULT_COMP,
         }));
        
        if (!this.props.user.isManager)
            matchesWithClients = this.getVisibleMatches(matchesWithClients);
        console.log('matches:', matchesWithClients);
        this.setState({ matches: matchesWithClients });
    }

    // testSetLogosInDB = async () => {
    //     console.log('test');
    //     const { matches } = this.state;
    //     matches.map(m => console.log(m.tableImage));

    //     for (const match of matches) {
    //         const inputMatch = {
    //             id: match.id,
    //             competition: match.competition,
    //             logo: match.tableImage
    //         }
    //         // let resultMatch = await API.graphql(
    //         //     graphqlOperation(updateMatch, { input: inputMatch })
    //         // );
    //         // console.log(resultMatch)
    //     }
    // }
    
    // feedsInString is a string - feed names separated with ';; '
    // this fuction returnes a dictionary with values from this 
    // string and keys being indexes starting from 1, like this:
    // {1: 'CANADA', 2: 'USA', 3: 'WORLD'}
    convertFeeds = (feedsInString) => {
        if (!feedsInString) {
            return {1: 'Reporting'}
        }
        const feedsArr = feedsInString.split(";; ");
        const feedsDict = {};
        
        feedsArr.map((feed, i) => {
            feedsDict[i+1] = feed;
        });
        return feedsDict;
    }

    updateChildPropsAfterChange = (image, matchId, action) => {
        // const { matches } = this.state;
        // const matchIndex = matches.findIndex(match => match.id ===  matchId);
        // let matchToUpdate = matches[matchIndex];
        // const imageIndex = matchToUpdate.images.items
        //     .findIndex(im => im.id === image.id);

        // matchToUpdate.images.items.splice(imageIndex, 1);
        // const updatedMatches = [
        //     ...matches.slice(0, matchIndex),
        //     matchToUpdate,
        //     ...matches.slice(matchIndex + 1)
        //   ];
        if (this.props.update) {
          this.props.update(image, matchId, action);
        }
    }
    // get json-file with Hub events data
    async getObject () {
        try {
            const options = {
                Bucket: aws_exports.aws_user_files_s3_bucket,
                Key: 'public/event.json',
                ResponseContentType: 'application/json'
            };      
            const data = await S3.getObject(options).promise();
      
            // return data.Body.toString('utf-8');
            return data.Body;
        } catch (e) {
            throw new Error(`Could not retrieve file from S3: ${e.message}`)
        }
    }

    addMatches = async () => {
        this.closeUpdateDBModal();
        const { diffMatches } = this.state;

        try {
            for (let match of diffMatches) {
                let result = await API.graphql(
                    graphqlOperation(createMatch, { input: match })
                );
                console.log(result);
            }
            Toast.success(`${diffMatches.length} match(es) added. Database is up to date`, 1500, () => {
                console.log(diffMatches);
                window.location.reload();
            });
        } catch (e) {
            throw new Error(`Could not add matches to DB: ${e.message}`)
        }
        this.setState({ matches: [...this.state.matches, ...diffMatches] });
    }
    refreshDb = async () => {
        const arr = await this.getObject();
        let str = "";   
        for (let i = 0; i < arr.byteLength; i++) {
            str += String.fromCharCode(arr[i]);
        }
        const final = JSON.parse(str);
        const curYear = moment().format('YY'); // get current year
        const nextYear = moment().add(1, "years").format('YY');
        // get matches from the current year (curYear) and next year (if any)
        const db = final.filter(match => 
            moment(match.fields.when).format('YY') == curYear ||
            moment(match.fields.when).format('YY') == nextYear
            ) // .map(match => match.fields);

        const hubMatches = db.map(match => {
            return {
                id: match.pk, 
                details: match.fields.name,
                date: match.fields.when,
            }
        })
        const { matches } = this.state;

        // TODO: REFACTOR!
        // find matches that are not currently in app's DB
        const diffMatches = hubMatches.filter(hubMatch => {
            let exists = false;
            for (let match of matches) {
                if (match.id == hubMatch.id)
                    exists = true;
            }
            if (!exists)
                return hubMatch;
        });

        if (diffMatches.length === 0)
            Toast.success('Database is up to date', 700, () => {
                console.log(diffMatches);
        });
        else {
            this.setState({ diffMatches });
            this.openUpdateDBModal();
        }
    }
    handleFullscreenModalOpen (currentMatch, tab) {
        console.log(currentMatch);
        this.setState({ currentMatch, open: true, tab });    
    }
    handleVideoDialogOpen (currentMatch) {
        this.setState({ currentMatch, openVideoDialog: true});
    }
    closeVideoDialog () {
        this.setState( { openVideoDialog: false });
    }
    handleChoice (tab) {
        this.setState({ tab });
    }
    // CompaniesColumn ( props ) {
    //     const numberOfCompanies = props.companies.items.length;
    //     const popoverConent = (props) => <span>
    //                             <p>Visible to</p>
    //                             {props.test.map((item) => <p>{item.name}</p>)}
    //                         </span>
    //     if (numberOfCompanies === 0)
    //         return (
    //             <span>
    //                 0
    //             </span>
    //         )
    //     else {
    //         return (
    //             <Popover area={rowData.companies.items.length} 
    //                         content={rowData.test.join(', ')} />
                
    //         )
    //     }
    // }

    /* This function applies changes done in transferList component.
    ** As of 12.02.21, fcbayern-analytics doesn't has messageToClients feature,
    ** and matchName change is used instead. 
    ** To make messageToClients feature work in fcbayern version, sync handleAccess() 
    ** code from analytics.supponor.com. Also, remove 'THEME === sportfive' condition
    ** in transferList.js.
    ** CHANGED on 24.12 - DISPLAY_NEW_FEATURES_1_4_4 const introduced
    */ 

    handleAccess = async (matchId, added, hidden, message, matchName) => {
        this.handleClose ();
        let input, result, allBookings;
        const limit = 1000;
        try {
            let tableData = [...this.state.matches];
            const index = tableData.findIndex(match => match.id === matchId);
            let matchToUpdate = { ...tableData[index] };

            const old = matchToUpdate.clients;
            console.log(old);

            // newly added companies (except for existing in DB before)
            const companiesToAdd = added.filter(item => old.map(c => c.id).indexOf(item.id) === -1);
            // companies removed by user
            const companiesToRemove = old.filter(item => added.map(c=>c.id).indexOf(item.id) === -1);
            console.log(companiesToAdd, companiesToRemove);

            // check changes (if any)
            // was name changed?
            const nameChanged = matchToUpdate.details !== matchName;
            
            // was messageToClient changed?
            const messageChanged = matchToUpdate.message !== message;
            
            // hidden field migth be null initially (if not defined on match creation), not neccessary false
            const wasMatchHidden = matchToUpdate.hidden ? true : false; 
            // was hidden field changed?
            const hiddenFieldChanged = hidden !== wasMatchHidden;

            console.log(nameChanged, messageChanged, wasMatchHidden, hiddenFieldChanged, matchToUpdate.hidden);
            // was list of authorized companies changed?
            const accessChanged = companiesToRemove.length > 0 || companiesToAdd.length > 0;
            
            // no changes were made -> just quit the function
            if (!accessChanged && !nameChanged && !messageChanged && !hiddenFieldChanged) {
                Toast.info(`No changes have been made`, 700);
                return true;
            }
            
            Toast.loading("Applying changes...");
            let inputMatch = {
                id: matchToUpdate.id
            }
            if (nameChanged)
                inputMatch['details'] = matchName;
            if (messageChanged)
                inputMatch['message'] = message;
            if (hiddenFieldChanged)
                inputMatch['hidden'] = hidden;

            let resultMatch = await API.graphql(
                graphqlOperation(updateMatch, { input: inputMatch })
            );
            console.log(resultMatch);
            
            // if match is going to be archived, remove all of its existing bookings (if any)
            if (hidden) {
                if (old.length > 0) {
                    console.log("match was visible to some companies");
                    
                    allBookings = await getAllCompanyMatchBookings();
                    for (let company of old) {
                        let bookingToRemove = allBookings.filter(item => item.company.id === company.id)
                            .find(item => item.match.id === matchId);
                        console.log(bookingToRemove);
                        input = { id: bookingToRemove.id };
                        result = await API.graphql(graphqlOperation(deleteCompanyMatchBooking, { input }));
                        console.log("removed: ", result);
                    }
                }
                else
                    console.log("Match was not visible");
                
                this.setState(({ matches }) => ({
                    matches: [
                        ...matches.slice(0, index),
                        {
                            ...matches[index],
                            clients: [],    // since all bookings are removed for a
                            hidden: hidden,
                            details: matchName,
                            message: message
                        },
                        ...matches.slice(index + 1)
                    ]
                }));
                Toast.success(`${matchToUpdate.details} is archived and won't be shown in the matchlist`, 2000);
                
                return true;
            }
            
            // removing companyMatchBookings
            if (companiesToRemove.length > 0) {
                allBookings = await getAllCompanyMatchBookings();
                for (let company of companiesToRemove) {
                    let bookingToRemove = allBookings.filter(item => item.company.id === company.id)
                        .find(item => item.match.id === matchId);
                    console.log(bookingToRemove);
                    input = { id: bookingToRemove.id };
                    result = await API.graphql(graphqlOperation(deleteCompanyMatchBooking, { input }));
                    console.log("removed: ", result);
                }
            }
            
            // adding companyMatchBookings
            let inputs = [];
            for (let company of companiesToAdd) {
                inputs.push({
                    companyMatchBookingCompanyId: company.id,
                    companyMatchBookingMatchId: matchId,
                    feeds: [1, 2, 3, 4]
                })
            }
            
            await Promise.all([
                inputs.map(input => {
                    API.graphql(
                        graphqlOperation(createCompanyMatchBooking, { input })
                    ).then(console.log('added booking: ', input));
                })
            ]);
            
            // for (let company of companiesToAdd) {
            //     input = {
            //         companyMatchBookingCompanyId: company.id,
            //         companyMatchBookingMatchId: matchId,
            //         feeds: [1, 2, 3, 4]
            //     }
            //     result = await API.graphql(
            //         graphqlOperation(createCompanyMatchBooking, { input })
            //     )
            //     console.log(result);
            // }

            if (added.length > 1)
                added.sort((a, b) => a.name.localeCompare(b.name));

            this.setState(({ matches }) => ({
                matches: [
                    ...matches.slice(0, index),
                    {
                        ...matches[index],
                        clients: added,
                        hidden: hidden,
                        message: message,
                        details: matchName
                    },
                    ...matches.slice(index + 1)
                ]
            }));
            if (hiddenFieldChanged) {
                // if (hidden)
                //     Toast.success(`${matchToUpdate.details} is archived and won't be shown in the matchlist`, 2000);
                // else
                    Toast.success(`${matchToUpdate.details} restored from archive`, 2000);
            }
            else if (accessChanged) {
                if (added.length === 1)
                    Toast.success(`Match is visible to ${added[0].name} only`, 900);
                else if (added.length > 1)
                    Toast.success(`Match is visible to ${added.length} companies`, 900);
                else
                    Toast.success(`Match is not visible for client users`, 900);
            } 
            else
                Toast.success(`Match successfully updated`, 800);
            Toast.hide();
        } catch (err) {
            Toast.fail('Error', 1300, () => {console.log(err);});
        }

    }
    handleShowArchivedMatches = () => {
        this.setState(prevState => ({
            showArchivedMatches: !prevState.showArchivedMatches
          }));
    }
    render () { 
        const { matches, open, openUpdateDB, showArchivedMatches, currentMatch, tab } = this.state;
        let ignoreRowClick = false;
        if (!matches)
            return (<div className="lds-dual-ring"></div>);
        else 

        return (       
            <Segment className="customizedSegment reportingPage">
                <MaterialTable
                    columns={[
                        { title: 'Competition', 
                        field: 'competition', 
                        // type: 'date',  // defaultSort: 'desc',
                            cellStyle: {
                                width: 120,
                                maxWidth: 120
                            },
                            headerStyle: {
                                width: 120,
                                maxWidth: 120
                        } },
                        { title: 'Match day', field: 'date', type: 'date',
                            render: rowData => moment(rowData.date).format('DD.MM.YYYY'),
                            defaultSort: 'desc',
                            cellStyle: {
                                width: 140,
                                maxWidth: 140
                            },
                            headerStyle: {
                                width: 140,
                                maxWidth: 140
                            } 
                        },
                        { title: 'Kick-off', 
                        field: 'kickoff', 
                        // type: 'date',  
                        // defaultSort: 'desc',
                            cellStyle: {
                                width: '10%',
                                maxWidth: '10%'
                            },
                            headerStyle: {
                                width: '10%',
                                maxWidth: '10%'
                        } },
                        
                        { title: 'Match', field: 'details', 
                        cellStyle: {
                            width: '30%',
                            minWidth: '30%'
                        },
                        headerStyle: {
                            width: '30%',
                            minWidth: '30%'
                        }
                        //width: "calc(100% + 100) / 5" },
                        // , width: 220 },
                    },
                    { title: 'Logo',  render: rowData => <img src={rowData.tableImage} 
                        style={{height: 60, borderRadius: '20%', opacity: '0.80'}}/>,
                        cellStyle: this.props.user.isManager ? 
                        {
                            textAlign: 'center',
                            width: 110,
                            maxWidth: 110,
                            padding: '10px 16px 5px 16px'                     
                        } : 
                        {},
                    
                        headerStyle: {
                            textAlign: this.props.user.isManager ? 'center' : 'left',
                            padding: this.props.user.isManager ? '16px 16px 16px 30px' : '16px 16px 16px 42px',
                            width: 110,
                            maxWidth: 110
                        } 
                    },
                    ]}
                    
                    components={{
                        Container: props => <Paper {...props} elevation={0}/>
                    }}
                    
                    data={showArchivedMatches ? 
                        matches 
                        :
                        matches.filter(match => !match.hidden)
                    }
                    options={{ 
                                detailPanelType: 'single', 
                                showTitle: false,
                                pageSize: 10, sorting: true,
                                emptyRowsWhenPaging: false,
                                rowStyle: x => {
                                    if ( x.hidden ) {
                                        return { backgroundColor: "#ddd", color: "#777" }
                                    }
                                } 
                    }}

                    detailPanel={!this.props.user.isManager ? rowData => {
                        return (
                                <Panel 
                                    matchId={rowData.rowData.id} 
                                    // TODO: add condition to check THEME
                                    FEEDS={rowData.rowData.feeds}
                                    updateChildPropsAfterChange={this.updateChildPropsAfterChange} 
                                    match={matches.find(el => el.id === rowData.rowData.id)}
                                    // isManager={this.props.user.isManager}
                                    user={this.props.user}
                                />
                        )
                    }: null}
                    actions={this.props.user.isManager ? [
                    { 
                        // hidden: !this.props.user.isManager,
                        icon: () => showArchivedMatches ? <IndeterminateCheckBoxOutlinedIcon /> : <CheckBoxOutlinedIcon  className="iconGreen" />,
                        tooltip: showArchivedMatches ? 'Hide archived matches' : 'Show archived matches',
                        isFreeAction: true,
                        onClick: () => this.handleShowArchivedMatches()
                    },
                    // { 
                    //     // hidden: !this.props.user.isManager,
                    //     icon: 'refresh',
                    //     tooltip: 'Sync with Hub',
                    //     isFreeAction: true,
                    //     onClick: () => this.testSetLogosInDB()
                    //     //onClick: () => this.refreshDb() // need this to avoid warning (https://github.com/mbrn/material-table/issues/657#issuecomment-549297024)
                    // },
                    // {
                    //     icon: () => <FullscreenIcon className="reportingTableIcon" />,
                    //     // iconProps: { style: { color: "#3d403c8a", fill: "#3d403c8a" } },
                    //     tooltip: 'Fullscreen preview',
                    //     onClick: (event, rowData) => this.handleFullscreenModalOpen(rowData, 1)
                    // },
                    rowData => ({
                    icon: () => rowData.clients.length > 0 ?
                        <Popover rowData={rowData} />
                    : 
                        <span>
                            {rowData.hidden ?
                                <Tooltip title="Restore from archive">
                                    <UnarchiveOutlinedIcon className="reportingTableIcon" />
                                </Tooltip>
                                :
                                <GroupOutlinedIcon className="reportingTableIcon" />
                            }
                            <span className="noCompanies"><ClearIcon style={{ fontSize: 12 }}/></span>
                        </span>,
                        
                        iconProps: { style: { color: "#3d403c8a", fill: "#3d403c8a" } },
                        // tooltip: 'Assign to clients',
                        onClick: (event, rowData) => 
                        this.handleFullscreenModalOpen(rowData, 0)
                    })               
                ] : null}
                    onRowClick={(event, rowData, togglePanel) => {
                        if (this.props.user.isManager) {
                            if (!ignoreRowClick)
                                this.handleFullscreenModalOpen(rowData, 1);
                        }
                        else {
                            if (!ignoreRowClick)
                                togglePanel();
                        }
                    }}
                />
         
            {openUpdateDB && this.props.user.isManager &&
            <Dialog open={openUpdateDB} TransitionComponent={Transition} onClose={this.closeUpdateDBModal} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update database?</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    New match(es) created in the Hub will be added to application
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.closeUpdateDBModal} color="primary">
                    Cancel
                </Button>
                <Button onClick={(event) => this.addMatches(event)} color="primary">
                    Add matches
                </Button>
                </DialogActions>
            </Dialog>
            }
            {open && 
            <Dialog fullScreen open={open} onClose={this.handleClose} TransitionComponent={Transition}>
            <AppBar className={"panelDialogBar " + THEME}>
              <Toolbar>
                <Typography variant="h6">
                <div className="appBarLogoArea">
                    <img className="appBarLogo" src={currentMatch.tableImage} />
                    <div className="appBarLogoText">
                        {currentMatch.details}
                    </div>
                </div>
                </Typography>
            
                <Divider orientation="vertical" className="panelDialogBarDivider" flexItem />
                {this.props.user.isManager  && <Tab className= {tab===0 ? "activeTab" : ""}
                    label="Settings" 
                    onClick={(event) => this.handleChoice(0)} />}
                    {
                        Object.keys(currentMatch.feeds).map((i) => 
                            <Tab key={i} className= {tab===parseInt(i) ? "activeTab" : ""}
                                label={currentMatch.feeds[parseInt(i)]} 
                                onClick={(event) => this.handleChoice(parseInt(i))} />
                        )
                    }
                <IconButton edge="start" color="inherit" 
                onClick={(event) => this.handleClose(tab)} aria-label="close" >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            {tab === 0 ? 
            <TransferList 
                companies={this.props.companyList}
                handleAccess={this.handleAccess}
                match={currentMatch}
                // handleClose={this.handleClose}
            />
            :
            <PanelDialog 
                updateChildPropsAfterChange={this.updateChildPropsAfterChange} 
                kickoff={currentMatch.kickoff}
                FEEDS={currentMatch.feeds}
                HUB_FEEDS={currentMatch.feeds}
                matchId={currentMatch.id} 
                feed={tab}
                user={this.props.user}
                companies={this.props.companyList}
            /> 
            }
          </Dialog>
          }
          </Segment>
        )
    }
}
export default ReportingPage;