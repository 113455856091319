import React from 'react';
import '../App.css';
//import { THEME } from '../utils/constants';
const THEME = 'infront';
// import { Button } from '@material-ui/core';

export default function LoginPage_2 () {  
    return (

      <div className={"loginPageCenter " + THEME}> 

          <div className="loginPageLogoBlock">
            <img className={"loginPageSportfiveLogo " + THEME} alt="Logo"
            src={process.env.PUBLIC_URL + '/' + THEME + '/logo-red.svg'} /> 
          </div>
          
          <p className="HubAlanyticsNReporting"> Reporting & Analytics </p>
          <hr className="loginPageLine"/>
          <p className="loginPageDescription">Virtual Advertising Documentation Platform</p> 
      </div>
    );
}