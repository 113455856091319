import React, { Component } from 'react';
import aws_exports from '../aws-exports';
import { getCompanyVideos } from '../utils/customQueries';
import { listVideos } from '../graphql/queries';
import { getSignedUrl } from '../utils/functions';
import { API, graphqlOperation } from "aws-amplify";
import Toast from 'light-toast';
import { Button as SemanticButton, 
  Dropdown, 
  Message, 
  Divider as SemanticDivider, 
  Icon } from 'semantic-ui-react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Table from '../components/smallTable';
import VideoPlayer from '../components/videoPlayer';
import { Embed } from 'semantic-ui-react';
import { FEEDS, CLOUDFRONT_DOMAIN_NAME } from '../utils/constants';

const AWS = require('aws-sdk');
AWS.config.update ({
    region: aws_exports.aws_project_region
});

class VideosPage extends Component {
    constructor(props) {
        super(props);
        // let's use it as a class variable, as we need this calculation only once
        this.dropdownCompanies = this.props.companies.map((company, index) => {
          return { key: company.id, value: company.id, text: company.name }
        });

        this.state = {
          matches: this.props.matches,
          videoFile: null,
          open: false,
          index: 0,
        };
    }

    async componentDidMount()  {
      const videosForALL = [];
      let value = null, videos = [];
      if (this.props.user.isManager) {
        // if there are no companies in dropdown list 
        if (this.dropdownCompanies.length == 0) {
          value = null;
          videos = videosForALL;
        }
        // default (first) dropdown option is the first company in the dropdown list after ALL
        else {
          value = this.dropdownCompanies[0].value;
          videos = await this.getClientVideos(value);
        }
      }
      else {
        // value will be null in case client user has no company
        if (this.props.user.userCompany) {
          value = this.props.user.userCompany.id;
          const clientVideos = await this.getClientVideos(value);
          videos = [...videosForALL, ...clientVideos];
        }
      }
      const sortedVideos = this.sortVideosByMatchDate(videos);

      this.setState({ videosForALL, videos: sortedVideos, value });

      this.setVariables(value, this.state.index);
    }
    // componentWillUnmount() {
    //   if (this.player) {
    //     this.player.dispose();
    //   }
    // }

    getClientVideos = async (value) => {
      // we don't handle ALL videos in this function
      if (value === "ALL")
        return [];
      
      else {
        try {
          const input = { id : value };
          const result = await API.graphql(
              graphqlOperation(getCompanyVideos, input)
          );
          return result.data.getCompany.videos.items;
        } catch (err) { 
          Toast.fail('Error fetching videos', 1500, () => {
              console.log(err);
              return [];
          });
        }
      }
    }
    
    sortVideosByMatchDate = (videos) => {
      if (videos.length > 1) {
        videos.sort(function(a, b) {
          var keyA = new Date(a.match.date),
              keyB = new Date(b.match.date);
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
      }
      return videos;
    }
    
    getAllExistingVideos = async(limit) => {
        try {
            const result = await API.graphql(graphqlOperation(listVideos, { limit }));
            return result.data.listVideos.items;
        } catch (err) { 
            Toast.fail('Error fetching videos', 1500, () => {
                console.log(err);
            });
        }
    }
    
    handleChangeDropdown = async (event, { value }) => {
        try {
            // const input = value === "ALL" 
            // { i}
            // let unsortedVideos;
            // if (value === "ALL")
            //     //unsortedVideos = this.state.allExistingVideos.filter((video) => video.company === null);
            //     unsortedVideos = [...this.state.videosForALL];
            // else {
            const unsortedVideos = await this.getClientVideos(value);  
            // }
            const videos = this.sortVideosByMatchDate(unsortedVideos);
            this.setState({ value, videos, index: 0 });
            this.setVariables(this.state.value, 0);
        } catch (err) {
            Toast.fail('Error fetching images', 1500, () => {
                console.log(err);
            });
        }
    };

    changeIndex = (diff) => {
      const currentIndex = this.state.index;
      const videosNumber = this.state.videos.length;
      
      // company has 1 or 0 videos
      if (videosNumber < 2) {
        return;
      }
        
      let index = currentIndex + diff;
      if (index >= videosNumber) {
        index = 0;
      }
      else if (index < 0) {
        index = videosNumber - 1;
      }
      this.setState({ index });
      
      this.setVariables(this.state.value, index);
    }
    // prevent right click on video player
    handleRightClick(ev) {
      console.log(ev);
      ev.preventDefault()
    }
    
    // for now we are passing index arg as well, because for some reason it's not passed via state in changeIndex() 
    setVariables = async (value, index) => {
          let companyName, userHasVideo = false,
              currentVideo, matchKickoff, matchDate, feed;
          const { videos } = this.state;

          // value = null if client user isn't part of any company
          if (value === "ALL" || !value)
              companyName = value;
          else 
              companyName = this.dropdownCompanies.find(company => company.value === value).text;
          
          if (videos.length > 0) {
              userHasVideo = true;
              currentVideo = videos[index];
  
              // Hub db has timestamps in UTC format, however in Germany Hub CET (+2 hours) timezone is used
              const timeOffset = moment.duration('02:00:00');
              matchKickoff = moment(currentVideo.match.date).subtract(timeOffset).format('HH:mm');
              matchDate = moment(currentVideo.match.date).format('DD.MM.YYYY');
              feed = FEEDS[currentVideo.feedNumber];
              
              // generate signed url for current video
              if (currentVideo.key && !currentVideo.signedUrl) {
                if (currentVideo.key.startsWith("video/") || currentVideo.key.startsWith("highlight/") || currentVideo.key.startsWith("upload/")) // if video wasn't uploaded to a "proper" place
                    currentVideo.signedUrl = await getSignedUrl(currentVideo.key, ""); 
                else
                    currentVideo.signedUrl = await getSignedUrl(currentVideo.key, "video/"); 
              }

          }

          this.setState({ companyName, userHasVideo, currentVideo, matchKickoff, matchDate, feed });
          console.log(this.state);
    }

    render () {
      const { value, videos, index, companyName,
            userHasVideo, currentVideo, matchKickoff, matchDate, feed } = this.state;
    
      if(!videos)
        return (<div className="lds-dual-ring"></div>);
      else {     
        return (
      <div className="videosPage">

        <Grid container spacing={3}>
        {userHasVideo ?
        <Grid className="videoPageLeftSide" item xs={8}>
          <SemanticDivider horizontal>
            <span className="dividerText">
                <Icon name="play circle outline" />
                Video {index + 1} of {videos.length} / {currentVideo.match.details} ({matchDate}) / {feed}
                {/* {video.company ? `Video for ${companyName}` : "Visible to all clients"} */}
            </span>
          </SemanticDivider>
          {currentVideo.link ?
              <Embed
                  className="videoPlayerContainer"
                  source="vimeo"
                  iframe={{
                    allowFullScreen: true,
                  }}
                  placeholder={process.env.PUBLIC_URL + '/sportfive_logo_transparent.png'}
                  id={currentVideo.link}
              />
              :
              <VideoPlayer key={currentVideo.id} watermark={currentVideo.extra === 'F'} 
              videoSrc={currentVideo.signedUrl} poster={'/infront/poster.png'} />
}
          <br/>
          <SemanticButton className="videoNavigationButton" onClick={() => this.changeIndex(-1)} icon labelPosition='left'>
            <Icon name='left arrow' />
            Previous
          </SemanticButton>
          <SemanticButton className="videoNavigationButton" onClick={() => this.changeIndex(1)} icon labelPosition='right'>
            Next
            <Icon name='right arrow' />
          </SemanticButton>
        </Grid>
        :
        <Grid className="videoPageLeftSide" item xs={8}>
            <Message
              icon='video play'
              size='large'
              className='noVideosImagesMessage'
              content={companyName ? 'No videos for ' + companyName : 'No videos'}
            />
        </Grid>
        }
        <Grid item xs={4}>          
        <div className="galleryViewButtonsTransferListWindow">
          {this.props.user.isManager &&
            <div className="galleryViewButtons">
                <SemanticDivider horizontal>
                    <span className="dividerText">
                        <Icon name='user outline' className='lowOpacityIcon' />
                        Client
                    </span>
                </SemanticDivider>
            
                <Dropdown
                    onChange={this.handleChangeDropdown}
                    options={this.dropdownCompanies}
                    placeholder='Select client'
                    button
                    scrolling
                    value={value}
                />
            </div>
          }
            
            {userHasVideo && 
              <span>
              <SemanticDivider horizontal>
              <span className="dividerText">
                  <Icon name='bar chart' />
                  Match
              </span>
              </SemanticDivider>

                  <Table rows={[
                      [ "Name", currentVideo.match.details ],
                      [ "Date", matchDate ],
                      [ "Kick-off", matchKickoff ],
                  ]}/>
              <SemanticDivider horizontal>
                  <span className="dividerText">
                      <Icon name='map marker alternate' className='lowOpacityIcon' />
                      Feed
                  </span>
              </SemanticDivider>
              
                  <Table 
                      cellClass="panelDialogFeedTableCell"
                      rows={[
                          [ "Region", feed ],
                          ]}
                  />
              </span>
            }
          </div>
        </Grid>
      </Grid>
      <br /><br /><br /><br />
        </div>);
    }}
}
export default VideosPage;