import React, {useEffect} from 'react';
import Switch from '@material-ui/core/Switch';
// import Tooltip from '@material-ui/core/Tooltip';
// import Grid from '@material-ui/core/Grid';
export default function MySwitch( props ) {
  const [state, setState] = React.useState({
    
    isChecked: true,

  });
  useEffect(() => {
    // user clicked on label "Videos" or "Images", we need to change Switch value accordingly
    if (props.currentView !== state.isChecked)
      setState({ ...state, isChecked: props.currentView});
  });

  const handleChange = (event) => {

    setState({ ...state, [event.target.name]: event.target.checked });
    props.change(event.target.checked);
  };

  return (
    <div className="panelDialogSwitch">

      {/* <Grid component="label" container alignItems="center" justify="center" spacing={1}>
          <Grid item>Hidden</Grid>
          <Grid item> */}
            <Switch
              checked={state.isChecked}
              onChange={handleChange}
              color="default"
              name="checkedB"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          {/* </Grid>
          <Grid item>Visible</Grid>
      </Grid> */}

    </div>
  );
}