import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Icon } from 'semantic-ui-react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {DatePicker} from '@material-ui/pickers';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  textField: {
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(1),
    width: 220,
  }
}));
export default function MaterialUIPickers( props ) {

    // Hub db has timestamps in UTC format, however in Germany Hub CET (+2 hours) timezone is used
    // const timeOffset = moment.duration('02:00:00');
    // const formattedDateAndTime = moment(props.dateAndTime)
    // .subtract(timeOffset).add(7, 'days').format('YYYY-MM-DDTHH:mm').toString();
    
    // const formattedDateAndTime = props.dateAndTime ? props.dateAndTime : moment(props.matchDate)
    //     .add(7, 'days').startOf('day').format('LL').toString();
    
    // const classes = useStyles();
    // const lastAvailabilityDate = moment(props.matchDate)
    // // .add(7, 'days')
    // .toString();
    const dayDate = new Date(props.matchDate).setHours(0,0,0,0);
    const [selectedDate, setSelectedDate] = React.useState(dayDate);
    // console.log(lastAvailabilityDate);
    const handleDateChange = (date) => {
        setSelectedDate(date);
        props.handleChangeDatepicker(date, props.video);
        // console.log(date);
    };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            value={selectedDate}
            format="dd.MM.yyyy"
            variant="inline"
            // InputProps={{
            //   disableUnderline: true
            // }}
            autoOk={true}
            label={<span>{props.label} <Icon className="infoIcon" size="small" name='info circle'/></span>}
            onChange={handleDateChange}
          />
        {/* <KeyboardDatePicker
          disableToolbar
          className={classes.textField} 
          variant="inline"
          disablePast = {true}
          InputProps={{
            disableUnderline: true
          }}
          format="MM/dd/yyyy"
          margin="normal"
          autoOk={true}
          id="date-picker-inline"
          label={props.label}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        /> */}
    </MuiPickersUtilsProvider>
  );
}
// import React from 'react';
// import { makeStyles } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
// import moment from 'moment';

// const useStyles = makeStyles((theme) => ({
//   container: {
//     display: 'inline-flex',
//     flexWrap: 'wrap',
//     width: 200,
//   },
//   textField: {
//     marginLeft: theme.spacing(1),
//     // marginRight: theme.spacing(1),
//     width: 190,
//   }
// }));

// export default function DateAndTimePickers( props ) {
//   const classes = useStyles();
 
  
//   const [selectedDate, setSelectedDate] = React.useState(new Date('2014-08-18T21:11'));
//   const handleDateChange = (date) => {
//     setSelectedDate(date);
//   };


  
//   return (
//     <form className={classes.container} noValidate>
//       <TextField
//         id="datetime-local"
//         format="yyyy-MM-dd"
//         // onChange={props.handleChangeDatepicker}
//         onChange={handleDateChange}
//         label={props.label}
//         // type="datetime-local"
//         // defaultValue={formattedDateAndTime}
//         value={selectedDate}
//         className={classes.textField}
//         InputLabelProps={{
//           shrink: true,
//         }}
//       />
//     </form>
//   );
// }